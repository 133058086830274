import { useState, useEffect, Fragment, useContext } from 'react'
import axios from 'axios'
import { useCookies } from 'react-cookie'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { addAuditLog, getPrivilegeForCurrentPage, checkCompulsoryFields, prepareTableColumnsWithPrivileges, addObject, deleteObject, prepareTableColumnsWithPrivilegeAndInputType, getObject, getJobByJobIdAndWorkerId, getProblemByProblemIdAndJobIdAndWorkerId, editObject, prepareListWithPrivileges, getSubsidiaryTableFromSubsidiarySlug, getValueByColumnName, deleteObjects, downloadFiles, getLargestSequence, checkCurrentUserCanViewSensitiveDiscussion, getJobAndProblemByWorkerId, checkCurrentUserCanViewSensitiveAttachment, listenToNotifications, checkDeactivated, pdpaDownloadFiles, checkSystemDown, checkCookieToken, fileIsAbove25M, checkValidityOfWorkerJobProblem, matchWorkerJobProblem} from '../../scripts/helpers'
import _ from 'lodash'

// components
import Header from '../../components/header'
import SubHeader2 from '../../components/subheader2'
import Modal from '../../components/modal'
import TagWorkerModal from '../../components/tagWorkerModal'
import AlertNotification from '../../components/alertNotification'
import WarningNotification from '../../components/warningNotification'
import TopAndMiddleNavigation3 from '../../components/topAndMiddleNavigation3'
import NavigationStub2 from '../../components/navigationStub2'
import RightStub from '../../components/rightStub'
import Abridged from './subsidiaries/abridged'
import ProblemAbridged from './subsidiaries/problemAbridged'
import { AuthContext } from '../../AuthProvider'
import { backend_base_url } from '../../utils/helper'

export default function WorkersView2() {
  const location = useLocation();
  const tableSlug = 'workers';
  const [cookies, setCookie, removeCookie] = useCookies(['cookie']);
  const [loading, setLoading] = useState(true);
  const [outcome, setOutcome] = useState({});
  const [objects, setObjects] = useState([]);
  const [objectIdsSelected, setObjectIdsSelected] = useState([]);
  const [objectsSelected, setObjectsSelected] = useState([]);
  const [taggedWorker, setTaggedWorker] = useState('');
  const [worker, setWorker] = useState({});
  const [sort, setSort] = useState({});
  const [page, setPage] = useState('');
  const [deleteSingle, setDeleteSingle] = useState('');
  const [deletePlural, setDeletePlural] = useState('');
  const [rightStubObj, setRightStubObj] = useState({});
  const [subHeaderObj, setSubHeaderObj] = useState({});
  const [navigationStubObj, setNavigationStubObj] = useState({});
  const [topAndMiddleNavigationObj, setTopAndMiddleNavigationObj] = useState({});
  const [table, setTable] = useState('');
  const [level, setLevel] = useState('');
  const [updatedFacepic, setUpdatedFacepic] = useState('');
  const [updatedFacepicDataFormat, setUpdatedFacepicDataFormat] = useState('');
  const [rightStubTitle, setRightStubTitle] = useState('');
  const [privileges, setPrivileges] = useState({});
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [tableColumns, setTableColumns] = useState([]);
  const [selectedDropdown, setSelectedDropdown] = useState([]);

  const context = useContext(AuthContext);
  const {socket, show, notifications, setNotifications, update} = context;
  useEffect(() => socket && listenToNotifications(socket, notifications, setNotifications, show, update), [socket]);
  useEffect(() => {
    if (location.state && location.state.warnings) {
      setOutcome({
        message: location.state.warnings,
        type: 'Warning'
      });
    }
  }, [location.state]);

  const navigate = useNavigate();
  const params = useParams();

  const {workerId, workerSubsidiarySlugOrWorkerAction, workerSubsidiaryIdOrWorkerSubsidiaryAction, jobSubsidiarySlugOrWorkerSubsidiaryAction, jobSubsidiaryIdOrJobSubsidiaryAction, problemSubsidiarySlugOrJobSubsidiaryAction, problemSubsidiaryIdOrProblemSubsidiaryAction, problemSubsidiaryAction} = params;

  const disableShowDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
  };

  const changeBenefitClass = (rightStubObj, tableColumn, column, event) => {
    const rightStubObjToUpdate = {...rightStubObj};
    const tableColumnsToUpdate = [...rightStubObj.tableColumns];

    for (let i = 0; i < tableColumnsToUpdate.length; i += 1) {
      if (tableColumnsToUpdate[i]['column_name'] === 'bene_class') {
        tableColumnsToUpdate[i]['value'] = event.dropdown_class;
      } else if (tableColumnsToUpdate[i]['column_name'] === 'bene_item') {
        tableColumnsToUpdate[i]['dropdowns'] = rightStubObjToUpdate['beneItemReferences'].filter(row => row.dropdown_class === event.dropdown_class);
        tableColumnsToUpdate[i]['dropdowns'].unshift({dropdown_item: '-'});
        tableColumnsToUpdate[i]['value'] = tableColumnsToUpdate[i]['dropdowns'][0]['dropdown_item'];
        
        break;
      }
    }

    rightStubObjToUpdate.tableColumns = tableColumnsToUpdate;
    setRightStubObj(rightStubObjToUpdate);
    setLoading(false);
  };

  const handleJobChange = (rightStubObj, tableColumn, column, event) => {
    const problems = rightStubObj.jobIdsToProblems[event.id];
    const rightStubObjToUpdate = {...rightStubObj};
    const tableColumnsToUpdate = [...rightStubObj.tableColumns];
    let resetJob = false;
    // fill up problems tied to job
    for (let i = 0; i < tableColumnsToUpdate.length; i += 1) {
      if (tableColumnsToUpdate[i]['column_name'] === 'employer_name') {
        if (event.dropdown_item === '-') {
          tableColumnsToUpdate[i]['job_id'] = '';
          tableColumnsToUpdate[i]['value'] = '';
          resetJob = true;
        } else {
          if (tableColumnsToUpdate[i]['value'] !== event.dropdown_item) {
            tableColumnsToUpdate[i]['job_id'] = event.id;
            tableColumnsToUpdate[i]['value'] = event.dropdown_item;
            resetJob = true;
          }
        }
      } else if (tableColumnsToUpdate[i]['column_name'] === 'chief_problem') {
        if (resetJob) {
          tableColumnsToUpdate[i]['dropdowns'] = [{dropdown_item: '-'}];
          tableColumnsToUpdate[i]['value'] = '';
          tableColumnsToUpdate[i]['problem_id'] = '';
          
          if (problems !== undefined) {
            problems.forEach((problem) => {
              tableColumnsToUpdate[i]['dropdowns'].push({id: problem.id, dropdown_item: problem.chief_problem});
            });
          }
        }
      }
    }

    rightStubObjToUpdate.tableColumns = tableColumnsToUpdate;
    setRightStubObj(rightStubObjToUpdate);
    setLoading(false);
  };

  const deleteSelected = () => {
    if (workerSubsidiarySlugOrWorkerAction === undefined) {
      deleteObject(tableSlug, workerId).then(result => {
        addAuditLog({
          table_name: rightStubObj.table,
          action_type: 'Delete',
          action_description: `Deleted ${rightStubObj.table.toLowerCase()} with id: ${workerId}.`,
          worker_id: workerId,
          user_id: cookies['userId']
        }).then(() => {
          navigate(`/`);
        });
      }).catch(err => {
        setOutcome({
          message: err.response.data.message,
          type: 'Danger'
        });

        
      });
    } else if (workerSubsidiarySlugOrWorkerAction !== undefined && jobSubsidiarySlugOrWorkerSubsidiaryAction === undefined) {
      if ((workerSubsidiarySlugOrWorkerAction === 'facepic-attachments' || workerSubsidiarySlugOrWorkerAction === 'pdpa-attachments' || workerSubsidiarySlugOrWorkerAction === 'ordinary-attachments'|| workerSubsidiarySlugOrWorkerAction === 'sensitive-attachments') && workerSubsidiaryIdOrWorkerSubsidiaryAction === undefined) {
        deleteObjects(workerSubsidiarySlugOrWorkerAction, rightStubObj.objectsSelected)
        .then(result => {
          disableShowDeleteConfirmation();

          const objSelected = [];
          rightStubObj.objectsSelected.map(obj => objSelected.push(obj.attachment_name));

          let table = '';

          if (workerSubsidiarySlugOrWorkerAction === 'facepic-attachments') {
            table = 'Facepic Attachment';
          } else if (workerSubsidiarySlugOrWorkerAction === 'pdpa-attachments') {
            table = 'PDPA Attachment';
          } else if (workerSubsidiarySlugOrWorkerAction === 'ordinary-attachments') {
            table = 'Ordinary Attachment';
          } else if (workerSubsidiarySlugOrWorkerAction === 'sensitive-attachments') {
            table = 'Sensitive Attachment';
          }

          rightStubObj.objectsSelected.forEach((row) => {
            addAuditLog({
              table_name: table,
              action_type: 'Delete',
              action_description: `Deleted attachment: ${row.attachment_name}.`,
              worker_id: workerId,
              user_id: cookies['userId']
            }).then(() => {
              window.location.reload();
            });
          });
        })
        .catch(err => {
          setOutcome({
              message: err,
              type: 'Danger'
            });

            
        });
      } else {
        deleteObject(workerSubsidiarySlugOrWorkerAction, workerSubsidiaryIdOrWorkerSubsidiaryAction).then(result => {
          disableShowDeleteConfirmation();
          if (workerSubsidiarySlugOrWorkerAction === 'jobs') {
            addAuditLog({
              table_name: rightStubObj.table,
              action_type: 'Delete',
              action_description: `Deleted ${rightStubObj.table.toLowerCase()} with id: ${workerSubsidiaryIdOrWorkerSubsidiaryAction}.`,
              job_id: workerSubsidiaryIdOrWorkerSubsidiaryAction,
              worker_id: workerId,
              user_id: cookies['userId']
            }).then(() => {
              navigate(`/${tableSlug}/${workerId}/${workerSubsidiarySlugOrWorkerAction}`);
            });
          } else {
            addAuditLog({
              table_name: rightStubObj.table,
              action_type: 'Delete',
              action_description: `Deleted ${rightStubObj.table.toLowerCase()} with id: ${workerSubsidiaryIdOrWorkerSubsidiaryAction}.`,
              worker_id: workerId,
              user_id: cookies['userId']
            }).then(() => {
              navigate(`/${tableSlug}/${workerId}/${workerSubsidiarySlugOrWorkerAction}`);
            });
          }
        }).catch(err => {
          setOutcome({
            message: err.response.data.message,
            type: 'Danger'
          });
  
          
        });
      }
    } else if (workerSubsidiarySlugOrWorkerAction !== undefined && jobSubsidiarySlugOrWorkerSubsidiaryAction !== undefined && problemSubsidiarySlugOrJobSubsidiaryAction === undefined) {
      if (rightStubObj.objectsSelected !== undefined && rightStubObj.objectsSelected.length > 0) {
        deleteObjects(jobSubsidiarySlugOrWorkerSubsidiaryAction, rightStubObj.objectsSelected)
        .then(result => {
          disableShowDeleteConfirmation();

          const objSelected = [];
          rightStubObj.objectsSelected.map(obj => objSelected.push(obj.attachment_name));

          let table = '';

          if (workerSubsidiarySlugOrWorkerAction === 'facepic-attachments') {
            table = 'Facepic Attachment';
            rightStubObj.objectsSelected.forEach((row) => {
              addAuditLog({
                table_name: table,
                action_type: 'Delete',
                action_description: `Deleted attachment: ${row.attachment_name}.`,
                worker_id: workerId,
                user_id: cookies['userId']
              }).then(() => {
                window.location.reload();
              });
            });
          } else if (workerSubsidiarySlugOrWorkerAction === 'pdpa-attachments') {
            table = 'PDPA Attachment';
            rightStubObj.objectsSelected.forEach((row) => {
              addAuditLog({
                table_name: table,
                action_type: 'Delete',
                action_description: `Deleted attachment: ${row.attachment_name}.`,
                worker_id: workerId,
                user_id: cookies['userId']
              }).then(() => {
                window.location.reload();
              });
            });
          } else if (jobSubsidiarySlugOrWorkerSubsidiaryAction === 'ordinary-attachments') {
            table = 'Ordinary Attachment';
            rightStubObj.objectsSelected.forEach((row) => {
              addAuditLog({
                table_name: table,
                action_type: 'Delete',
                action_description: `Deleted attachment: ${row.attachment_name}.`,
                job_id: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                worker_id: workerId,
                user_id: cookies['userId']
              }).then(() => {
                window.location.reload();
              });
            });
          } else if (jobSubsidiarySlugOrWorkerSubsidiaryAction === 'sensitive-attachments') {
            table = 'Sensitive Attachment';
            rightStubObj.objectsSelected.forEach((row) => {
              addAuditLog({
                table_name: table,
                action_type: 'Delete',
                action_description: `Deleted attachment: ${row.attachment_name}.`,
                job_id: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                worker_id: workerId,
                user_id: cookies['userId']
              }).then(() => {
                window.location.reload();
              });
            });
          }
        })
        .catch(err => {
          setOutcome({
              message: err,
              type: 'Danger'
            });

            
        });
      } else {
        deleteObject(jobSubsidiarySlugOrWorkerSubsidiaryAction, jobSubsidiaryIdOrJobSubsidiaryAction).then(result => {
          if (jobSubsidiarySlugOrWorkerSubsidiaryAction === 'problems') {
            addAuditLog({
              table_name: rightStubObj.table,
              action_type: 'Delete',
              action_description: `Deleted ${rightStubObj.table.toLowerCase()} with id: ${jobSubsidiaryIdOrJobSubsidiaryAction}.`,
              problem_id: jobSubsidiaryIdOrJobSubsidiaryAction,
              job_id: workerSubsidiaryIdOrWorkerSubsidiaryAction,
              worker_id: workerId,
              user_id: cookies['userId']
            }).then(() => {
              navigate(`/${tableSlug}/${workerId}/${workerSubsidiarySlugOrWorkerAction}/${workerSubsidiaryIdOrWorkerSubsidiaryAction}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}`);
            });
          } else {
            addAuditLog({
              table_name: rightStubObj.table,
              action_type: 'Delete',
              action_description: `Deleted ${rightStubObj.table.toLowerCase()} with id: ${jobSubsidiaryIdOrJobSubsidiaryAction}.`,
              job_id: workerSubsidiaryIdOrWorkerSubsidiaryAction,
              worker_id: workerId,
              user_id: cookies['userId']
            }).then(() => {
              navigate(`/${tableSlug}/${workerId}/${workerSubsidiarySlugOrWorkerAction}/${workerSubsidiaryIdOrWorkerSubsidiaryAction}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}`);
            });
          }
        }).catch(err => {
          setOutcome({
            message: err.response.data.message,
            type: 'Danger'
          });
  
          
        });
      }
    } else if (workerSubsidiarySlugOrWorkerAction !== undefined && jobSubsidiarySlugOrWorkerSubsidiaryAction !== undefined && problemSubsidiarySlugOrJobSubsidiaryAction !== undefined) {
      if (rightStubObj.objectsSelected !== undefined && rightStubObj.objectsSelected.length > 0) {
        deleteObjects(problemSubsidiarySlugOrJobSubsidiaryAction, rightStubObj.objectsSelected)
        .then(result => {
          disableShowDeleteConfirmation();
          
          const objSelected = [];
          rightStubObj.objectsSelected.map(obj => objSelected.push(obj.attachment_name));

          let table = '';

          if (workerSubsidiarySlugOrWorkerAction === 'facepic-attachments') {
            table = 'Facepic Attachment';
            rightStubObj.objectsSelected.forEach((row) => {
              addAuditLog({
                table_name: table,
                action_type: 'Delete',
                action_description: `Deleted attachment: ${row.attachment_name}.`,
                worker_id: workerId,
                user_id: cookies['userId']
              }).then(() => {
                window.location.reload();
              });
            });
          } else if (workerSubsidiarySlugOrWorkerAction === 'pdpa-attachments') {
            table = 'PDPA Attachment';
            rightStubObj.objectsSelected.forEach((row) => {
              addAuditLog({
                table_name: table,
                action_type: 'Delete',
                action_description: `Deleted attachment: ${row.attachment_name}.`,
                worker_id: workerId,
                user_id: cookies['userId']
              }).then(() => {
                window.location.reload();
              });
            });
          } else if (problemSubsidiarySlugOrJobSubsidiaryAction === 'ordinary-attachments') {
            table = 'Ordinary Attachment';

            rightStubObj.objectsSelected.forEach((row) => {
              addAuditLog({
                table_name: table,
                action_type: 'Delete',
                action_description: `Deleted attachment: ${row.attachment_name}.`,
                problem_id: jobSubsidiaryIdOrJobSubsidiaryAction,
                job_id: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                worker_id: workerId,
                user_id: cookies['userId']
              }).then(() => {
                window.location.reload();
              });
            });
          } else if (problemSubsidiarySlugOrJobSubsidiaryAction === 'sensitive-attachments') {
            table = 'Sensitive Attachment';
            rightStubObj.objectsSelected.forEach((row) => {
              addAuditLog({
                table_name: table,
                action_type: 'Delete',
                action_description: `Deleted attachment: ${row.attachment_name}.`,
                problem_id: jobSubsidiaryIdOrJobSubsidiaryAction,
                job_id: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                worker_id: workerId,
                user_id: cookies['userId']
              }).then(() => {
                window.location.reload();
              });
            });
          }
        })
        .catch(err => {
          setOutcome({
              message: err,
              type: 'Danger'
            });

            
        });
      } else {
        deleteObject(problemSubsidiarySlugOrJobSubsidiaryAction, problemSubsidiaryIdOrProblemSubsidiaryAction).then(result => {
          addAuditLog({
            table_name: rightStubObj.table,
            action_type: 'Delete',
            action_description: `Deleted ${rightStubObj.table.toLowerCase()} with id: ${problemSubsidiaryIdOrProblemSubsidiaryAction}.`,
            problem_id: jobSubsidiaryIdOrJobSubsidiaryAction,
            job_id: workerSubsidiaryIdOrWorkerSubsidiaryAction,
            worker_id: workerId,
            user_id: cookies['userId']
          }).then(() => {
            navigate(`/${tableSlug}/${workerId}/${workerSubsidiarySlugOrWorkerAction}/${workerSubsidiaryIdOrWorkerSubsidiaryAction}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}/${jobSubsidiaryIdOrJobSubsidiaryAction}/${problemSubsidiarySlugOrJobSubsidiaryAction}`);
          });
        }).catch(err => {
          setOutcome({
            message: err.response.data.message,
            type: 'Danger'
          });
  
          
        });
      }
    }
  };

  const handleOnChangeCheckbox = (event, rightStubObj, id, object) => {
    let objectsSelectedToUpdate = [...rightStubObj.objectsSelected];
    const rightStubObjToUpdate = {...rightStubObj};

    if (event.target.checked) {
      objectsSelectedToUpdate.push(object);
    } else {
      objectsSelectedToUpdate = objectsSelectedToUpdate.filter((value) => value.id !== id);
    }
    rightStubObjToUpdate.objectsSelected = objectsSelectedToUpdate;

    // setObjectIdsSelected(objectIdsSelectedToUpdate);
    setObjectsSelected(objectsSelectedToUpdate);

    setRightStubObj(rightStubObjToUpdate);
    setLoading(false);
  };

  const handleFormSubmit = async (event, rightStubObj) => {
    event.preventDefault();
    
    if (checkCookieToken(document.cookie)) {
      checkDeactivated(cookies['token'])
      .then(async () => {
        const buttonClicked = event.nativeEvent.submitter.innerHTML.split(" ")[0];
        if (workerSubsidiarySlugOrWorkerAction === undefined) {
          // worker view
          if (buttonClicked === 'Edit') {
            navigate(`/${tableSlug}/${workerId}/edit`);
          } else if (buttonClicked === 'Delete') {
            setDeleteSingle('worker');
            setDeletePlural('workers');
            setShowDeleteConfirmation(true);
          }
        } else if (
          workerSubsidiarySlugOrWorkerAction !== undefined && 
          workerSubsidiaryIdOrWorkerSubsidiaryAction === undefined
        ) {
          if (buttonClicked === 'Save') {
            if (workerSubsidiarySlugOrWorkerAction === 'edit') {
              const result = checkCompulsoryFields(tableColumns);
              if (result.allCompulsoryFieldsHaveBeenFilled) {

                const tableColumnsToSubmit = [...tableColumns];
                tableColumnsToSubmit.push({
                  token: cookies['token']
                });

                tableColumnsToSubmit[0]['value'] = tableColumnsToSubmit[0]['value'].replace(/ /g, "");
                
                editObject(tableSlug, tableColumnsToSubmit, workerId).then(result => {
                  addAuditLog({
                    table_name: rightStubObj.table,
                    action_type: 'Update',
                    record_id: workerId,
                    action_description: `Updated ${rightStubObj.table.toLowerCase()} with id: ${workerId}.`,
                    worker_id: workerId,
                    user_id: cookies['userId']
                  }).then(() => {
                    navigate(`/${tableSlug}/${workerId}`);
                  });
                }).catch(err => {
                  setOutcome({
                    message: err.response.data.message,
                    type: 'Danger'
                  });

                  if (document.getElementById(err.response.data.input) !== null) {
                    document.getElementById(err.response.data.input).className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';
                  }

                  
                });
              } else {
                const emptyFields = result.emptyFields;

                emptyFields.forEach((emptyField) => {
                  if (document.getElementById(emptyField)) {
                    document.getElementById(emptyField).className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';
                  } else {
                    if (document.getElementsByClassName(`bg-white relative w-full border border-gray-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500 sm:text-sm ${emptyField}`)[0] !== undefined) {
                      document.getElementsByClassName(`bg-white relative w-full border border-gray-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500 sm:text-sm ${emptyField}`)[0].className = `bg-red-50 relative w-full border border-red-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500 sm:text-sm ${emptyField}`;
                    } else if (document.getElementsByClassName(`w-full border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm ${emptyField}`)[0] !== undefined) {
                      document.getElementsByClassName(`w-full border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm ${emptyField}`)[0].className = `bg-red-50 w-full border border-red-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-red-500 focus:outline-none focus:ring-1 focus:ring-red-500 sm:text-sm ${emptyField}`;
                    }
                  }
                });

                setOutcome({
                  message: 'Compulsory fields have not been filled.',
                  type: 'Danger'
                });

                
              }
            }
          } else if (buttonClicked === 'Abandon') {
            navigate(`/${tableSlug}/${workerId}`);
          } else if (buttonClicked === 'Add') {
            navigate(`/${tableSlug}/${workerId}/${workerSubsidiarySlugOrWorkerAction}/add`);
          } else if (buttonClicked === 'Edit') {
            if (rightStubObj.objectsSelected.length === 0) {
              setOutcome({
                message: 'Please select at least 1 record to edit.',
                type: 'Danger'
              });

              
            } else if (rightStubObj.objectsSelected.length >= 2) {
              setOutcome({
                message: 'You can select only 1 record to edit.',
                type: 'Danger'
              });

              
            } else {
              navigate(`/${tableSlug}/${workerId}/${workerSubsidiarySlugOrWorkerAction}/${rightStubObj.objectsSelected[0]['id']}/edit`);
            }
          } else if (buttonClicked === 'Download') {
            if (rightStubObj.objectsSelected.length === 0) {
              setOutcome({
                message: 'Please select at least 1 record to download.',
                type: 'Danger'
              });
            } else {
              if (rightStubObj.table === "PDPA ATTACHMENTS") {
                const fileNames = rightStubObj.objectsSelected.map((row) => row.file_path.substring(row.file_path.lastIndexOf('/') + 1));
                const filePaths = rightStubObj.objectsSelected.map((row) => row.file_path);
                pdpaDownloadFiles(fileNames, filePaths, cookies['userId']).then(result => {
                  window.location.href=`${backend_base_url()}/api/attachments/downloads/downloadFile?filename=${result.data}`;
                });

                rightStubObj.objectsSelected.forEach((row) => {
                  addAuditLog({
                    table_name: 'PDPA Attachment',
                    action_type: 'Download',
                    record_id: row.id,
                    worker_id: row.worker_id,
                    job_id: row.job_id,
                    problem_id: row.problem_id,
                    action_description: `Downloaded file: ${row.filename}.`,
                    user_id: cookies['userId']
                  });
                });
              } else if (rightStubObj.table === "ORDINARY ATTACHMENTS") {
                const fileNames = rightStubObj.objectsSelected.map((row) => row.file_path.substring(row.file_path.lastIndexOf('/') + 1));
                downloadFiles('ordinary-attachments', fileNames, cookies['userId']).then(result => {
                  window.location.href=`${backend_base_url()}/api/attachments/downloads/downloadFile?filename=${result.data}`;
                });

                rightStubObj.objectsSelected.forEach((row) => {
                  addAuditLog({
                    table_name: 'Ordinary Attachment',
                    action_type: 'Download',
                    record_id: row.id,
                    worker_id: row.worker_id,
                    job_id: row.job_id,
                    problem_id: row.problem_id,
                    action_description: `Downloaded file: ${row.filename}.`,
                    user_id: cookies['userId']
                  });
                });
              } else if (rightStubObj.table === "SENSITIVE ATTACHMENTS") {
                const fileNames = rightStubObj.objectsSelected.map((row) => row.file_path.substring(row.file_path.lastIndexOf('/') + 1));
                downloadFiles('sensitive-attachments', fileNames, cookies['userId']).then(result => {
                  window.location.href=`${backend_base_url()}/api/attachments/downloads/downloadFile?filename=${result.data}`;
                });
                rightStubObj.objectsSelected.forEach((row) => {
                  addAuditLog({
                    table_name: 'Sensitive Attachment',
                    action_type: 'Download',
                    record_id: row.id,
                    worker_id: row.worker_id,
                    job_id: row.job_id,
                    problem_id: row.problem_id,
                    action_description: `Downloaded file: ${row.filename}.`,
                    user_id: cookies['userId']
                  });
                });
              } else if (rightStubObj.table === "FACEPIC ATTACHMENTS") {
                const fileNames = rightStubObj.objectsSelected.map((row) => row.file_path.substring(row.file_path.lastIndexOf('/') + 1));
                downloadFiles('facepic-attachments', fileNames, cookies['userId']).then(result => {
                  window.location.href=`${backend_base_url()}/api/attachments/downloads/downloadFile?filename=${result.data}`;
                });
                rightStubObj.objectsSelected.forEach((row) => {
                  addAuditLog({
                    table_name: 'Facepic Attachment',
                    action_type: 'Download',
                    record_id: row.id,
                    worker_id: row.worker_id,
                    job_id: row.job_id,
                    problem_id: row.problem_id,
                    action_description: `Downloaded file: ${row.filename}.`,
                    user_id: cookies['userId']
                  });
                });
              } else {
                const fileNames = rightStubObj.objectsSelected.map((row) => row.file_path.substring(row.file_path.lastIndexOf('/') + 1));
                downloadFiles('attachments', fileNames, cookies['userId']).then(result => {
                  window.location.href=`${backend_base_url()}/api/attachments/downloads/downloadFile?filename=${result.data}`;
                });
                rightStubObj.objectsSelected.forEach((row) => {
                  addAuditLog({
                    table_name: rightStubObj.table,
                    action_type: 'Download',
                    record_id: row.id,
                    worker_id: row.worker_id,
                    job_id: row.job_id,
                    problem_id: row.problem_id,
                    action_description: `Downloaded file: ${row.filename}.`,
                    user_id: cookies['userId']
                  });
                });
              }
            }
          } else if (buttonClicked === 'Delete') {
            if (rightStubObj.objectsSelected.length === 0) {
              setOutcome({
                message: 'Please select at least 1 record to delete.',
                type: 'Danger'
              });

              
            } else {
              setDeleteSingle(`${rightStubObj.subsidiary.toLowerCase()}`);
              setDeletePlural(`${workerSubsidiarySlugOrWorkerAction.replace(/-/g, ' ').toLowerCase()}`);
              setShowDeleteConfirmation(true);
            }
          }
        } else if (
          workerSubsidiarySlugOrWorkerAction !== undefined && 
          workerSubsidiaryIdOrWorkerSubsidiaryAction !== undefined && 
          jobSubsidiarySlugOrWorkerSubsidiaryAction === undefined
        ) {
          if (workerSubsidiaryIdOrWorkerSubsidiaryAction === 'add') {
            if (buttonClicked === 'Save') {
              const result = checkCompulsoryFields(rightStubObj.tableColumns);
              if (result.allCompulsoryFieldsHaveBeenFilled) {
                const tableColumnsToSubmit = [...tableColumns];
                tableColumnsToSubmit.push({
                  token: cookies['token']
                });

                addObject(workerSubsidiarySlugOrWorkerAction, tableColumnsToSubmit).then(result => {
                  if (workerSubsidiarySlugOrWorkerAction === 'jobs') {
                    addAuditLog({
                      table_name: rightStubObj.table,
                      action_type: 'Create',
                      record_id: result.data.id,
                      action_description: `Created ${rightStubObj.table.toLowerCase()} with id: ${result.data.id}.`,
                      worker_id: result.data.worker_id,
                      user_id: cookies['userId']
                    }).then(() => {
                      navigate(`/workers/${result.data.worker_id}/${workerSubsidiarySlugOrWorkerAction}/${result.data.id}`);
                    });
                  } else {
                    addAuditLog({
                      table_name: rightStubObj.table,
                      action_type: 'Create',
                      record_id: result.data.id,
                      action_description: `Created ${rightStubObj.table.toLowerCase()} with id: ${result.data.id}.`,
                      worker_id: workerId,
                      user_id: cookies['userId']
                    }).then(() => {
                      navigate(`/workers/${result.data.worker_id}/${workerSubsidiarySlugOrWorkerAction}/${result.data.id}`);
                    });
                  }
                }).catch(err => {
                  setOutcome({
                    message: err.response.data.message,
                    type: 'Danger'
                  });

                  if (document.getElementById(err.response.data.input) !== null) {
                    document.getElementById(err.response.data.input).className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';
                  }
                });
              } else {
                const emptyFields = result.emptyFields;

                emptyFields.forEach((emptyField) => {
                  if (document.getElementById(emptyField)) {
                    document.getElementById(emptyField).className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';
                  } else {
                    if (document.getElementsByClassName(`bg-white relative w-full border border-gray-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500 sm:text-sm ${emptyField}`)[0] !== undefined) {
                      document.getElementsByClassName(`bg-white relative w-full border border-gray-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500 sm:text-sm ${emptyField}`)[0].className = `bg-red-50 relative w-full border border-red-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500 sm:text-sm ${emptyField}`;
                    } else if (document.getElementsByClassName(`w-full border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm ${emptyField}`)[0] !== undefined) {
                      document.getElementsByClassName(`w-full border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm ${emptyField}`)[0].className = `bg-red-50 w-full border border-red-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-red-500 focus:outline-none focus:ring-1 focus:ring-red-500 sm:text-sm ${emptyField}`;
                    }
                  }
                });

                setOutcome({
                  message: 'Compulsory fields have not been filled.',
                  type: 'Danger'
                });

                
              }
            } else if (buttonClicked === 'Upload') {
              const result = checkCompulsoryFields(rightStubObj.tableColumns);
              
              if (result.allCompulsoryFieldsHaveBeenFilled) {
                const tableColumnsToSubmit = [...rightStubObj.tableColumns];
                tableColumnsToSubmit.push({
                  token: cookies['token']
                });

                // let returnObj = await checkValidityOfWorkerJobProblem(tableColumnsToSubmit);
                // returnObj = returnObj.data;
                // if (returnObj && !returnObj.success) {
                //   setOutcome({
                //     message: returnObj.message,
                //     type: 'Danger'
                //   });

                //   return;
                // }

                if (fileIsAbove25M(tableColumnsToSubmit[0].value.size)) {
                  setOutcome({
                    message: 'Please upload a file below 10MB.',
                    type: 'Danger'
                  });
                } else {
                  addObject(workerSubsidiarySlugOrWorkerAction, tableColumnsToSubmit).then(result2 => {
                    if (workerSubsidiarySlugOrWorkerAction === 'facepic-attachments') {
                      addAuditLog({
                        table_name: rightStubObj.table,
                        action_type: 'Create',
                        record_id: result2.data.id,
                        action_description: `Created ${rightStubObj.table.toLowerCase()} with id: ${result2.data.id}.`,
                        worker_id: result2.data.worker_id,
                        user_id: cookies['userId']
                      }).then(() => {
                        navigate(`/workers/${result2.data.worker_id}/${workerSubsidiarySlugOrWorkerAction}/${result2.data.id}/edit`);
                      });
                    } else {
                      if (workerSubsidiarySlugOrWorkerAction === 'ordinary-attachments') {
                        if (result2.data.job_id !== null && result2.data.problem_id !== null) {
                          addAuditLog({
                            table_name: rightStubObj.table,
                            action_type: 'Create',
                            record_id: result2.data.id,
                            action_description: `Created ${rightStubObj.table.toLowerCase()} with id: ${result2.data.id}.`,
                            worker_id: result2.data.worker_id,
                            job_id: result2.data.job_id,
                            problem_id: result2.data.problem_id,
                            user_id: cookies['userId']
                          }).then(() => {
                            navigate(`/workers/${result2.data.worker_id}/jobs/${result2.data.job_id}/problems/${result2.data.problem_id}/${workerSubsidiarySlugOrWorkerAction}/${result2.data.id}`);
                          });
                        } else if (result2.data.job_id !== null && result2.data.problem_id === null) {
                          addAuditLog({
                            table_name: rightStubObj.table,
                            action_type: 'Create',
                            record_id: result2.data.id,
                            action_description: `Created ${rightStubObj.table.toLowerCase()} with id: ${result2.data.id}.`,
                            worker_id: result2.data.worker_id,
                            job_id: result2.data.job_id,
                            user_id: cookies['userId']
                          }).then(() => {
                            navigate(`/workers/${result2.data.worker_id}/jobs/${result2.data.job_id}/${workerSubsidiarySlugOrWorkerAction}/${result2.data.id}`);
                          });
                        } else {
                          addAuditLog({
                            table_name: rightStubObj.table,
                            action_type: 'Create',
                            record_id: result2.data.id,
                            action_description: `Created ${rightStubObj.table.toLowerCase()} with id: ${result2.data.id}.`,
                            worker_id: result2.data.worker_id,
                            user_id: cookies['userId']
                          }).then(() => {
                            navigate(`/workers/${result2.data.worker_id}/${workerSubsidiarySlugOrWorkerAction}/${result2.data.id}`);
                          });
                        }
                      } else if (workerSubsidiarySlugOrWorkerAction === 'sensitive-attachments') {
                        if (result2.data.job_id !== null && result2.data.problem_id !== null) {
                          addAuditLog({
                            table_name: rightStubObj.table,
                            action_type: 'Create',
                            record_id: result2.data.sensitiveAttachment.id,
                            action_description: `Created ${rightStubObj.table.toLowerCase()} with id: ${result2.data.sensitiveAttachment.id}.`,
                            worker_id: result2.data.worker_id,
                            job_id: result2.data.job_id,
                            problem_id: result2.data.problem_id,
                            user_id: cookies['userId']
                          }).then(() => {
                            navigate(`/workers/${result2.data.worker_id}/jobs/${result2.data.job_id}/problems/${result2.data.problem_id}/${workerSubsidiarySlugOrWorkerAction}/${result2.data.sensitiveAttachment.id}`);
                          });
                        } else if (result2.data.job_id !== null && result2.data.problem_id === null) {
                          addAuditLog({
                            table_name: rightStubObj.table,
                            action_type: 'Create',
                            record_id: result2.data.sensitiveAttachment.id,
                            action_description: `Created ${rightStubObj.table.toLowerCase()} with id: ${result2.data.sensitiveAttachment.id}.`,
                            worker_id: result2.data.worker_id,
                            job_id: result2.data.job_id,
                            user_id: cookies['userId']
                          }).then(() => {
                            navigate(`/workers/${result2.data.worker_id}/jobs/${result2.data.job_id}/${workerSubsidiarySlugOrWorkerAction}/${result2.data.sensitiveAttachment.id}`);
                          });
                        } else {
                          addAuditLog({
                            table_name: rightStubObj.table,
                            action_type: 'Create',
                            record_id: result2.data.sensitiveAttachment.id,
                            action_description: `Created ${rightStubObj.table.toLowerCase()} with id: ${result2.data.sensitiveAttachment.id}.`,
                            worker_id: result2.data.worker_id,
                            user_id: cookies['userId']
                          }).then(() => {
                            navigate(`/workers/${result2.data.worker_id}/${workerSubsidiarySlugOrWorkerAction}/${result2.data.sensitiveAttachment.id}`);
                          });
                        }
                      } else {
                        addAuditLog({
                          table_name: rightStubObj.table,
                          action_type: 'Create',
                          record_id: result2.data.id,
                          action_description: `Created ${rightStubObj.table.toLowerCase()} with id: ${result2.data.id}.`,
                          worker_id: result2.data.worker_id,
                          user_id: cookies['userId']
                        }).then(() => {
                          navigate(`/workers/${result2.data.worker_id}/${workerSubsidiarySlugOrWorkerAction}/${result2.data.id}`);
                        });
                      }
                    }
                  }).catch(err => {
                    console.log(err);
                    setOutcome({
                      message: err.response.data.message,
                      type: 'Danger'
                    });
  
                    if (document.getElementById(err.response.data.input) !== null) {
                      document.getElementById(err.response.data.input).className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';
                    }
                  });
                }
              } else {
                const emptyFields = result.emptyFields;

                emptyFields.forEach((emptyField) => {
                  if (document.getElementById(emptyField)) {
                    document.getElementById(emptyField).className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';
                  } else {
                    if (document.getElementsByClassName(`bg-white relative w-full border border-gray-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500 sm:text-sm ${emptyField}`)[0] !== undefined) {
                      document.getElementsByClassName(`bg-white relative w-full border border-gray-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500 sm:text-sm ${emptyField}`)[0].className = `bg-red-50 relative w-full border border-red-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500 sm:text-sm ${emptyField}`;
                    } else if (document.getElementsByClassName(`w-full border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm ${emptyField}`)[0] !== undefined) {
                      document.getElementsByClassName(`w-full border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm ${emptyField}`)[0].className = `bg-red-50 w-full border border-red-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-red-500 focus:outline-none focus:ring-1 focus:ring-red-500 sm:text-sm ${emptyField}`;
                    }
                  }
                });

                setOutcome({
                  message: 'Compulsory fields have not been filled.',
                  type: 'Danger'
                });

                
              }
            } else if (buttonClicked === 'Go') {
              navigate(`/${tableSlug}/${workerId}/${workerSubsidiarySlugOrWorkerAction}`);
            } else {
              navigate(`/${tableSlug}/${workerId}/${workerSubsidiarySlugOrWorkerAction}`);
            }
          } else {
            if (buttonClicked === 'Edit') {
              navigate(`/${tableSlug}/${workerId}/${workerSubsidiarySlugOrWorkerAction}/${workerSubsidiaryIdOrWorkerSubsidiaryAction}/edit`);
            } else if (buttonClicked === 'Delete') {
              setDeleteSingle(`${rightStubObj.table.toLowerCase()}`);
              setDeletePlural(`${rightStubObj.workerSubsidiarySlugOrWorkerAction.replace(/-/g, ' ').toUpperCase()}`);
              setShowDeleteConfirmation(true);
            } else if (buttonClicked === 'Go') {
              navigate(`/${tableSlug}/${workerId}/${workerSubsidiarySlugOrWorkerAction}`);
            }
          }
        } else if (
          workerSubsidiarySlugOrWorkerAction !== undefined && 
          workerSubsidiaryIdOrWorkerSubsidiaryAction !== undefined && 
          jobSubsidiarySlugOrWorkerSubsidiaryAction !== undefined && 
          jobSubsidiaryIdOrJobSubsidiaryAction === undefined
        ) {
          if (buttonClicked === 'Save') {
            const result = checkCompulsoryFields(rightStubObj.tableColumns);
            if (result.allCompulsoryFieldsHaveBeenFilled) {
              const tableColumnsToSubmit = [...rightStubObj.tableColumns];
              tableColumnsToSubmit.push({
                token: cookies['token']
              });

              // let returnObj = await checkValidityOfWorkerJobProblem(tableColumnsToSubmit);
              // returnObj = returnObj.data;
              // if (returnObj && !returnObj.success) {
              //   setOutcome({
              //     message: returnObj.message,
              //     type: 'Danger'
              //   });

              //   return;
              // }
              
              if (rightStubObj.updatedFacepic !== '' && workerSubsidiarySlugOrWorkerAction === 'facepic-attachments') {
                const formInput = new FormData();

                tableColumnsToSubmit.forEach((obj, index) => {
                  formInput.append(obj['column_name'], obj['value']);
                });

                tableColumnsToSubmit.push({
                  column_name: 'updatedFacepic',
                  value: rightStubObj.updatedFacepic
                });

                tableColumnsToSubmit.push({
                  column_name: 'updatedFacepicDataFormat',
                  value: 'image/png'
                });

                formInput.append('token', tableColumnsToSubmit[tableColumnsToSubmit.length - 3]['token']);
                formInput.append('updatedFacepic', tableColumnsToSubmit[tableColumnsToSubmit.length - 2]['value']);
                formInput.append('updatedFacepicDataFormat', tableColumnsToSubmit[tableColumnsToSubmit.length - 1]['value']);
                formInput.append('image_edited', !!rightStubObj.image_edited);
                formInput.append('withCredentials', true);

                const result = await axios.put(`${backend_base_url()}/api/facepic-attachments/${workerSubsidiaryIdOrWorkerSubsidiaryAction}`, formInput, {
                  withCredentials: true,
                  headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
                });
                addAuditLog({
                  table_name: rightStubObj.table,
                  action_type: 'Update',
                  record_id: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                  action_description: `Updated ${rightStubObj.table.toLowerCase()} with id: ${workerSubsidiaryIdOrWorkerSubsidiaryAction}.`,
                  worker_id: result.data.worker_id,
                  user_id: cookies['userId']
                }).then(() => {
                  window.location.href=`/${tableSlug}/${result.data.worker_id}/${workerSubsidiarySlugOrWorkerAction}/${workerSubsidiaryIdOrWorkerSubsidiaryAction}`;
                });
              } else {
                let updatedWorkerId;

                for (let i = 0; i < tableColumnsToSubmit.length; i += 1) {
                  if (tableColumnsToSubmit[i]['column_name'] === 'worker_id') {
                    updatedWorkerId = tableColumnsToSubmit[i]['value'];
                    break;
                  }
                }

                getObject('workers', updatedWorkerId).then(result => {
                  editObject(workerSubsidiarySlugOrWorkerAction, tableColumnsToSubmit, workerSubsidiaryIdOrWorkerSubsidiaryAction).then(result2 => {
                    if (workerSubsidiarySlugOrWorkerAction === 'ordinary-attachments') {
                      if (result2.data.job_id !== null && result2.data.problem_id !== null) {
                        addAuditLog({
                          table_name: rightStubObj.table,
                          action_type: 'Update',
                          record_id: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                          action_description: `Updated ${rightStubObj.table.toLowerCase()} with id: ${workerSubsidiaryIdOrWorkerSubsidiaryAction}.`,
                          problem_id: result2.data.problem_id,
                          job_id: result2.data.job_id,
                          worker_id: updatedWorkerId,
                          user_id: cookies['userId']
                        }).then(() => {
                          navigate(`/${tableSlug}/${updatedWorkerId}/jobs/${result2.data.job_id}/problems/${result2.data.problem_id}/${workerSubsidiarySlugOrWorkerAction}/${workerSubsidiaryIdOrWorkerSubsidiaryAction}`);
                        });
                      } else if (result2.data.job_id !== null && result2.data.problem_id === null) {
                        addAuditLog({
                          table_name: rightStubObj.table,
                          action_type: 'Update',
                          record_id: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                          action_description: `Updated ${rightStubObj.table.toLowerCase()} with id: ${workerSubsidiaryIdOrWorkerSubsidiaryAction}.`,
                          job_id: result2.data.job_id,
                          worker_id: updatedWorkerId,
                          user_id: cookies['userId']
                        }).then(() => {
                          navigate(`/${tableSlug}/${updatedWorkerId}/jobs/${result2.data.job_id}/${workerSubsidiarySlugOrWorkerAction}/${workerSubsidiaryIdOrWorkerSubsidiaryAction}`);
                        });
                      } else {
                        addAuditLog({
                          table_name: rightStubObj.table,
                          action_type: 'Update',
                          record_id: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                          action_description: `Updated ${rightStubObj.table.toLowerCase()} with id: ${workerSubsidiaryIdOrWorkerSubsidiaryAction}.`,
                          worker_id: updatedWorkerId,
                          user_id: cookies['userId']
                        }).then(() => {
                          navigate(`/${tableSlug}/${updatedWorkerId}/${workerSubsidiarySlugOrWorkerAction}/${workerSubsidiaryIdOrWorkerSubsidiaryAction}`);
                        });
                      }
                    } else if (workerSubsidiarySlugOrWorkerAction === 'sensitive-attachments') {
                      if (result2.data.job_id !== null && result2.data.problem_id !== null) {
                        addAuditLog({
                          table_name: rightStubObj.table,
                          action_type: 'Update',
                          record_id: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                          action_description: `Updated ${rightStubObj.table.toLowerCase()} with id: ${workerSubsidiaryIdOrWorkerSubsidiaryAction}.`,
                          problem_id: result2.data.problem_id,
                          job_id: result2.data.job_id,
                          worker_id: updatedWorkerId,
                          user_id: cookies['userId']
                        }).then(() => {
                          navigate(`/${tableSlug}/${updatedWorkerId}/jobs/${result2.data.job_id}/problems/${result2.data.problem_id}/${workerSubsidiarySlugOrWorkerAction}/${workerSubsidiaryIdOrWorkerSubsidiaryAction}`);
                        });
                      } else if (result2.data.job_id !== null && result2.data.problem_id === null) {
                        addAuditLog({
                          table_name: rightStubObj.table,
                          action_type: 'Update',
                          record_id: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                          action_description: `Updated ${rightStubObj.table.toLowerCase()} with id: ${workerSubsidiaryIdOrWorkerSubsidiaryAction}.`,
                          job_id: result2.data.job_id,
                          worker_id: updatedWorkerId,
                          user_id: cookies['userId']
                        }).then(() => {
                          navigate(`/${tableSlug}/${updatedWorkerId}/jobs/${result2.data.job_id}/${workerSubsidiarySlugOrWorkerAction}/${workerSubsidiaryIdOrWorkerSubsidiaryAction}`);
                        });
                      } else {
                        addAuditLog({
                          table_name: rightStubObj.table,
                          action_type: 'Update',
                          record_id: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                          action_description: `Updated ${rightStubObj.table.toLowerCase()} with id: ${workerSubsidiaryIdOrWorkerSubsidiaryAction}.`,
                          worker_id: updatedWorkerId,
                          user_id: cookies['userId']
                        }).then(() => {
                          navigate(`/${tableSlug}/${updatedWorkerId}/${workerSubsidiarySlugOrWorkerAction}/${workerSubsidiaryIdOrWorkerSubsidiaryAction}`);
                        });
                      }
                    } else {
                      if (workerSubsidiarySlugOrWorkerAction === 'jobs') {
                        addAuditLog({
                          table_name: rightStubObj.table,
                          action_type: 'Update',
                          record_id: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                          action_description: `Updated ${rightStubObj.table.toLowerCase()} with id: ${workerSubsidiaryIdOrWorkerSubsidiaryAction}.`,
                          worker_id: updatedWorkerId,
                          user_id: cookies['userId']
                        }).then(() => {
                          navigate(`/${tableSlug}/${updatedWorkerId}/${workerSubsidiarySlugOrWorkerAction}/${workerSubsidiaryIdOrWorkerSubsidiaryAction}`);
                        });
                      } else {
                        addAuditLog({
                          table_name: rightStubObj.table,
                          action_type: 'Update',
                          record_id: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                          action_description: `Updated ${rightStubObj.table.toLowerCase()} with id: ${workerSubsidiaryIdOrWorkerSubsidiaryAction}.`,
                          worker_id: updatedWorkerId,
                          user_id: cookies['userId']
                        }).then(() => {
                          navigate(`/${tableSlug}/${updatedWorkerId}/${workerSubsidiarySlugOrWorkerAction}/${workerSubsidiaryIdOrWorkerSubsidiaryAction}`);
                        });
                      }
                    }
                  }).catch(err => {
                    setOutcome({
                      message: err.response.data.message,
                      type: 'Danger'
                    });

                  if (document.getElementById(err.response.data.input) !== null) {
                    document.getElementById(err.response.data.input).className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';
                  }

                    
                  });
                }).catch(err => {
                  setOutcome({
                    message: err.response.data.message,
                    type: 'Danger'
                  });

                  if (document.getElementById(err.response.data.input) !== null) {
                    document.getElementById(err.response.data.input).className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';
                  }

                  
                });
              }
            } else {
              const emptyFields = result.emptyFields;

              emptyFields.forEach((emptyField) => {
                if (document.getElementById(emptyField)) {
                  document.getElementById(emptyField).className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';
                } else {
                  if (document.getElementsByClassName(`bg-white relative w-full border border-gray-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500 sm:text-sm ${emptyField}`)[0] !== undefined) {
                    document.getElementsByClassName(`bg-white relative w-full border border-gray-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500 sm:text-sm ${emptyField}`)[0].className = `bg-red-50 relative w-full border border-red-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500 sm:text-sm ${emptyField}`;
                  } else if (document.getElementsByClassName(`w-full border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm ${emptyField}`)[0] !== undefined) {
                    document.getElementsByClassName(`w-full border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm ${emptyField}`)[0].className = `bg-red-50 w-full border border-red-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-red-500 focus:outline-none focus:ring-1 focus:ring-red-500 sm:text-sm ${emptyField}`;
                  }
                }
              });

              setOutcome({
                message: 'Compulsory fields have not been filled.',
                type: 'Danger'
              });

              
            }
          } else if (buttonClicked === 'Abandon') {
            navigate(`/${tableSlug}/${workerId}/${workerSubsidiarySlugOrWorkerAction}/${workerSubsidiaryIdOrWorkerSubsidiaryAction}`);
          } else if (buttonClicked === 'Add') {
            navigate(`/${tableSlug}/${workerId}/${workerSubsidiarySlugOrWorkerAction}/${workerSubsidiaryIdOrWorkerSubsidiaryAction}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}/add`);
          } else if (buttonClicked === 'Edit') {
            if (rightStubObj.objectsSelected.length === 0) {
              setOutcome({
                message: 'Please select at least 1 record to edit.',
                type: 'Danger'
              });

              
            } else if (rightStubObj.objectsSelected.length >= 2) {
              setOutcome({
                message: 'You can select only 1 record to edit.',
                type: 'Danger'
              });

              
            } else {
              navigate(`/${tableSlug}/${workerId}/${workerSubsidiarySlugOrWorkerAction}/${workerSubsidiaryIdOrWorkerSubsidiaryAction}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}/${rightStubObj.objectsSelected[0]['id']}/edit`);
            }
          } else if (buttonClicked === 'Download') {
            if (rightStubObj.objectsSelected.length === 0) {
              setOutcome({
                message: 'Please select at least 1 record to download.',
                type: 'Danger'
              });

              
            } else {
              if (rightStubObj.table === "ORDINARY ATTACHMENTS") {
                const fileNames = rightStubObj.objectsSelected.map((row) => row.file_path.substring(row.file_path.lastIndexOf('/') + 1));
                downloadFiles('ordinary-attachments', fileNames, cookies['userId']).then(result => {
                  window.location.href=`${backend_base_url()}/api/attachments/downloads/downloadFile?filename=${result.data}`;
                });

                rightStubObj.objectsSelected.forEach((row) => {
                  addAuditLog({
                    table_name: 'Ordinary Attachment',
                    action_type: 'Download',
                    record_id: row.id,
                    worker_id: row.worker_id,
                    job_id: row.job_id,
                    problem_id: row.problem_id,
                    action_description: `Downloaded file: ${row.filename}.`,
                    user_id: cookies['userId']
                  });
                });
              } else if (rightStubObj.table === "SENSITIVE ATTACHMENTS") {
                const fileNames = rightStubObj.objectsSelected.map((row) => row.file_path.substring(row.file_path.lastIndexOf('/') + 1));
                downloadFiles('sensitive-attachments', fileNames, cookies['userId']).then(result => {
                  window.location.href=`${backend_base_url()}/api/attachments/downloads/downloadFile?filename=${result.data}`;
                });
                rightStubObj.objectsSelected.forEach((row) => {
                  addAuditLog({
                    table_name: 'Sensitive Attachment',
                    action_type: 'Download',
                    record_id: row.id,
                    worker_id: row.worker_id,
                    job_id: row.job_id,
                    problem_id: row.problem_id,
                    action_description: `Downloaded file: ${row.filename}.`,
                    user_id: cookies['userId']
                  });
                });
              } else {
                const fileNames = rightStubObj.objectsSelected.map((row) => row.file_path.substring(row.file_path.lastIndexOf('/') + 1));
                downloadFiles('attachments', fileNames, cookies['userId']).then(result => {
                  window.location.href=`${backend_base_url()}/api/attachments/downloads/downloadFile?filename=${result.data}`;
                });
  
                rightStubObj.objectsSelected.forEach((row) => {
                  addAuditLog({
                    table_name: rightStubObj.table,
                    action_type: 'Download',
                    record_id: row.id,
                    worker_id: row.worker_id,
                    job_id: row.job_id,
                    problem_id: row.problem_id,
                    action_description: `Downloaded file: ${row.filename}.`,
                    user_id: cookies['userId']
                  });
                });
              }
            }
          } else if (buttonClicked === 'Delete') {
            if (rightStubObj.objectsSelected.length === 0) {
              setOutcome({
                message: 'Please select at least 1 record to delete.',
                type: 'Danger'
              });

              
            } else {
              setDeleteSingle(`${rightStubObj.table.toLowerCase()}`);
              setDeletePlural(`${rightStubObj.jobSubsidiarySlugOrWorkerSubsidiaryAction.replace(/-/g, ' ').toUpperCase()}`);
              setShowDeleteConfirmation(true);
            }
          }
        } else if (
          workerSubsidiarySlugOrWorkerAction !== undefined && 
          workerSubsidiaryIdOrWorkerSubsidiaryAction !== undefined && 
          jobSubsidiarySlugOrWorkerSubsidiaryAction !== undefined && 
          jobSubsidiaryIdOrJobSubsidiaryAction !== undefined &&
          problemSubsidiarySlugOrJobSubsidiaryAction === undefined
        ) {
          if (buttonClicked === 'Save') {
            const result = checkCompulsoryFields(tableColumns);
            if (result.allCompulsoryFieldsHaveBeenFilled) {
              const tableColumnsToSubmit = [...tableColumns];
              tableColumnsToSubmit.push({
                token: cookies['token']
              });

              addObject(jobSubsidiarySlugOrWorkerSubsidiaryAction, tableColumnsToSubmit).then(result => {
                if (jobSubsidiarySlugOrWorkerSubsidiaryAction === 'problems') {
                  addAuditLog({
                    table_name: rightStubObj.table,
                    action_type: 'Create',
                    record_id: result.data.id,
                    action_description: `Created ${rightStubObj.table.toLowerCase()} with id: ${result.data.id}.`,
                    problem_id: result.data.problem_id,
                    job_id: result.data.job_id,
                    worker_id: result.data.worker_id,
                    user_id: cookies['userId']
                  }).then(() => {
                    navigate(`/workers/${result.data.worker_id}/${workerSubsidiarySlugOrWorkerAction}/${result.data.job_id}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}/${result.data.id}`);
                  });
                } else {
                  addAuditLog({
                    table_name: rightStubObj.table,
                    action_type: 'Create',
                    record_id: result.data.id,
                    action_description: `Created ${rightStubObj.table.toLowerCase()} with id: ${result.data.id}.`,
                    job_id: result.data.job_id,
                    worker_id: result.data.worker_id,
                    user_id: cookies['userId']
                  }).then(() => {
                    navigate(`/workers/${result.data.worker_id}/${workerSubsidiarySlugOrWorkerAction}/${result.data.job_id}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}/${result.data.id}`);
                  }).catch(err => {
                    console.log(err);
                  })
                }
              }).catch(err => {
                setOutcome({
                  message: err.response.data.message,
                  type: 'Danger'
                });

                if (document.getElementById(err.response.data.input) !== null) {
                  document.getElementById(err.response.data.input).className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';
                }

                
              });
            } else {
              const emptyFields = result.emptyFields;

              emptyFields.forEach((emptyField) => {
                if (document.getElementById(emptyField)) {
                  document.getElementById(emptyField).className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';
                } else {
                  if (document.getElementsByClassName(`bg-white relative w-full border border-gray-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500 sm:text-sm ${emptyField}`)[0] !== undefined) {
                    document.getElementsByClassName(`bg-white relative w-full border border-gray-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500 sm:text-sm ${emptyField}`)[0].className = `bg-red-50 relative w-full border border-red-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500 sm:text-sm ${emptyField}`;
                  } else if (document.getElementsByClassName(`w-full border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm ${emptyField}`)[0] !== undefined) {
                    document.getElementsByClassName(`w-full border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm ${emptyField}`)[0].className = `bg-red-50 w-full border border-red-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-red-500 focus:outline-none focus:ring-1 focus:ring-red-500 sm:text-sm ${emptyField}`;
                  }
                }
              });

              setOutcome({
                message: 'Compulsory fields have not been filled.',
                type: 'Danger'
              });

              
            }
          } else if (buttonClicked === 'Abandon') {
            navigate(`/${tableSlug}/${workerId}/${workerSubsidiarySlugOrWorkerAction}/${workerSubsidiaryIdOrWorkerSubsidiaryAction}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}`);
          } else if (buttonClicked === 'Edit') {
            navigate(`/${tableSlug}/${workerId}/${workerSubsidiarySlugOrWorkerAction}/${workerSubsidiaryIdOrWorkerSubsidiaryAction}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}/${jobSubsidiaryIdOrJobSubsidiaryAction}/edit`);
          } else if (buttonClicked === 'Delete') {
            setDeleteSingle(`${rightStubObj.table.toLowerCase()}`);
            setDeletePlural(`${rightStubObj.jobSubsidiarySlugOrWorkerSubsidiaryAction.replace(/-/g, ' ').toUpperCase()}`);
            setShowDeleteConfirmation(true);
          } else if (buttonClicked === 'Upload') {
            const result = checkCompulsoryFields(rightStubObj.tableColumns);
            
            if (result.allCompulsoryFieldsHaveBeenFilled) {
              const tableColumnsToSubmit = [...rightStubObj.tableColumns];
              tableColumnsToSubmit.push({
                token: cookies['token']
              });

              // let returnObj = await checkValidityOfWorkerJobProblem(tableColumnsToSubmit);
              // returnObj = returnObj.data;
              // if (returnObj && !returnObj.success) {
              //   setOutcome({
              //     message: returnObj.message,
              //     type: 'Danger'
              //   });

              //   return;
              // }

              if (fileIsAbove25M(tableColumnsToSubmit[0].value.size)) {
                setOutcome({
                  message: 'Please upload a file below 10MB.',
                  type: 'Danger'
                });
              } else {
                addObject(jobSubsidiarySlugOrWorkerSubsidiaryAction, tableColumnsToSubmit).then(result => {
                  if (jobSubsidiarySlugOrWorkerSubsidiaryAction === 'ordinary-attachments') {
                    if (result.data.job_id !== null && result.data.problem_id !== null) {
                      addAuditLog({
                        table_name: rightStubObj.table,
                        action_type: 'Create',
                        record_id: result.data.id,
                        action_description: `Created ${rightStubObj.table.toLowerCase()} with id: ${result.data.id}.`,
                        problem_id: result.data.problem_id,
                        job_id: result.data.job_id,
                        worker_id: result.data.worker_id,
                        user_id: cookies['userId']
                      }).then(() => {
                        navigate(`/workers/${result.data.worker_id}/jobs/${result.data.job_id}/problems/${result.data.problem_id}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}/${result.data.id}`);
                      });
                    } else if (result.data.job_id !== null && result.data.problem_id === null) {
                      addAuditLog({
                        table_name: rightStubObj.table,
                        action_type: 'Create',
                        action_description: `Created ${rightStubObj.table.toLowerCase()} with id: ${result.data.id}.`,
                        job_id: result.data.job_id,
                        worker_id: result.data.worker_id,
                        user_id: cookies['userId']
                      }).then(() => {
                        navigate(`/workers/${result.data.worker_id}/jobs/${result.data.job_id}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}/${result.data.id}`);
                      });
                    } else {
                      addAuditLog({
                        table_name: rightStubObj.table,
                        action_type: 'Create',
                        record_id: result.data.id,
                        action_description: `Created ${rightStubObj.table.toLowerCase()} with id: ${result.data.id}.`,
                        worker_id: result.data.worker_id,
                        user_id: cookies['userId']
                      }).then(() => {
                        navigate(`/workers/${result.data.worker_id}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}/${result.data.id}`);
                      });
                    }
                  } else if (jobSubsidiarySlugOrWorkerSubsidiaryAction === 'sensitive-attachments') {
                    if (result.data.job_id !== null && result.data.problem_id !== null) {
                      addAuditLog({
                        table_name: rightStubObj.table,
                        action_type: 'Create',
                        record_id: result.data.sensitiveAttachment.id,
                        action_description: `Created ${rightStubObj.table.toLowerCase()} with id: ${result.data.sensitiveAttachment.id}.`,
                        problem_id: result.data.problem_id,
                        job_id: result.data.job_id,
                        worker_id: result.data.worker_id,
                        user_id: cookies['userId']
                      }).then(() => {
                        navigate(`/workers/${result.data.worker_id}/jobs/${result.data.job_id}/problems/${result.data.problem_id}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}/${result.data.sensitiveAttachment.id}`);
                      });
                    } else if (result.data.job_id !== null && result.data.problem_id === null) {
                      addAuditLog({
                        table_name: rightStubObj.table,
                        action_type: 'Create',
                        record_id: result.data.sensitiveAttachment.id,
                        action_description: `Created ${rightStubObj.table.toLowerCase()} with id: ${result.data.sensitiveAttachment.id}.`,
                        job_id: result.data.job_id,
                        worker_id: result.data.worker_id,
                        user_id: cookies['userId']
                      }).then(() => {
                        addAuditLog({
                          table_name: rightStubObj.table,
                          action_type: 'Create',
                        record_id: result.data.sensitiveAttachment.id,
                          action_description: `Created ${rightStubObj.table.toLowerCase()} with id: ${result.data.sensitiveAttachment.id}.`,
                          worker_id: result.data.worker_id,
                          user_id: cookies['userId']
                        }).then(() => {
                          navigate(`/workers/${result.data.worker_id}/jobs/${result.data.job_id}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}/${result.data.sensitiveAttachment.id}`);
                        });
                      });
                    } else {
                      addAuditLog({
                        table_name: rightStubObj.table,
                        action_type: 'Create',
                        record_id: result.data.sensitiveAttachment.id,
                        action_description: `Created ${rightStubObj.table.toLowerCase()} with id: ${result.data.sensitiveAttachment.id}.`,
                        worker_id: result.data.worker_id,
                        user_id: cookies['userId']
                      }).then(() => {
                        navigate(`/workers/${result.data.worker_id}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}/${result.data.sensitiveAttachment.id}`);
                      });
                    }
                  } else {
                    addAuditLog({
                      table_name: rightStubObj.table,
                      action_type: 'Create',
                      record_id: result.data.id,
                      action_description: `Created ${rightStubObj.table.toLowerCase()} with id: ${result.data.id}.`,
                      job_id: result.data.job_id,
                      worker_id: result.data.worker_id,
                      user_id: cookies['userId']
                    }).then(() => {
                      navigate(`/workers/${result.data.worker_id}/${workerSubsidiarySlugOrWorkerAction}/${result.data.job_id}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}/${result.data.id}`);
                    });
                  }
  
                }).catch(err => {
                  console.log(err);
                  setOutcome({
                    message: err.response.data.message,
                    type: 'Danger'
                  });
  
                  if (document.getElementById(err.response.data.input) !== null) {
                    document.getElementById(err.response.data.input).className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';
                  }
                });
              }
            } else {
              const emptyFields = result.emptyFields;

              emptyFields.forEach((emptyField) => {
                if (document.getElementById(emptyField)) {
                  document.getElementById(emptyField).className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';
                } else {
                  if (document.getElementsByClassName(`bg-white relative w-full border border-gray-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500 sm:text-sm ${emptyField}`)[0] !== undefined) {
                    document.getElementsByClassName(`bg-white relative w-full border border-gray-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500 sm:text-sm ${emptyField}`)[0].className = `bg-red-50 relative w-full border border-red-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500 sm:text-sm ${emptyField}`;
                  } else if (document.getElementsByClassName(`w-full border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm ${emptyField}`)[0] !== undefined) {
                    document.getElementsByClassName(`w-full border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm ${emptyField}`)[0].className = `bg-red-50 w-full border border-red-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-red-500 focus:outline-none focus:ring-1 focus:ring-red-500 sm:text-sm ${emptyField}`;
                  }
                }
              });

              setOutcome({
                message: 'Compulsory fields have not been filled.',
                type: 'Danger'
              });

              
            }
          } else if (buttonClicked === 'Go') {
            navigate(`/${tableSlug}/${workerId}/${workerSubsidiarySlugOrWorkerAction}/${workerSubsidiaryIdOrWorkerSubsidiaryAction}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}`);
          }
        }  else if (
          workerSubsidiarySlugOrWorkerAction !== undefined && 
          workerSubsidiaryIdOrWorkerSubsidiaryAction !== undefined && 
          jobSubsidiarySlugOrWorkerSubsidiaryAction !== undefined && 
          jobSubsidiaryIdOrJobSubsidiaryAction !== undefined &&
          problemSubsidiarySlugOrJobSubsidiaryAction !== undefined &&
          problemSubsidiaryIdOrProblemSubsidiaryAction === undefined
        ) {
          if (buttonClicked === 'Save') {
            const result = checkCompulsoryFields(rightStubObj.tableColumns);
            if (result.allCompulsoryFieldsHaveBeenFilled) {
              const tableColumnsToSubmit = [...rightStubObj.tableColumns];
              tableColumnsToSubmit.push({
                token: cookies['token']
              });

              let updatedWorkerId;
              let updatedJobId;

              for (let i = 0; i < tableColumnsToSubmit.length; i += 1) {
                if (tableColumnsToSubmit[i]['column_name'] === 'worker_id') {
                  updatedWorkerId = tableColumnsToSubmit[i]['value'];
                }
                if (tableColumnsToSubmit[i]['column_name'] === 'job_id') {
                  updatedJobId = tableColumnsToSubmit[i]['value'];
                }
              }

              for (let i = 0; i < tableColumnsToSubmit.length; i += 1) {
                if (tableColumnsToSubmit[i]['column_name'] === 'employer_name' && updatedJobId !== undefined) {
                  updatedJobId = tableColumnsToSubmit[i]['job_id'];
                }
              }

              // let returnObj = await checkValidityOfWorkerJobProblem(tableColumnsToSubmit);
              // returnObj = returnObj.data;
              // if (returnObj && !returnObj.success) {
              //   setOutcome({
              //     message: returnObj.message,
              //     type: 'Danger'
              //   });

              //   return;
              // }
              
              getObject('workers', updatedWorkerId).then(result => {
                if (updatedJobId !== null && updatedJobId !== undefined && updatedJobId !== "") {
                  getJobByJobIdAndWorkerId(updatedJobId, updatedWorkerId).then(result => {
                    editObject(jobSubsidiarySlugOrWorkerSubsidiaryAction, tableColumnsToSubmit, jobSubsidiaryIdOrJobSubsidiaryAction).then(result2 => {
                      if (jobSubsidiarySlugOrWorkerSubsidiaryAction === 'ordinary-attachments') {
                        if (result2.data.job_id !== null && result2.data.problem_id !== null) {
                          addAuditLog({
                            table_name: rightStubObj.table,
                            action_type: 'Update',
                            record_id: jobSubsidiaryIdOrJobSubsidiaryAction,
                            action_description: `Updated ${rightStubObj.table.toLowerCase()} with id: ${jobSubsidiaryIdOrJobSubsidiaryAction}.`,
                            problem_id: result2.data.problem_id,
                            job_id: result2.data.job_id,
                            worker_id: updatedWorkerId,
                            user_id: cookies['userId']
                          }).then(() => {
                            navigate(`/${tableSlug}/${updatedWorkerId}/jobs/${result2.data.job_id}/problems/${result2.data.problem_id}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}/${jobSubsidiaryIdOrJobSubsidiaryAction}`);
                          });
                        } else if (result2.data.job_id !== null && result2.data.problem_id === null) {
                          addAuditLog({
                            table_name: rightStubObj.table,
                            action_type: 'Update',
                            record_id: jobSubsidiaryIdOrJobSubsidiaryAction,
                            action_description: `Updated ${rightStubObj.table.toLowerCase()} with id: ${jobSubsidiaryIdOrJobSubsidiaryAction}.`,
                            job_id: result2.data.job_id,
                            worker_id: updatedWorkerId,
                            user_id: cookies['userId']
                          }).then(() => {
                            navigate(`/${tableSlug}/${updatedWorkerId}/jobs/${result2.data.job_id}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}/${jobSubsidiaryIdOrJobSubsidiaryAction}`);
                          });
                        } else {
                          addAuditLog({
                            table_name: rightStubObj.table,
                            action_type: 'Update',
                            record_id: jobSubsidiaryIdOrJobSubsidiaryAction,
                            action_description: `Updated ${rightStubObj.table.toLowerCase()} with id: ${jobSubsidiaryIdOrJobSubsidiaryAction}.`,
                            worker_id: updatedWorkerId,
                            user_id: cookies['userId']
                          }).then(() => {
                            navigate(`/${tableSlug}/${updatedWorkerId}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}/${jobSubsidiaryIdOrJobSubsidiaryAction}`);
                          });
                        }
                      } else if (jobSubsidiarySlugOrWorkerSubsidiaryAction === 'sensitive-attachments') {
                        if (result2.data.job_id !== null && result2.data.problem_id !== null) {
                          addAuditLog({
                            table_name: rightStubObj.table,
                            action_type: 'Update',
                            record_id: jobSubsidiaryIdOrJobSubsidiaryAction,
                            action_description: `Updated ${rightStubObj.table.toLowerCase()} with id: ${jobSubsidiaryIdOrJobSubsidiaryAction}.`,
                            problem_id: result2.data.problem_id,
                            job_id: result2.data.job_id,
                            worker_id: updatedWorkerId,
                            user_id: cookies['userId']
                          }).then(() => {
                            navigate(`/${tableSlug}/${updatedWorkerId}/jobs/${result2.data.job_id}/problems/${result2.data.problem_id}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}/${jobSubsidiaryIdOrJobSubsidiaryAction}`);
                          });
                        } else if (result2.data.job_id !== null && result2.data.problem_id === null) {
                          addAuditLog({
                            table_name: rightStubObj.table,
                            action_type: 'Update',
                            record_id: jobSubsidiaryIdOrJobSubsidiaryAction,
                            action_description: `Updated ${rightStubObj.table.toLowerCase()} with id: ${jobSubsidiaryIdOrJobSubsidiaryAction}.`,
                            job_id: result2.data.job_id,
                            worker_id: updatedWorkerId,
                            user_id: cookies['userId']
                          }).then(() => {
                            navigate(`/${tableSlug}/${updatedWorkerId}/jobs/${result2.data.job_id}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}/${jobSubsidiaryIdOrJobSubsidiaryAction}`);
                          });
                        } else {
                          navigate(`/${tableSlug}/${updatedWorkerId}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}/${jobSubsidiaryIdOrJobSubsidiaryAction}`);
                        }
                      } else {
                        if (jobSubsidiarySlugOrWorkerSubsidiaryAction === 'problems') {
                          addAuditLog({
                            table_name: rightStubObj.table,
                            action_type: 'Update',
                            record_id: result2.data.id,
                            action_description: `Updated ${rightStubObj.table.toLowerCase()} with id: ${result2.data.id}.`,
                            job_id: result2.data.job_id,
                            worker_id: result2.data.worker_id,
                            user_id: cookies['userId']
                          }).then(() => {
                            navigate(`/${tableSlug}/${result2.data.worker_id}/${workerSubsidiarySlugOrWorkerAction}/${result2.data.job_id}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}/${result2.data.id}`);
                          });
                        } else {
                          addAuditLog({
                            table_name: rightStubObj.table,
                            action_type: 'Update',
                            record_id: result2.data.id,
                            action_description: `Updated ${rightStubObj.table.toLowerCase()} with id: ${result2.data.id}.`,
                            job_id: result2.data.job_id,
                            worker_id: result2.data.worker_id,
                            user_id: cookies['userId']
                          }).then(() => {
                            navigate(`/${tableSlug}/${result2.data.worker_id}/${workerSubsidiarySlugOrWorkerAction}/${result2.data.job_id}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}/${result2.data.id}`);
                          });
                        }
                      }
                    }).catch(err => {
                      setOutcome({
                        message: err.response.data.message,
                        type: 'Danger'
                      });

                    if (document.getElementById(err.response.data.input) !== null) {
                      document.getElementById(err.response.data.input).className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';
                    }

                      
                    });
                  }).catch(err => {
                    setOutcome({
                      message: err.response.data.message,
                      type: 'Danger'
                    });

                    if (document.getElementById(err.response.data.input) !== null) {
                      document.getElementById(err.response.data.input).className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';
                    }

                    
                  });
                } else {
                  editObject(jobSubsidiarySlugOrWorkerSubsidiaryAction, tableColumnsToSubmit, jobSubsidiaryIdOrJobSubsidiaryAction).then(result2 => {
                    if (jobSubsidiarySlugOrWorkerSubsidiaryAction === 'ordinary-attachments') {
                      if (result2.data.job_id !== null && result2.data.problem_id !== null) {
                        addAuditLog({
                          table_name: rightStubObj.table,
                          action_type: 'Update',
                          record_id: jobSubsidiaryIdOrJobSubsidiaryAction,
                          action_description: `Updated ${rightStubObj.table.toLowerCase()} with id: ${jobSubsidiaryIdOrJobSubsidiaryAction}.`,
                          problem_id: result2.data.problem_id,
                          job_id: result2.data.job_id,
                          worker_id: updatedWorkerId,
                          user_id: cookies['userId']
                        }).then(() => {
                          navigate(`/${tableSlug}/${updatedWorkerId}/jobs/${result2.data.job_id}/problems/${result2.data.problem_id}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}/${jobSubsidiaryIdOrJobSubsidiaryAction}`);
                        });
                      } else if (result2.data.job_id !== null && result2.data.problem_id === null) {
                        addAuditLog({
                          table_name: rightStubObj.table,
                          action_type: 'Update',
                          record_id: jobSubsidiaryIdOrJobSubsidiaryAction,
                          action_description: `Updated ${rightStubObj.table.toLowerCase()} with id: ${jobSubsidiaryIdOrJobSubsidiaryAction}.`,
                          job_id: result2.data.job_id,
                          worker_id: updatedWorkerId,
                          user_id: cookies['userId']
                        }).then(() => {
                          navigate(`/${tableSlug}/${updatedWorkerId}/jobs/${result2.data.job_id}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}/${jobSubsidiaryIdOrJobSubsidiaryAction}`);
                        });
                      } else {
                        addAuditLog({
                          table_name: rightStubObj.table,
                          action_type: 'Update',
                          record_id: jobSubsidiaryIdOrJobSubsidiaryAction,
                          action_description: `Updated ${rightStubObj.table.toLowerCase()} with id: ${jobSubsidiaryIdOrJobSubsidiaryAction}.`,
                          worker_id: updatedWorkerId,
                          user_id: cookies['userId']
                        }).then(() => {
                          navigate(`/${tableSlug}/${updatedWorkerId}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}/${jobSubsidiaryIdOrJobSubsidiaryAction}`);
                        });
                      }
                    } else if (jobSubsidiarySlugOrWorkerSubsidiaryAction === 'sensitive-attachments') {
                      if (result2.data.job_id !== null && result2.data.problem_id !== null) {
                        addAuditLog({
                          table_name: rightStubObj.table,
                          action_type: 'Update',
                          record_id: jobSubsidiaryIdOrJobSubsidiaryAction,
                          action_description: `Updated ${rightStubObj.table.toLowerCase()} with id: ${jobSubsidiaryIdOrJobSubsidiaryAction}.`,
                          problem_id: result2.data.problem_id,
                          job_id: result2.data.job_id,
                          worker_id: updatedWorkerId,
                          user_id: cookies['userId']
                        }).then(() => {
                          navigate(`/${tableSlug}/${updatedWorkerId}/jobs/${result2.data.job_id}/problems/${result2.data.problem_id}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}/${jobSubsidiaryIdOrJobSubsidiaryAction}`);
                        });
                      } else if (result2.data.job_id !== null && result2.data.problem_id === null) {
                        addAuditLog({
                          table_name: rightStubObj.table,
                          action_type: 'Update',
                          record_id: jobSubsidiaryIdOrJobSubsidiaryAction,
                          action_description: `Updated ${rightStubObj.table.toLowerCase()} with id: ${jobSubsidiaryIdOrJobSubsidiaryAction}.`,
                          job_id: result2.data.job_id,
                          worker_id: updatedWorkerId,
                          user_id: cookies['userId']
                        }).then(() => {
                          navigate(`/${tableSlug}/${updatedWorkerId}/jobs/${result2.data.job_id}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}/${jobSubsidiaryIdOrJobSubsidiaryAction}`);
                        });
                      } else {
                        addAuditLog({
                          table_name: rightStubObj.table,
                          action_type: 'Update',
                          record_id: jobSubsidiaryIdOrJobSubsidiaryAction,
                          action_description: `Updated ${rightStubObj.table.toLowerCase()} with id: ${jobSubsidiaryIdOrJobSubsidiaryAction}.`,
                          worker_id: updatedWorkerId,
                          user_id: cookies['userId']
                        }).then(() => {
                          navigate(`/${tableSlug}/${updatedWorkerId}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}/${jobSubsidiaryIdOrJobSubsidiaryAction}`);
                        });
                      }
                    } else {
                      if (workerSubsidiarySlugOrWorkerAction === 'jobs') {
                        addAuditLog({
                          table_name: rightStubObj.table,
                          action_type: 'Update',
                          record_id: jobSubsidiaryIdOrJobSubsidiaryAction,
                          action_description: `Updated ${rightStubObj.table.toLowerCase()} with id: ${jobSubsidiaryIdOrJobSubsidiaryAction}.`,
                          job_id: jobSubsidiaryIdOrJobSubsidiaryAction,
                          worker_id: updatedWorkerId,
                          user_id: cookies['userId']
                        }).then(() => {
                          navigate(`/${tableSlug}/${updatedWorkerId}/${workerSubsidiarySlugOrWorkerAction}/${jobSubsidiaryIdOrJobSubsidiaryAction}`);
                        });
                      } else {
                        addAuditLog({
                          table_name: rightStubObj.table,
                          action_type: 'Update',
                          record_id: jobSubsidiaryIdOrJobSubsidiaryAction,
                          action_description: `Updated ${rightStubObj.table.toLowerCase()} with id: ${jobSubsidiaryIdOrJobSubsidiaryAction}.`,
                          worker_id: updatedWorkerId,
                          user_id: cookies['userId']
                        }).then(() => {
                          navigate(`/${tableSlug}/${updatedWorkerId}/${workerSubsidiarySlugOrWorkerAction}/${jobSubsidiaryIdOrJobSubsidiaryAction}`);
                        });
                      }
                    }
                  }).catch(err => {
                    setOutcome({
                      message: err.response.data.message,
                      type: 'Danger'
                    });

                  if (document.getElementById(err.response.data.input) !== null) {
                    document.getElementById(err.response.data.input).className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';
                  }

                    
                  });
                }
              }).catch(err => {
                setOutcome({
                  message: err.response.data.message,
                  type: 'Danger'
                });

                if (document.getElementById(err.response.data.input) !== null) {
                  document.getElementById(err.response.data.input).className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';
                }

                
              });
            } else {
              const emptyFields = result.emptyFields;

              emptyFields.forEach((emptyField) => {
                if (document.getElementById(emptyField)) {
                  document.getElementById(emptyField).className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';
                } else {
                  if (document.getElementsByClassName(`bg-white relative w-full border border-gray-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500 sm:text-sm ${emptyField}`)[0] !== undefined) {
                    document.getElementsByClassName(`bg-white relative w-full border border-gray-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500 sm:text-sm ${emptyField}`)[0].className = `bg-red-50 relative w-full border border-red-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500 sm:text-sm ${emptyField}`;
                  } else if (document.getElementsByClassName(`w-full border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm ${emptyField}`)[0] !== undefined) {
                    document.getElementsByClassName(`w-full border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm ${emptyField}`)[0].className = `bg-red-50 w-full border border-red-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-red-500 focus:outline-none focus:ring-1 focus:ring-red-500 sm:text-sm ${emptyField}`;
                  }
                }
              });

              setOutcome({
                message: 'Compulsory fields have not been filled.',
                type: 'Danger'
              });

              
            }
          } else if (buttonClicked === 'Abandon') {
            navigate(`/${tableSlug}/${workerId}/${workerSubsidiarySlugOrWorkerAction}/${workerSubsidiaryIdOrWorkerSubsidiaryAction}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}/${jobSubsidiaryIdOrJobSubsidiaryAction}`);
          } else if (buttonClicked === 'Add') {
            navigate(`/${tableSlug}/${workerId}/${workerSubsidiarySlugOrWorkerAction}/${workerSubsidiaryIdOrWorkerSubsidiaryAction}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}/${jobSubsidiaryIdOrJobSubsidiaryAction}/${problemSubsidiarySlugOrJobSubsidiaryAction}/add`);
          } else if (buttonClicked === 'Edit') {
            if (rightStubObj.objectsSelected.length === 0) {
              setOutcome({
                message: 'Please select at least 1 record to edit.',
                type: 'Danger'
              });

              
            } else if (rightStubObj.objectsSelected.length >= 2) {
              setOutcome({
                message: 'You can select only 1 record to edit.',
                type: 'Danger'
              });

              
            } else {
              navigate(`/${tableSlug}/${workerId}/${workerSubsidiarySlugOrWorkerAction}/${workerSubsidiaryIdOrWorkerSubsidiaryAction}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}/${jobSubsidiaryIdOrJobSubsidiaryAction}/${problemSubsidiarySlugOrJobSubsidiaryAction}/${rightStubObj.objectsSelected[0]['id']}/edit`);
            }
          } else if (buttonClicked === 'Download') {
            if (rightStubObj.objectsSelected.length === 0) {
              setOutcome({
                message: 'Please select at least 1 record to download.',
                type: 'Danger'
              });

              
            } else {
              if (rightStubObj.table === "ORDINARY ATTACHMENTS") {
                const fileNames = rightStubObj.objectsSelected.map((row) => row.file_path.substring(row.file_path.lastIndexOf('/') + 1));
                downloadFiles('ordinary-attachments', fileNames, cookies['userId']).then(result => {
                  window.location.href=`${backend_base_url()}/api/attachments/downloads/downloadFile?filename=${result.data}`;
                });
                rightStubObj.objectsSelected.forEach((row) => {
                  addAuditLog({
                    table_name: 'Ordinary Attachment',
                    action_type: 'Download',
                    record_id: row.id,
                    worker_id: row.worker_id,
                    job_id: row.job_id,
                    problem_id: row.problem_id,
                    action_description: `Downloaded file: ${row.filename}.`,
                    user_id: cookies['userId']
                  });
                });
              } else if (rightStubObj.table === "SENSITIVE ATTACHMENTS") {
                const fileNames = rightStubObj.objectsSelected.map((row) => row.file_path.substring(row.file_path.lastIndexOf('/') + 1));
                downloadFiles('sensitive-attachments', fileNames, cookies['userId']).then(result => {
                  window.location.href=`${backend_base_url()}/api/attachments/downloads/downloadFile?filename=${result.data}`;
                });
                rightStubObj.objectsSelected.forEach((row) => {
                  addAuditLog({
                    table_name: 'Sensitive Attachment',
                    action_type: 'Download',
                    record_id: row.id,
                    worker_id: row.worker_id,
                    job_id: row.job_id,
                    problem_id: row.problem_id,
                    action_description: `Downloaded file: ${row.filename}.`,
                    user_id: cookies['userId']
                  });
                });
              } else {
                const fileNames = rightStubObj.objectsSelected.map((row) => row.file_path.substring(row.file_path.lastIndexOf('/') + 1));
                downloadFiles('attachments', fileNames, cookies['userId']).then(result => {
                  window.location.href=`${backend_base_url()}/api/attachments/downloads/downloadFile?filename=${result.data}`;
                });
  
                rightStubObj.objectsSelected.forEach((row) => {
                  addAuditLog({
                    table_name: rightStubObj.table,
                    action_type: 'Download',
                    record_id: row.id,
                    worker_id: row.worker_id,
                    job_id: row.job_id,
                    problem_id: row.problem_id,
                    action_description: `Downloaded file: ${row.filename}.`,
                    user_id: cookies['userId']
                  });
                });
              }
            }
          } else if (buttonClicked === 'Delete') {
            if (rightStubObj.objectsSelected.length === 0) {
              setOutcome({
                message: 'Please select at least 1 record to delete.',
                type: 'Danger'
              });

              
            } else {
              setDeleteSingle(`${rightStubObj.table.toLowerCase()}`);
              setDeletePlural(`${rightStubObj.problemSubsidiarySlugOrJobSubsidiaryAction.replace(/-/g, ' ').toUpperCase()}`);
              setShowDeleteConfirmation(true);
            }
          }
        } else if (
          workerSubsidiarySlugOrWorkerAction !== undefined && 
          workerSubsidiaryIdOrWorkerSubsidiaryAction !== undefined && 
          jobSubsidiarySlugOrWorkerSubsidiaryAction !== undefined && 
          jobSubsidiaryIdOrJobSubsidiaryAction !== undefined &&
          problemSubsidiarySlugOrJobSubsidiaryAction !== undefined &&
          problemSubsidiaryIdOrProblemSubsidiaryAction !== undefined &&
          problemSubsidiaryAction === undefined
        ) {
          if (buttonClicked === 'Save') {
            const result = checkCompulsoryFields(tableColumns);
            if (result.allCompulsoryFieldsHaveBeenFilled) {
              const tableColumnsToSubmit = [...tableColumns];
              tableColumnsToSubmit.push({
                token: cookies['token']
              });

              addObject(problemSubsidiarySlugOrJobSubsidiaryAction, tableColumnsToSubmit).then(result => {
                addAuditLog({
                  table_name: rightStubObj.table,
                  action_type: 'Create',
                  record_id: result.data.id,
                  action_description: `Created ${rightStubObj.table.toLowerCase()} with id: ${result.data.id}.`,
                  problem_id: result.data.problem_id,
                  job_id: result.data.job_id,
                  worker_id: result.data.worker_id,
                  user_id: cookies['userId']
                }).then(() => {
                  navigate(`/workers/${result.data.worker_id}/${workerSubsidiarySlugOrWorkerAction}/${result.data.job_id}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}/${result.data.problem_id}/${problemSubsidiarySlugOrJobSubsidiaryAction}/${result.data.id}`, {state: {warnings: result.data.warnings}});
                });
              }).catch(err => {
                setOutcome({
                  message: err.response.data.message,
                  type: 'Danger'
                });

                if (document.getElementById(err.response.data.input) !== null) {
                  document.getElementById(err.response.data.input).className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';
                }

                
              });
            } else {
              const emptyFields = result.emptyFields;

              emptyFields.forEach((emptyField) => {
                if (document.getElementById(emptyField)) {
                  document.getElementById(emptyField).className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';
                } else {
                  if (document.getElementsByClassName(`bg-white relative w-full border border-gray-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500 sm:text-sm ${emptyField}`)[0] !== undefined) {
                    document.getElementsByClassName(`bg-white relative w-full border border-gray-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500 sm:text-sm ${emptyField}`)[0].className = `bg-red-50 relative w-full border border-red-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500 sm:text-sm ${emptyField}`;
                  } else if (document.getElementsByClassName(`w-full border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm ${emptyField}`)[0] !== undefined) {
                    document.getElementsByClassName(`w-full border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm ${emptyField}`)[0].className = `bg-red-50 w-full border border-red-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-red-500 focus:outline-none focus:ring-1 focus:ring-red-500 sm:text-sm ${emptyField}`;
                  }
                }
              });

              setOutcome({
                message: 'Compulsory fields have not been filled.',
                type: 'Danger'
              });

              
            }
          } else if (buttonClicked === 'Abandon') {
            navigate(`/${tableSlug}/${workerId}/${workerSubsidiarySlugOrWorkerAction}/${workerSubsidiaryIdOrWorkerSubsidiaryAction}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}/${jobSubsidiaryIdOrJobSubsidiaryAction}/${problemSubsidiarySlugOrJobSubsidiaryAction}`);
          } else if (buttonClicked === 'Edit') {
            navigate(`/${tableSlug}/${workerId}/${workerSubsidiarySlugOrWorkerAction}/${workerSubsidiaryIdOrWorkerSubsidiaryAction}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}/${jobSubsidiaryIdOrJobSubsidiaryAction}/${problemSubsidiarySlugOrJobSubsidiaryAction}/${problemSubsidiaryIdOrProblemSubsidiaryAction}/edit`);
          } else if (buttonClicked === 'Delete') {
            setDeleteSingle(`${rightStubObj.table.toLowerCase()}`);
            setDeletePlural(`${rightStubObj.problemSubsidiarySlugOrJobSubsidiaryAction.replace(/-/g, ' ').toUpperCase()}`);
            setShowDeleteConfirmation(true);
          } else if (buttonClicked === 'Upload') {
            const result = checkCompulsoryFields(rightStubObj.tableColumns);
            
            if (result.allCompulsoryFieldsHaveBeenFilled) {
              const tableColumnsToSubmit = [...rightStubObj.tableColumns];
              tableColumnsToSubmit.push({
                token: cookies['token']
              });

              // let returnObj = await checkValidityOfWorkerJobProblem(tableColumnsToSubmit);
              // returnObj = returnObj.data;
              // if (returnObj && !returnObj.success) {
              //   setOutcome({
              //     message: returnObj.message,
              //     type: 'Danger'
              //   });

              //   return;
              // }

              if (fileIsAbove25M(tableColumnsToSubmit[0].value.size)) {
                setOutcome({
                  message: 'Please upload a file below 10MB.',
                  type: 'Danger'
                });
              } else {
                addObject(problemSubsidiarySlugOrJobSubsidiaryAction, tableColumnsToSubmit).then(result => {
                  if (problemSubsidiarySlugOrJobSubsidiaryAction === 'ordinary-attachments') {
                    if (result.data.job_id !== null && result.data.problem_id !== null) {
                      addAuditLog({
                        table_name: rightStubObj.table,
                        action_type: 'Create',
                        record_id: result.data.id,
                        action_description: `Created ${rightStubObj.table.toLowerCase()} with id: ${result.data.id}.`,
                        problem_id: result.data.problem_id,
                        job_id: result.data.job_id,
                        worker_id: result.data.worker_id,
                        user_id: cookies['userId']
                      }).then(() => {
                        navigate(`/workers/${result.data.worker_id}/jobs/${result.data.job_id}/problems/${result.data.problem_id}/${problemSubsidiarySlugOrJobSubsidiaryAction}/${result.data.id}`);
                      });
                    } else if (result.data.job_id !== null && result.data.problem_id === null) {
                      addAuditLog({
                        table_name: rightStubObj.table,
                        action_type: 'Create',
                        record_id: result.data.id,
                        action_description: `Created ${rightStubObj.table.toLowerCase()} with id: ${result.data.id}.`,
                        job_id: result.data.job_id,
                        worker_id: result.data.worker_id,
                        user_id: cookies['userId']
                      }).then(() => {
                        navigate(`/workers/${result.data.worker_id}/jobs/${result.data.job_id}/${problemSubsidiarySlugOrJobSubsidiaryAction}/${result.data.id}`);
                      });
                    } else {
                      addAuditLog({
                        table_name: rightStubObj.table,
                        action_type: 'Create',
                        record_id: result.data.id,
                        action_description: `Created ${rightStubObj.table.toLowerCase()} with id: ${result.data.id}.`,
                        worker_id: result.data.worker_id,
                        user_id: cookies['userId']
                      }).then(() => {
                        navigate(`/workers/${result.data.worker_id}/${problemSubsidiarySlugOrJobSubsidiaryAction}/${result.data.id}`);
                      });
                    }
                  } else if (problemSubsidiarySlugOrJobSubsidiaryAction === 'sensitive-attachments') {
                    if (result.data.job_id !== null && result.data.problem_id !== null) {
                      addAuditLog({
                        table_name: rightStubObj.table,
                        action_type: 'Create',
                        record_id: result.data.sensitiveAttachment.id,
                        action_description: `Created ${rightStubObj.table.toLowerCase()} with id: ${result.data.sensitiveAttachment.id}.`,
                        problem_id: result.data.problem_id,
                        job_id: result.data.job_id,
                        worker_id: result.data.worker_id,
                        user_id: cookies['userId']
                      }).then(() => {
                        navigate(`/workers/${result.data.worker_id}/jobs/${result.data.job_id}/problems/${result.data.problem_id}/${problemSubsidiarySlugOrJobSubsidiaryAction}/${result.data.sensitiveAttachment.id}`);
                      });
                    } else if (result.data.job_id !== null && result.data.problem_id === null) {
                      addAuditLog({
                        table_name: rightStubObj.table,
                        action_type: 'Create',
                        record_id: result.data.sensitiveAttachment.id,
                        action_description: `Created ${rightStubObj.table.toLowerCase()} with id: ${result.data.sensitiveAttachment.id}.`,
                        job_id: result.data.job_id,
                        worker_id: result.data.worker_id,
                        user_id: cookies['userId']
                      }).then(() => {
                        navigate(`/workers/${result.data.worker_id}/jobs/${result.data.job_id}/${problemSubsidiarySlugOrJobSubsidiaryAction}/${result.data.sensitiveAttachment.id}`);
                      });
                    } else {
                      addAuditLog({
                        table_name: rightStubObj.table,
                        action_type: 'Create',
                        record_id: result.data.sensitiveAttachment.id,
                        action_description: `Created ${rightStubObj.table.toLowerCase()} with id: ${result.data.sensitiveAttachment.id}.`,
                        worker_id: result.data.worker_id,
                        user_id: cookies['userId']
                      }).then(() => {
                        navigate(`/workers/${result.data.worker_id}/${problemSubsidiarySlugOrJobSubsidiaryAction}/${result.data.sensitiveAttachment.id}`);
                      });
                    }
                  } else {
                    addAuditLog({
                      table_name: rightStubObj.table,
                      action_type: 'Create',
                      record_id: result.data.id,
                      action_description: `Created ${rightStubObj.table.toLowerCase()} with id: ${result.data.id}.`,
                      problem_id: result.data.problem_id,
                      job_id: result.data.job_id,
                      worker_id: result.data.worker_id,
                      user_id: cookies['userId']
                    }).then(() => {
                      navigate(`/workers/${result.data.worker_id}/${workerSubsidiarySlugOrWorkerAction}/${result.data.job_id}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}/${result.data.problem_id}/${problemSubsidiarySlugOrJobSubsidiaryAction}/${result.data.id}`);
                    });
                  }
                }).catch(err => {
                  setOutcome({
                    message: err.response.data.message,
                    type: 'Danger'
                  });
  
                  if (document.getElementById(err.response.data.input) !== null) {
                    document.getElementById(err.response.data.input).className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';
                  }
  
                  
                });
              }
            } else {
              const emptyFields = result.emptyFields;

              emptyFields.forEach((emptyField) => {
                if (document.getElementById(emptyField)) {
                  document.getElementById(emptyField).className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';
                } else {
                  if (document.getElementsByClassName(`bg-white relative w-full border border-gray-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500 sm:text-sm ${emptyField}`)[0] !== undefined) {
                    document.getElementsByClassName(`bg-white relative w-full border border-gray-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500 sm:text-sm ${emptyField}`)[0].className = `bg-red-50 relative w-full border border-red-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500 sm:text-sm ${emptyField}`;
                  } else if (document.getElementsByClassName(`w-full border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm ${emptyField}`)[0] !== undefined) {
                    document.getElementsByClassName(`w-full border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm ${emptyField}`)[0].className = `bg-red-50 w-full border border-red-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-red-500 focus:outline-none focus:ring-1 focus:ring-red-500 sm:text-sm ${emptyField}`;
                  }
                }
              });

              setOutcome({
                message: 'Compulsory fields have not been filled.',
                type: 'Danger'
              });

              
            }
          } else if (buttonClicked === 'Go') {
            navigate(`/${tableSlug}/${workerId}/${workerSubsidiarySlugOrWorkerAction}/${workerSubsidiaryIdOrWorkerSubsidiaryAction}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}/${jobSubsidiaryIdOrJobSubsidiaryAction}/${problemSubsidiarySlugOrJobSubsidiaryAction}/`);
          }
        } else if (
          workerSubsidiarySlugOrWorkerAction !== undefined && 
          workerSubsidiaryIdOrWorkerSubsidiaryAction !== undefined && 
          jobSubsidiarySlugOrWorkerSubsidiaryAction !== undefined && 
          jobSubsidiaryIdOrJobSubsidiaryAction !== undefined &&
          problemSubsidiarySlugOrJobSubsidiaryAction !== undefined &&
          problemSubsidiaryIdOrProblemSubsidiaryAction !== undefined &&
          problemSubsidiaryAction !== undefined
        ) {
          if (buttonClicked === 'Save') {
            const result = checkCompulsoryFields(rightStubObj.tableColumns);
            if (result.allCompulsoryFieldsHaveBeenFilled) {
              let tableColumnsToSubmit = [...rightStubObj.tableColumns];
              tableColumnsToSubmit.push({
                token: cookies['token']
              });

              let updatedWorkerId;
              let updatedJobId;
              let updatedProblemId;

              for (let i = 0; i < tableColumnsToSubmit.length; i += 1) {
                if (tableColumnsToSubmit[i]['column_name'] === 'worker_id') {
                  updatedWorkerId = tableColumnsToSubmit[i]['value'];
                }
                if (tableColumnsToSubmit[i]['column_name'] === 'job_id') {
                  updatedJobId = tableColumnsToSubmit[i]['value'];
                }
                if (tableColumnsToSubmit[i]['column_name'] === 'problem_id') {
                  updatedProblemId = tableColumnsToSubmit[i]['value'];
                }
                if (tableColumnsToSubmit[i]['column_name'] === 'tagged_workers') {
                  // tableColumnsToSubmit = tableColumnsToSubmit.slice(0, i).concat(tableColumnsToSubmit.slice(i+1));
                }
              }

              for (let i = 0; i < tableColumnsToSubmit.length; i += 1) {
                if (tableColumnsToSubmit[i]['column_name'] === 'employer_name' && updatedJobId !== undefined) {
                  updatedJobId = tableColumnsToSubmit[i]['job_id'];
                }
                if (tableColumnsToSubmit[i]['column_name'] === 'chief_problem' && updatedProblemId !== undefined) {
                  updatedProblemId = tableColumnsToSubmit[i]['problem_id'];
                }
              }

              // let returnObj = await checkValidityOfWorkerJobProblem(tableColumnsToSubmit);
              // returnObj = returnObj.data;
              // if (returnObj && !returnObj.success) {
              //   setOutcome({
              //     message: returnObj.message,
              //     type: 'Danger'
              //   });

              //   return;
              // }

              getObject('workers', updatedWorkerId).then(result => {
                if (updatedJobId !== null && updatedJobId !== undefined && updatedJobId !== "") {
                  getJobByJobIdAndWorkerId(updatedJobId, updatedWorkerId).then(result => {
                    if (updatedProblemId !== null && updatedProblemId !== undefined && updatedProblemId !== "") {
                      getProblemByProblemIdAndJobIdAndWorkerId(updatedProblemId, updatedJobId, updatedWorkerId).then(result => {
                        editObject(problemSubsidiarySlugOrJobSubsidiaryAction, tableColumnsToSubmit, problemSubsidiaryIdOrProblemSubsidiaryAction).then(result2 => {
                          if (problemSubsidiarySlugOrJobSubsidiaryAction === 'ordinary-attachments') {
                            if (result2.data.job_id !== null && result2.data.problem_id !== null) {
                              addAuditLog({
                                table_name: rightStubObj.table,
                                action_type: 'Update',
                                record_id: problemSubsidiaryIdOrProblemSubsidiaryAction,
                                action_description: `Updated ${rightStubObj.table.toLowerCase()} with id: ${problemSubsidiaryIdOrProblemSubsidiaryAction}.`,
                                problem_id: result2.data.problem_id,
                                job_id: result2.data.job_id,
                                worker_id: updatedWorkerId,
                                user_id: cookies['userId']
                              }).then(() => {
                                navigate(`/${tableSlug}/${updatedWorkerId}/jobs/${result2.data.job_id}/problems/${result2.data.problem_id}/${problemSubsidiarySlugOrJobSubsidiaryAction}/${problemSubsidiaryIdOrProblemSubsidiaryAction}`);
                              });
                            } else if (result2.data.job_id !== null && result2.data.problem_id === null) {
                              addAuditLog({
                                table_name: rightStubObj.table,
                                action_type: 'Update',
                                record_id: problemSubsidiaryIdOrProblemSubsidiaryAction,
                                action_description: `Updated ${rightStubObj.table.toLowerCase()} with id: ${problemSubsidiaryIdOrProblemSubsidiaryAction}.`,
                                job_id: result2.data.job_id,
                                worker_id: updatedWorkerId,
                                user_id: cookies['userId']
                              }).then(() => {
                                navigate(`/${tableSlug}/${updatedWorkerId}/jobs/${result2.data.job_id}/${problemSubsidiarySlugOrJobSubsidiaryAction}/${problemSubsidiaryIdOrProblemSubsidiaryAction}`);
                              });
                            } else {
                              addAuditLog({
                                table_name: rightStubObj.table,
                                action_type: 'Update',
                                record_id: problemSubsidiaryIdOrProblemSubsidiaryAction,
                                action_description: `Updated ${rightStubObj.table.toLowerCase()} with id: ${problemSubsidiaryIdOrProblemSubsidiaryAction}.`,
                                worker_id: updatedWorkerId,
                                user_id: cookies['userId']
                              }).then(() => {
                                navigate(`/${tableSlug}/${updatedWorkerId}/${problemSubsidiarySlugOrJobSubsidiaryAction}/${problemSubsidiaryIdOrProblemSubsidiaryAction}`);
                              });
                            }
                          } else if (problemSubsidiarySlugOrJobSubsidiaryAction === 'sensitive-attachments') {
                            if (result2.data.job_id !== null && result2.data.problem_id !== null) {
                              addAuditLog({
                                table_name: rightStubObj.table,
                                action_type: 'Update',
                                record_id: problemSubsidiaryIdOrProblemSubsidiaryAction,
                                action_description: `Updated ${rightStubObj.table.toLowerCase()} with id: ${problemSubsidiaryIdOrProblemSubsidiaryAction}.`,
                                problem_id: result2.data.problem_id,
                                job_id: result2.data.job_id,
                                worker_id: updatedWorkerId,
                                user_id: cookies['userId']
                              }).then(() => {
                                navigate(`/${tableSlug}/${updatedWorkerId}/jobs/${result2.data.job_id}/problems/${result2.data.problem_id}/${problemSubsidiarySlugOrJobSubsidiaryAction}/${problemSubsidiaryIdOrProblemSubsidiaryAction}`);
                              });
                            } else if (result2.data.job_id !== null && result2.data.problem_id === null) {
                              addAuditLog({
                                table_name: rightStubObj.table,
                                action_type: 'Update',
                                record_id: problemSubsidiaryIdOrProblemSubsidiaryAction,
                                action_description: `Updated ${rightStubObj.table.toLowerCase()} with id: ${problemSubsidiaryIdOrProblemSubsidiaryAction}.`,
                                job_id: result2.data.job_id,
                                worker_id: updatedWorkerId,
                                user_id: cookies['userId']
                              }).then(() => {
                                navigate(`/${tableSlug}/${updatedWorkerId}/jobs/${result2.data.job_id}/${problemSubsidiarySlugOrJobSubsidiaryAction}/${problemSubsidiaryIdOrProblemSubsidiaryAction}`);
                              });
                            } else {
                              addAuditLog({
                                table_name: rightStubObj.table,
                                action_type: 'Update',
                                record_id: problemSubsidiaryIdOrProblemSubsidiaryAction,
                                action_description: `Updated ${rightStubObj.table.toLowerCase()} with id: ${problemSubsidiaryIdOrProblemSubsidiaryAction}.`,
                                worker_id: updatedWorkerId,
                                user_id: cookies['userId']
                              }).then(() => {
                                navigate(`/${tableSlug}/${updatedWorkerId}/${problemSubsidiarySlugOrJobSubsidiaryAction}/${problemSubsidiaryIdOrProblemSubsidiaryAction}`);
                              });
                            }
                          } else {
                            addAuditLog({
                              table_name: rightStubObj.table,
                              action_type: 'Update',
                              record_id: problemSubsidiaryIdOrProblemSubsidiaryAction,
                              action_description: `Updated ${rightStubObj.table.toLowerCase()} with id: ${problemSubsidiaryIdOrProblemSubsidiaryAction}.`,
                              problem_id: result2.data.problem_id,
                              job_id: result2.data.job_id,
                              worker_id: result2.data.worker_id,
                              user_id: cookies['userId']
                            }).then(() => {
                              navigate(`/${tableSlug}/${result2.data.worker_id}/${workerSubsidiarySlugOrWorkerAction}/${result2.data.job_id}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}/${result2.data.problem_id}/${problemSubsidiarySlugOrJobSubsidiaryAction}/${problemSubsidiaryIdOrProblemSubsidiaryAction}`);
                            });
                          }
                        }).catch(err => {
                          setOutcome({
                            message: err.response.data.message,
                            type: 'Danger'
                          });
            
                        if (document.getElementById(err.response.data.input) !== null) {
                          document.getElementById(err.response.data.input).className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';
                        }
                        });
                      }).catch(err => {
                        setOutcome({
                          message: err.response.data.message,
                          type: 'Danger'
                        });
    
                        if (document.getElementById(err.response.data.input) !== null) {
                          document.getElementById(err.response.data.input).className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';
                        }
    
                        
                      });
                    } else {
                      getJobByJobIdAndWorkerId(updatedJobId, updatedWorkerId).then(result => {
                        editObject(problemSubsidiarySlugOrJobSubsidiaryAction, tableColumnsToSubmit, problemSubsidiaryIdOrProblemSubsidiaryAction).then(result2 => {
                          if (problemSubsidiarySlugOrJobSubsidiaryAction === 'ordinary-attachments') {
                            if (result2.data.job_id !== null && result2.data.problem_id !== null) {
                              addAuditLog({
                                table_name: rightStubObj.table,
                                action_type: 'Update',
                                record_id: problemSubsidiaryIdOrProblemSubsidiaryAction,
                                action_description: `Updated ${rightStubObj.table.toLowerCase()} with id: ${problemSubsidiaryIdOrProblemSubsidiaryAction}.`,
                                problem_id: result2.data.problem_id,
                                job_id: result2.data.job_id,
                                worker_id: updatedWorkerId,
                                user_id: cookies['userId']
                              }).then(() => {
                                navigate(`/${tableSlug}/${updatedWorkerId}/jobs/${result2.data.job_id}/problems/${result2.data.problem_id}/${problemSubsidiarySlugOrJobSubsidiaryAction}/${problemSubsidiaryIdOrProblemSubsidiaryAction}`);
                              });
                            } else if (result2.data.job_id !== null && result2.data.problem_id === null) {
                              addAuditLog({
                                table_name: rightStubObj.table,
                                action_type: 'Update',
                                record_id: problemSubsidiaryIdOrProblemSubsidiaryAction,
                                action_description: `Updated ${rightStubObj.table.toLowerCase()} with id: ${problemSubsidiaryIdOrProblemSubsidiaryAction}.`,
                                job_id: result2.data.job_id,
                                worker_id: updatedWorkerId,
                                user_id: cookies['userId']
                              }).then(() => {
                                navigate(`/${tableSlug}/${updatedWorkerId}/jobs/${result2.data.job_id}/${problemSubsidiarySlugOrJobSubsidiaryAction}/${problemSubsidiaryIdOrProblemSubsidiaryAction}`);
                              });
                            } else {
                              addAuditLog({
                                table_name: rightStubObj.table,
                                action_type: 'Update',
                                record_id: problemSubsidiaryIdOrProblemSubsidiaryAction,
                                action_description: `Updated ${rightStubObj.table.toLowerCase()} with id: ${problemSubsidiaryIdOrProblemSubsidiaryAction}.`,
                                worker_id: updatedWorkerId,
                                user_id: cookies['userId']
                              }).then(() => {
                                navigate(`/${tableSlug}/${updatedWorkerId}/${problemSubsidiarySlugOrJobSubsidiaryAction}/${problemSubsidiaryIdOrProblemSubsidiaryAction}`);
                              });
                            }
                          } else if (problemSubsidiarySlugOrJobSubsidiaryAction === 'sensitive-attachments') {
                            if (result2.data.job_id !== null && result2.data.problem_id !== null) {
                              addAuditLog({
                                table_name: rightStubObj.table,
                                action_type: 'Update',
                                record_id: problemSubsidiaryIdOrProblemSubsidiaryAction,
                                action_description: `Updated ${rightStubObj.table.toLowerCase()} with id: ${problemSubsidiaryIdOrProblemSubsidiaryAction}.`,
                                problem_id: result2.data.problem_id,
                                job_id: result2.data.job_id,
                                worker_id: updatedWorkerId,
                                user_id: cookies['userId']
                              }).then(() => {
                                navigate(`/${tableSlug}/${updatedWorkerId}/jobs/${result2.data.job_id}/problems/${result2.data.problem_id}/${problemSubsidiarySlugOrJobSubsidiaryAction}/${problemSubsidiaryIdOrProblemSubsidiaryAction}`);
                              });
                            } else if (result2.data.job_id !== null && result2.data.problem_id === null) {
                              addAuditLog({
                                table_name: rightStubObj.table,
                                action_type: 'Update',
                                record_id: problemSubsidiaryIdOrProblemSubsidiaryAction,
                                action_description: `Updated ${rightStubObj.table.toLowerCase()} with id: ${problemSubsidiaryIdOrProblemSubsidiaryAction}.`,
                                job_id: result2.data.job_id,
                                worker_id: updatedWorkerId,
                                user_id: cookies['userId']
                              }).then(() => {
                                navigate(`/${tableSlug}/${updatedWorkerId}/jobs/${result2.data.job_id}/${problemSubsidiarySlugOrJobSubsidiaryAction}/${problemSubsidiaryIdOrProblemSubsidiaryAction}`);
                              });
                            } else {
                              addAuditLog({
                                table_name: rightStubObj.table,
                                action_type: 'Update',
                                record_id: problemSubsidiaryIdOrProblemSubsidiaryAction,
                                action_description: `Updated ${rightStubObj.table.toLowerCase()} with id: ${problemSubsidiaryIdOrProblemSubsidiaryAction}.`,
                                worker_id: updatedWorkerId,
                                user_id: cookies['userId']
                              }).then(() => {
                                navigate(`/${tableSlug}/${updatedWorkerId}/${problemSubsidiarySlugOrJobSubsidiaryAction}/${problemSubsidiaryIdOrProblemSubsidiaryAction}`);
                              });
                            }
                          } else {
                            addAuditLog({
                              table_name: rightStubObj.table,
                              action_type: 'Update',
                              record_id: problemSubsidiaryIdOrProblemSubsidiaryAction,
                              action_description: `Updated ${rightStubObj.table.toLowerCase()} with id: ${problemSubsidiaryIdOrProblemSubsidiaryAction}.`,
                              problem_id: jobSubsidiaryIdOrJobSubsidiaryAction,
                              job_id: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                              worker_id: updatedWorkerId,
                              user_id: cookies['userId']
                            }).then(() => {
                              navigate(`/${tableSlug}/${updatedWorkerId}/${workerSubsidiarySlugOrWorkerAction}/${workerSubsidiaryIdOrWorkerSubsidiaryAction}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}/${jobSubsidiaryIdOrJobSubsidiaryAction}/${problemSubsidiarySlugOrJobSubsidiaryAction}/${problemSubsidiaryIdOrProblemSubsidiaryAction}`);
                            });
                          }
                        }).catch(err => {
                          setOutcome({
                            message: err.response.data.message,
                            type: 'Danger'
                          });
            
                        if (document.getElementById(err.response.data.input) !== null) {
                          document.getElementById(err.response.data.input).className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';
                        }
                        });
                      }).catch(err => {
                        setOutcome({
                          message: err.response.data.message,
                          type: 'Danger'
                        });
  
                        if (document.getElementById(err.response.data.input) !== null) {
                          document.getElementById(err.response.data.input).className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';
                        }
  
                        
                      });
                    }
                  }).catch(err => {
                    setOutcome({
                      message: err.response.data.message,
                      type: 'Danger'
                    });
  
                    if (document.getElementById(err.response.data.input) !== null) {
                      document.getElementById(err.response.data.input).className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';
                    }
  
                    
                  });
                } else {
                  editObject(problemSubsidiarySlugOrJobSubsidiaryAction, tableColumnsToSubmit, problemSubsidiaryIdOrProblemSubsidiaryAction).then(result2 => {
                    if (problemSubsidiarySlugOrJobSubsidiaryAction === 'ordinary-attachments') {
                      if (result2.data.job_id !== null && result2.data.problem_id !== null) {
                        addAuditLog({
                          table_name: rightStubObj.table,
                          action_type: 'Update',
                          record_id: problemSubsidiaryIdOrProblemSubsidiaryAction,
                          action_description: `Updated ${rightStubObj.table.toLowerCase()} with id: ${problemSubsidiaryIdOrProblemSubsidiaryAction}.`,
                          problem_id: result2.data.problem_id,
                          job_id: result2.data.job_id,
                          worker_id: updatedWorkerId,
                          user_id: cookies['userId']
                        }).then(() => {
                          navigate(`/${tableSlug}/${updatedWorkerId}/jobs/${result2.data.job_id}/problems/${result2.data.problem_id}/${problemSubsidiarySlugOrJobSubsidiaryAction}/${problemSubsidiaryIdOrProblemSubsidiaryAction}`);
                        });
                      } else if (result2.data.job_id !== null && result2.data.problem_id === null) {
                        addAuditLog({
                          table_name: rightStubObj.table,
                          action_type: 'Update',
                          record_id: problemSubsidiaryIdOrProblemSubsidiaryAction,
                          action_description: `Updated ${rightStubObj.table.toLowerCase()} with id: ${problemSubsidiaryIdOrProblemSubsidiaryAction}.`,
                          job_id: result2.data.job_id,
                          worker_id: updatedWorkerId,
                          user_id: cookies['userId']
                        }).then(() => {
                          navigate(`/${tableSlug}/${updatedWorkerId}/jobs/${result2.data.job_id}/${problemSubsidiarySlugOrJobSubsidiaryAction}/${problemSubsidiaryIdOrProblemSubsidiaryAction}`);
                        });
                      } else {
                        addAuditLog({
                          table_name: rightStubObj.table,
                          action_type: 'Update',
                          record_id: problemSubsidiaryIdOrProblemSubsidiaryAction,
                          action_description: `Updated ${rightStubObj.table.toLowerCase()} with id: ${problemSubsidiaryIdOrProblemSubsidiaryAction}.`,
                          worker_id: updatedWorkerId,
                          user_id: cookies['userId']
                        }).then(() => {
                          navigate(`/${tableSlug}/${updatedWorkerId}/${problemSubsidiarySlugOrJobSubsidiaryAction}/${problemSubsidiaryIdOrProblemSubsidiaryAction}`);
                        });
                      }
                    } else if (problemSubsidiarySlugOrJobSubsidiaryAction === 'sensitive-attachments') {
                      if (result2.data.job_id !== null && result2.data.problem_id !== null) {
                        addAuditLog({
                          table_name: rightStubObj.table,
                          action_type: 'Update',
                          record_id: problemSubsidiaryIdOrProblemSubsidiaryAction,
                          action_description: `Updated ${rightStubObj.table.toLowerCase()} with id: ${problemSubsidiaryIdOrProblemSubsidiaryAction}.`,
                          problem_id: result2.data.problem_id,
                          job_id: result2.data.job_id,
                          worker_id: updatedWorkerId,
                          user_id: cookies['userId']
                        }).then(() => {
                          navigate(`/${tableSlug}/${updatedWorkerId}/jobs/${result2.data.job_id}/problems/${result2.data.problem_id}/${problemSubsidiarySlugOrJobSubsidiaryAction}/${problemSubsidiaryIdOrProblemSubsidiaryAction}`);
                        });
                      } else if (result2.data.job_id !== null && result2.data.problem_id === null) {
                        addAuditLog({
                          table_name: rightStubObj.table,
                          action_type: 'Update',
                          record_id: problemSubsidiaryIdOrProblemSubsidiaryAction,
                          action_description: `Updated ${rightStubObj.table.toLowerCase()} with id: ${problemSubsidiaryIdOrProblemSubsidiaryAction}.`,
                          job_id: result2.data.job_id,
                          worker_id: updatedWorkerId,
                          user_id: cookies['userId']
                        }).then(() => {
                          navigate(`/${tableSlug}/${updatedWorkerId}/jobs/${result2.data.job_id}/${problemSubsidiarySlugOrJobSubsidiaryAction}/${problemSubsidiaryIdOrProblemSubsidiaryAction}`);
                        });
                      } else {
                        addAuditLog({
                          table_name: rightStubObj.table,
                          action_type: 'Update',
                          record_id: problemSubsidiaryIdOrProblemSubsidiaryAction,
                          action_description: `Updated ${rightStubObj.table.toLowerCase()} with id: ${problemSubsidiaryIdOrProblemSubsidiaryAction}.`,
                          worker_id: updatedWorkerId,
                          user_id: cookies['userId']
                        }).then(() => {
                          navigate(`/${tableSlug}/${updatedWorkerId}/${problemSubsidiarySlugOrJobSubsidiaryAction}/${problemSubsidiaryIdOrProblemSubsidiaryAction}`);
                        });
                      }
                    } else {
                      if (workerSubsidiarySlugOrWorkerAction === 'jobs') {
                        addAuditLog({
                          table_name: rightStubObj.table,
                          action_type: 'Update',
                          record_id: problemSubsidiaryIdOrProblemSubsidiaryAction,
                          action_description: `Updated ${rightStubObj.table.toLowerCase()} with id: ${problemSubsidiaryIdOrProblemSubsidiaryAction}.`,
                          job_id: problemSubsidiaryIdOrProblemSubsidiaryAction,
                          worker_id: updatedWorkerId,
                          user_id: cookies['userId']
                        }).then(() => {
                          navigate(`/${tableSlug}/${updatedWorkerId}/${workerSubsidiarySlugOrWorkerAction}/${problemSubsidiaryIdOrProblemSubsidiaryAction}`);
                        });
                      } else {
                        addAuditLog({
                          table_name: rightStubObj.table,
                          action_type: 'Update',
                          record_id: problemSubsidiaryIdOrProblemSubsidiaryAction,
                          action_description: `Updated ${rightStubObj.table.toLowerCase()} with id: ${problemSubsidiaryIdOrProblemSubsidiaryAction}.`,
                          worker_id: updatedWorkerId,
                          user_id: cookies['userId']
                        }).then(() => {
                          navigate(`/${tableSlug}/${updatedWorkerId}/${workerSubsidiarySlugOrWorkerAction}/${problemSubsidiaryIdOrProblemSubsidiaryAction}`);
                        });
                      }
                    }
                  }).catch(err => {
                    setOutcome({
                      message: err.response.data.message,
                      type: 'Danger'
                    });

                  if (document.getElementById(err.response.data.input) !== null) {
                    document.getElementById(err.response.data.input).className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';
                  }

                    
                  });
                }
              }).catch(err => {
                setOutcome({
                  message: err.response.data.message,
                  type: 'Danger'
                });

                if (document.getElementById(err.response.data.input) !== null) {
                  document.getElementById(err.response.data.input).className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';
                }

                
              });
            } else {
              const emptyFields = result.emptyFields;

              emptyFields.forEach((emptyField) => {
                if (document.getElementById(emptyField)) {
                  document.getElementById(emptyField).className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';
                } else {
                  if (document.getElementsByClassName(`bg-white relative w-full border border-gray-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500 sm:text-sm ${emptyField}`)[0] !== undefined) {
                    document.getElementsByClassName(`bg-white relative w-full border border-gray-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500 sm:text-sm ${emptyField}`)[0].className = `bg-red-50 relative w-full border border-red-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500 sm:text-sm ${emptyField}`;
                  } else if (document.getElementsByClassName(`w-full border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm ${emptyField}`)[0] !== undefined) {
                    document.getElementsByClassName(`w-full border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm ${emptyField}`)[0].className = `bg-red-50 w-full border border-red-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-red-500 focus:outline-none focus:ring-1 focus:ring-red-500 sm:text-sm ${emptyField}`;
                  }
                }
              });

              setOutcome({
                message: 'Compulsory fields have not been filled.',
                type: 'Danger'
              });

              
            }
          } else if (buttonClicked === 'Abandon') {
            navigate(`/${tableSlug}/${workerId}/${workerSubsidiarySlugOrWorkerAction}/${workerSubsidiaryIdOrWorkerSubsidiaryAction}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}/${jobSubsidiaryIdOrJobSubsidiaryAction}/${problemSubsidiarySlugOrJobSubsidiaryAction}/${problemSubsidiaryIdOrProblemSubsidiaryAction}`);
          }
        }
      }).catch((error) => {
        if (error.response) {
          if (error.response.data) {
            if (error.response.data.message === 'User forbidden.') {
              addAuditLog({
                table_name: 'Auth',
                action_type: 'Kicked out',
                action_description: `Check deactivated - workers/view.jsx - ${error}`,
                user_id: cookies['userId']
              }).then(result => {
                removeCookie('token');
                window.location.replace("/")
              })
            }
          }
        }
      });
      checkSystemDown()
      .then((result) => {
      }).catch((error) => {
        if (cookies['role'] !== 'Admin') {
          addAuditLog({
            table_name: 'Auth',
            action_type: 'Kicked out',
            action_description: `Check system down - workers/view.jsx - ${error}`,
            user_id: cookies['userId']
          }).then(result => {
          removeCookie('token');
          window.location.replace("/")
          });
        }
      });
    } else {
      Object.keys(cookies).forEach(cookie => {
        removeCookie(cookie);
      });
      navigate('/login');
    }
  };

  const transformPrivileges = (privilegesResult, action) => {
    const resultPrivileges = privilegesResult;
    const privilegesToUpdate = {};
    resultPrivileges.forEach((privilege) => {
      if (privilegesToUpdate[privilege['mode']] === undefined) {
        privilegesToUpdate[privilege['mode']] = {};
      }

      privilegesToUpdate[privilege['mode']][privilege['tableAndColumn']['column_name']] = privilege['privilege'];
    });

    // if user is not authorised to view this page, redirect back to home page
    if (privilegesToUpdate[action]['id'] === 'No') {
      navigate('/');
    }

    return privilegesToUpdate;
  };

  const deleteButton = (rightStubObj, column, event) => {
    const rightStubObjToUpdate = {...rightStubObj};
    const tableColumnsToUpdate = [...rightStubObj.tableColumns];
    tableColumnsToUpdate[column['sequence'] - 1].value = '';
    
    rightStubObjToUpdate.tableColumns = tableColumnsToUpdate;
    setRightStubObj(rightStubObjToUpdate);
    setLoading(false);
  };

  const handleComboBoxChange = (rightStubObj, column, event, referencedColumnName, referencedTableSlug, storeAsColumnName, value) => {
    const rightStubObjToUpdate = {...rightStubObj};
    const tableColumnsToUpdate = [...rightStubObj.tableColumns];

    const columnNameToStoreAs = storeAsColumnName !== null ? storeAsColumnName : '';
    const columnNameToSearch = referencedColumnName !== null ? referencedColumnName : '';
    const columnValueToSearch = event !== null ? event[Object.keys(event)[0]] : '';
    
    if (rightStubObj.table === 'Ordinary Case Discussion' || rightStubObj.table === 'Hearings Progress' || rightStubObj.table === 'Sensitive Discussion' || rightStubObj.table === 'Sensitive Attachment') {
      if (value !== undefined) {
        if (referencedColumnName === 'tagged_workers') {
          for (let i = 0; i < tableColumnsToUpdate.length; i += 1) {
            if (tableColumnsToUpdate[i]['column_name'] === 'tagged_workers') {
              delete(tableColumnsToUpdate[i]['value'][value]);
              break;
            }
          }
        } else if (referencedColumnName === 'tagged_users') {
          for (let i = 0; i < tableColumnsToUpdate.length; i += 1) {
            if (tableColumnsToUpdate[i]['column_name'] === 'tagged_users') {
              delete(tableColumnsToUpdate[i]['value'][value]);
              break;
            }
          }
        }
        
      } else {
        if (referencedColumnName === 'name_of_worker') {
          setTaggedWorker(event.name_of_worker.slice(0,10));
        } else if (referencedColumnName === 'user_username') {
          for (let i = 0; i < tableColumnsToUpdate.length; i += 1) {
            if (tableColumnsToUpdate[i]['column_name'] === 'tagged_users') {
              tableColumnsToUpdate[i]['value'][event.user_username] = true;
              break;
            }
          }
        } 
      }
      
      rightStubObjToUpdate.tableColumns = tableColumnsToUpdate;
      setRightStubObj(rightStubObjToUpdate);
      setLoading(false);
    } else {
      tableColumnsToUpdate[column['sequence'] - 1].value = event[Object.keys(event)[0]];
      tableColumnsToUpdate[column['sequence'] - 1].referencedColumnName = referencedColumnName;
      tableColumnsToUpdate[column['sequence'] - 1].storeAsColumnName = storeAsColumnName;
      tableColumnsToUpdate[column['sequence'] - 1].referencedTableSlug = referencedTableSlug;

      getValueByColumnName(referencedTableSlug, columnNameToStoreAs, columnNameToSearch, columnValueToSearch).then(value => {
        tableColumnsToUpdate[column['sequence'] - 1].storeAsColumnValue = value.data[storeAsColumnName];
      });

      rightStubObjToUpdate.tableColumns = tableColumnsToUpdate;
      setRightStubObj(rightStubObjToUpdate);
      setLoading(false);
    }
  };

  const findJobsAndProblemsByWorkerId = (workerId, rightStubObj2) => {
    const tableColumnsToUpdate = [...rightStubObj2.tableColumns];
    const jobIdsToProblems = {};

    getJobAndProblemByWorkerId(workerId).then(jobAndProblems => {
      if (jobAndProblems.data.id) {
        jobAndProblems.data.problems.forEach((problem) => {
          if (jobIdsToProblems[problem.job_id] === undefined) {
            jobIdsToProblems[problem.job_id] = [];
          }
  
          jobIdsToProblems[problem.job_id].push(problem);
        });
      }

      for (let i = 0; i < tableColumnsToUpdate.length; i += 1) {
        if (tableColumnsToUpdate[i]['column_name'] === 'employer_name') {
          tableColumnsToUpdate[i]['dropdowns'] = [{dropdown_item: '-'}];
          jobAndProblems.data.jobs.forEach(job => {
            tableColumnsToUpdate[i]['dropdowns'].push({id: job.id, dropdown_item: job.employer_name});
          });
          tableColumnsToUpdate[i]['value'] = "";
          tableColumnsToUpdate[i]['job_id'] = "";
        } else if (tableColumnsToUpdate[i]['column_name'] === 'chief_problem') {
          tableColumnsToUpdate[i]['dropdowns'] = [{dropdown_item: '-'}];
          tableColumnsToUpdate[i]['value'] = "";
          tableColumnsToUpdate[i]['problem_id'] = "";
        } else if (tableColumnsToUpdate[i]['column_name'] === 'job_id') {
          tableColumnsToUpdate[i]['value'] = null;
        } else if (tableColumnsToUpdate[i]['column_name'] === 'problem_id') {
          tableColumnsToUpdate[i]['value'] = null;
        }
      }
      
      rightStubObj2.tableColumns = tableColumnsToUpdate;
      rightStubObj2.jobIdsToProblems = jobIdsToProblems;
      setRightStubObj(rightStubObj2);
      setLoading(false);
    }).catch(err => {
      for (let i = 0; i < tableColumnsToUpdate.length; i += 1) {
        if (tableColumnsToUpdate[i]['column_name'] === 'employer_name') {
          tableColumnsToUpdate[i]['dropdowns'] = [{dropdown_item: '-'}];
          tableColumnsToUpdate[i]['value'] = "";
          tableColumnsToUpdate[i]['job_id'] = "";
        } else if (tableColumnsToUpdate[i]['column_name'] === 'chief_problem') {
          tableColumnsToUpdate[i]['dropdowns'] = [{dropdown_item: '-'}];
          tableColumnsToUpdate[i]['value'] = "";
          tableColumnsToUpdate[i]['problem_id'] = "";
        } else if (tableColumnsToUpdate[i]['column_name'] === 'job_id') {
          tableColumnsToUpdate[i]['value'] = null;
        } else if (tableColumnsToUpdate[i]['column_name'] === 'problem_id') {
          tableColumnsToUpdate[i]['value'] = null;
        }
      }
      
      rightStubObj2.tableColumns = tableColumnsToUpdate;
      rightStubObj2.jobIdsToProblems = jobIdsToProblems;
      setRightStubObj(rightStubObj2);
      setLoading(false);
    })
  };

  const handleChange = (rightStubObj, column, event, updateAttachmentName) => {
    if (updateAttachmentName !== undefined) {
      const rightStubObjToUpdate = {...rightStubObj};
      const tableColumnsToUpdate = [...rightStubObj.tableColumns];
      for (let i = 0; i < tableColumnsToUpdate.length; i += 1) {
        if (tableColumnsToUpdate[i].column_name === 'attachment_name') {
          tableColumnsToUpdate[i].value = updateAttachmentName;
          break;
        }
      }

      setRightStubObj(rightStubObjToUpdate);
      setLoading(false);
    } else {
      const rightStubObjToUpdate = {...rightStubObj};
      const tableColumnsToUpdate = [...rightStubObj.tableColumns];
      const selectedDropdownToUpdate = [...selectedDropdown];
    
      if (event.target !== undefined && event.target.type !== 'checkbox') {
        event.target.className = 'shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300';
      }

      // if checkbox, handle differently
      if (column['input_type'] === 'checkbox') {
        tableColumnsToUpdate[column['sequence'] - 1]['value'] = event.target.checked;
        
        if (column.column_name === "problem_clan_checkbox") {
          for (let i = 0; i < tableColumnsToUpdate.length; i += 1) {
            if (tableColumnsToUpdate[i]['column_name'] === 'tagged_workers') {
              if (event.target.checked) {
                tableColumnsToUpdate[i]['value'] = {...rightStubObj.clanWorkers};
              } else {
                tableColumnsToUpdate[i]['value'] = {};
              }
              break;
            }
          }
        }

      } else if (column['input_type'] === 'float') {
        if (event.target.value.indexOf('.') !== -1 && (event.target.value.split('.')[1].length > tableColumnsToUpdate[column['sequence'] - 1]['float_step'].split('.')[1].length || (event.target.value.match(/\./g) || []).length > 1)) {
          
        } else {
          tableColumnsToUpdate[column['sequence'] - 1]['value'] = event.target.value;
        }
      } else if (column['column_name'] === 'tableAndColumn_id') {
        const filteredSelectedDropdownToUpdate = selectedDropdownToUpdate.map((selectedDropdownToUpdateRow) => {
          return selectedDropdownToUpdateRow.column_name
        });

        if (filteredSelectedDropdownToUpdate.indexOf(event.column_name) === -1) {
          tableColumnsToUpdate[column['sequence'] - 1]['value'].push(event);
          selectedDropdownToUpdate.push(event);
        }

        document.getElementsByClassName('w-full border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm')[0].value = '';
      } else {
        tableColumnsToUpdate[column['sequence'] - 1]['value'] = event.target.value;
      }

      if ((rightStubObj.table === 'Ordinary Attachment' || rightStubObj.table === 'Sensitive Attachment') && (column['column_name'] === 'worker_id')) {
        findJobsAndProblemsByWorkerId(tableColumnsToUpdate[column['sequence'] - 1]['value'], rightStubObjToUpdate);
      } else {
        rightStubObjToUpdate.tableColumns = tableColumnsToUpdate;
        setRightStubObj(rightStubObjToUpdate);
        setLoading(false);
        setSelectedDropdown(selectedDropdownToUpdate);
      }
    }
  };

  const handleCalendarChange = (rightStubObj, column, event) => {
    const rightStubObjToUpdate = {...rightStubObj};
    const tableColumnsToUpdate = [...rightStubObj.tableColumns];
    tableColumnsToUpdate[column['sequence'] - 1].value = event;

    rightStubObjToUpdate.tableColumns = tableColumnsToUpdate;
    setRightStubObj(rightStubObjToUpdate);
    setLoading(false);
  };

  const handleDropdownChange = (rightStubObj, tableColumn, column, event) => {
    if (document.getElementsByClassName(`bg-red-50 relative w-full border border-red-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500 sm:text-sm ${column}`)[0] !== undefined) {
      document.getElementsByClassName(`bg-red-50 relative w-full border border-red-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500 sm:text-sm ${column}`)[0].className = `bg-white relative w-full border border-gray-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500 sm:text-sm ${column}`;
    }
    const rightStubObjToUpdate = {...rightStubObj};
    const tableColumnsToUpdate = [...rightStubObj.tableColumns];
    if (event.dropdown_item === '-') {
      tableColumnsToUpdate[tableColumn.sequence - 1].value = '';
      if (column === 'chief_problem') {
        tableColumnsToUpdate[tableColumn.sequence - 1].problem_id = '';
      }
    } else {
      tableColumnsToUpdate[tableColumn.sequence - 1].value = event.dropdown_item;
      if (column === 'chief_problem') {
        tableColumnsToUpdate[tableColumn.sequence - 1].problem_id = event.id;
      }
    }
    rightStubObjToUpdate.tableColumns = tableColumnsToUpdate;
    setRightStubObj(rightStubObjToUpdate);
    setLoading(false);
  };

  const handleFileChange = (rightStubObj, column, event) => {
    const rightStubObjToUpdate = {...rightStubObj};
    const tableColumnsToUpdate = [...rightStubObj.tableColumns];

    const file = event.target.files[0];

    tableColumnsToUpdate[0]['value'] = file;

    rightStubObjToUpdate.tableColumns = tableColumnsToUpdate;
    setRightStubObj(rightStubObjToUpdate);
    setLoading(false);
  };
  
  const toggleSort = (rightStubObj, column) => {
    const rightStubObjToUpdate = {...rightStubObj};
    let tableColumnsToUpdate = [...rightStubObj.tableColumns.data];
    let sortToUpdate = {...rightStubObj.sort};

    if (Object.keys(sortToUpdate).length === 1) {
      if (column !== Object.keys(sortToUpdate)[0]) {
        sortToUpdate = {};
      }
    }

    if (sortToUpdate[column] === undefined) {
      sortToUpdate[column] = false;
    }

    if ((workerSubsidiarySlugOrWorkerAction === 'ordinary-attachments' || workerSubsidiarySlugOrWorkerAction === 'sensitive-attachments') && workerSubsidiaryIdOrWorkerSubsidiaryAction === undefined) {
      if (sortToUpdate[column] === false) {
        if (column === 'employer_name') {
          tableColumnsToUpdate = _.orderBy(tableColumnsToUpdate, function(item) {
            if (item.job !== null) {
              return item.job.employer_name;
            }
          }, ['asc']);
        } else if (column === 'chief_problem') {
          tableColumnsToUpdate = _.orderBy(tableColumnsToUpdate, function(item) {
            if (item.problem !== null) {
              return item.problem.chief_problem;
            }
          }, ['asc']);
        } else {
          tableColumnsToUpdate = _.orderBy(tableColumnsToUpdate, [column], ['asc']);
        }        
      } else {
        if (column === 'employer_name') {
          tableColumnsToUpdate = _.orderBy(tableColumnsToUpdate, function(item) {
            if (item.job !== null) {
              return item.job.employer_name;
            }
          }, ['desc']);
        } else if (column === 'chief_problem') {
          tableColumnsToUpdate = _.orderBy(tableColumnsToUpdate, function(item) {
            if (item.problem !== null) {
              return item.problem.chief_problem;
            }
          }, ['desc']);
        } else {
          tableColumnsToUpdate = _.orderBy(tableColumnsToUpdate, [column], ['desc']);
        }     
      }
    } else {
      if (sortToUpdate[column] === false) {
        // searchResultsToUpdate.sort((a,b) => (a[column] > b[column] ? 1 : -1));
        tableColumnsToUpdate = _.orderBy(tableColumnsToUpdate, [column], ['asc']);
      } else {
        // searchResultsToUpdate.sort((a,b) => (a[column] < b[column] ? 1 : -1));
        tableColumnsToUpdate = _.orderBy(tableColumnsToUpdate, [column], ['desc']);
      }
    }

    

    sortToUpdate[column] = !sortToUpdate[column];
    rightStubObjToUpdate.tableColumns.data = tableColumnsToUpdate;
    rightStubObjToUpdate.sort = sortToUpdate;
    setRightStubObj(rightStubObjToUpdate);
    setLoading(false);
  };

  const toggleSort2 = (obj, index, page) => {
    let sortToUpdate = {...obj.sort};

    const column = obj.dashedLabelColumns[index];

    if (Object.keys(sortToUpdate).length === 1) {
      if (index !== parseInt(Object.keys(sortToUpdate)[0])) {
        sortToUpdate = {};
      }
    }
    
    let rightStubObjToUpdate = {...obj};
    let tableColumnsToUpdate = [...obj.tableColumns];
    let innerTableColumnsToUpdate = [...obj.tableColumns[page-1]];

    if (sortToUpdate[index] === undefined) {
      sortToUpdate[index] = false;
    }

    if (sortToUpdate[index] === false) {
      innerTableColumnsToUpdate = _.orderBy(innerTableColumnsToUpdate, [column], ['asc']);
    } else {
      innerTableColumnsToUpdate = _.orderBy(innerTableColumnsToUpdate, [column], ['desc']);
    }

    sortToUpdate[index] = !sortToUpdate[index];

    rightStubObjToUpdate.sort = sortToUpdate;
    tableColumnsToUpdate[page-1] = innerTableColumnsToUpdate;
    rightStubObjToUpdate.tableColumns = tableColumnsToUpdate;

    setRightStubObj(rightStubObjToUpdate);
    setLoading(false);
  };

  const toggleSort3 = (rightStubObj, column, page) => {
    const rightStubObjToUpdate = {...rightStubObj};
    let tableColumnsToUpdate = [...rightStubObj.tableColumns[page - 1]];
    let sortToUpdate = {...rightStubObj.sort};

    if (Object.keys(sortToUpdate).length === 1) {
      if (column !== Object.keys(sortToUpdate)[0]) {
        sortToUpdate = {};
      }
    }

    if (sortToUpdate[column] === undefined) {
      sortToUpdate[column] = false;
    }

    if ((workerSubsidiarySlugOrWorkerAction === 'ordinary-attachments' || workerSubsidiarySlugOrWorkerAction === 'sensitive-attachments') && workerSubsidiaryIdOrWorkerSubsidiaryAction === undefined) {
      if (sortToUpdate[column] === false) {
        if (column === 'employer_name') {
          tableColumnsToUpdate = _.orderBy(tableColumnsToUpdate, function(item) {
            if (item.job !== null) {
              return item.job.employer_name;
            }
          }, ['asc']);
        } else if (column === 'chief_problem') {
          tableColumnsToUpdate = _.orderBy(tableColumnsToUpdate, function(item) {
            if (item.problem !== null) {
              return item.problem.chief_problem;
            }
          }, ['asc']);
        } else if (column === 'submitted_by') {
          tableColumnsToUpdate = _.orderBy(tableColumnsToUpdate, function(item) {
            if (item.submitter !== null) {
              return item.submitter.user_username;
            }
          }, ['asc']);
        } else {
          tableColumnsToUpdate = _.orderBy(tableColumnsToUpdate, [column], ['asc']);
        }        
      } else {
        if (column === 'employer_name') {
          tableColumnsToUpdate = _.orderBy(tableColumnsToUpdate, function(item) {
            if (item.job !== null) {
              return item.job.employer_name;
            }
          }, ['desc']);
        } else if (column === 'chief_problem') {
          tableColumnsToUpdate = _.orderBy(tableColumnsToUpdate, function(item) {
            if (item.problem !== null) {
              return item.problem.chief_problem;
            }
          }, ['desc']);
        } else if (column === 'submitted_by') {
          tableColumnsToUpdate = _.orderBy(tableColumnsToUpdate, function(item) {
            if (item.submitter !== null) {
              return item.submitter.user_username;
            }
          }, ['desc']);
        } else {
          tableColumnsToUpdate = _.orderBy(tableColumnsToUpdate, [column], ['desc']);
        }     
      }
    } else {
      if (sortToUpdate[column] === false) {
        if (column === 'employer_name') {
          tableColumnsToUpdate = _.orderBy(tableColumnsToUpdate, function(item) {
            if (item.job !== null) {
              return item.job.employer_name;
            }
          }, ['asc']);
        } else if (column === 'chief_problem') {
          tableColumnsToUpdate = _.orderBy(tableColumnsToUpdate, function(item) {
            if (item.problem !== null) {
              return item.problem.chief_problem;
            }
          }, ['asc']);
        } else if (column === 'submitted_by') {
          tableColumnsToUpdate = _.orderBy(tableColumnsToUpdate, function(item) {
            if (item.submitter !== null) {
              return item.submitter.user_username;
            }
          }, ['asc']);
        } else {
          tableColumnsToUpdate = _.orderBy(tableColumnsToUpdate, [column], ['asc']);
        }
      } else {
        if (column === 'employer_name') {
          tableColumnsToUpdate = _.orderBy(tableColumnsToUpdate, function(item) {
            if (item.job !== null) {
              return item.job.employer_name;
            }
          }, ['desc']);
        } else if (column === 'chief_problem') {
          tableColumnsToUpdate = _.orderBy(tableColumnsToUpdate, function(item) {
            if (item.problem !== null) {
              return item.problem.chief_problem;
            }
          }, ['desc']);
        } else if (column === 'submitted_by') {
          tableColumnsToUpdate = _.orderBy(tableColumnsToUpdate, function(item) {
            if (item.submitter !== null) {
              return item.submitter.user_username;
            }
          }, ['desc']);
        } else {
          tableColumnsToUpdate = _.orderBy(tableColumnsToUpdate, [column], ['desc']);
        }
      }
    }

    sortToUpdate[column] = !sortToUpdate[column];
    rightStubObjToUpdate.tableColumns[page-1] = tableColumnsToUpdate;
    rightStubObjToUpdate.sort = sortToUpdate;
    setRightStubObj(rightStubObjToUpdate);
    setLoading(false);
  };
  
  useEffect(() => {
    setShowDeleteConfirmation(false);

    // TODO!!!
    // setSubHeaderObj({
    //   a: 'a'
    // });

    if (workerSubsidiarySlugOrWorkerAction === undefined) {
      const privilegeObject = {
        tableSlug: tableSlug, 
        token: cookies['token']
      };

      getPrivilegeForCurrentPage(privilegeObject).then(privilegesResult => {
        const privilegesToUpdate = transformPrivileges(privilegesResult, 'List');
        setPrivileges(privilegesToUpdate);

        prepareTableColumnsWithPrivileges('workers', privilegesToUpdate, 'View', workerId).then(result => {
          if (!matchWorkerJobProblem(result, workerId, workerSubsidiarySlugOrWorkerAction, workerSubsidiaryIdOrWorkerSubsidiaryAction, jobSubsidiarySlugOrWorkerSubsidiaryAction, jobSubsidiaryIdOrJobSubsidiaryAction)) navigate('/');
          setPage('View');
          setTable('workers');
          setLevel('workers');
          setTableColumns(result);

          setNavigationStubObj({
            level: 'workers',
            workerId: workerId
          });

          setSubHeaderObj({
            workerId: workerId,
            table: tableSlug, 
            page: 'View',
            privileges: privilegesToUpdate,
            workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
            workerSubsidiaryIdOrWorkerSubsidiaryAction: workerSubsidiaryIdOrWorkerSubsidiaryAction,
            jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction
          });

          // for (let i = 0; i < result.length; i += 1) {
          //   if (result[i]['value'] !== null && result[i]['value'] !== undefined) {
          //     if (typeof(result[i]['value']) === 'string') {
          //     result[i]['value'] = result[i]['value'].replace(/\n/g, '\\n');
          //     }
          //   }
          // }

          setRightStubObj({
            page: 'View',
            tableColumns: result,
            table: 'Worker',
            setRightStubObj: setRightStubObj,
            toggleSort: toggleSort,
            sort: sort,
          });
          setLoading(false);

          setTopAndMiddleNavigationObj({
            level: 'workers',
            workerId: workerId,
            page: 'View'
          });
        });
      });
    } else if (
      workerSubsidiarySlugOrWorkerAction !== undefined && 
      workerSubsidiaryIdOrWorkerSubsidiaryAction === undefined
    ) {
      // either worker edit or worker subsidiary list
      if (workerSubsidiarySlugOrWorkerAction === 'edit') {
        const privilegeObject = {
          tableSlug: tableSlug, 
          token: cookies['token']
        };

        getPrivilegeForCurrentPage(privilegeObject).then(privilegesResult => {
          const privilegesToUpdate = transformPrivileges(privilegesResult, 'Edit');
          setPrivileges(privilegesToUpdate);

          prepareTableColumnsWithPrivilegeAndInputType('workers', privilegesToUpdate, 'Edit', workerId, workerId, null, null).then(result => {
            setPage('Edit');
            setTable('workers');
            setLevel('workers');
            setTableColumns(result);

            setNavigationStubObj({
              level: 'workers',
              workerId: workerId
            });

            setSubHeaderObj({
              workerId: workerId,
              table: tableSlug, 
              page: 'Edit',
              privileges: privilegesToUpdate,
              workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
              workerSubsidiaryIdOrWorkerSubsidiaryAction: workerSubsidiaryIdOrWorkerSubsidiaryAction,
              jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
              jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
              problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction,
              problemSubsidiaryIdOrProblemSubsidiaryAction: problemSubsidiaryIdOrProblemSubsidiaryAction
            });

            setRightStubObj({
              level: 'workers',
              page: 'Edit',
              tableColumns: result,
              table: 'Worker',
              handleChange: handleChange,
              handleCalendarChange: handleCalendarChange,
              handleDropdownChange: handleDropdownChange,
              handleFileChange: handleFileChange,
              handleFormSubmit: handleFormSubmit,
              handleComboBoxChange: handleComboBoxChange,
              deleteButton: deleteButton,
              setRightStubObj: setRightStubObj,
              workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
              toggleSort: toggleSort,
              sort: sort,
            });
            setLoading(false);
            
            setTopAndMiddleNavigationObj({
              level: 'workers',
              workerId: workerId,
              page: 'Edit'
            });
          });
        });
      } else {
        const privilegeObject = {
          tableSlug: workerSubsidiarySlugOrWorkerAction, 
          token: cookies['token']
        };

        getPrivilegeForCurrentPage(privilegeObject).then(privilegesResult => {
          const privilegesToUpdate = transformPrivileges(privilegesResult, 'List');
          setPrivileges(privilegesToUpdate);

          prepareListWithPrivileges(workerId, null, null, workerSubsidiarySlugOrWorkerAction, null, cookies['token']).then(result => {
            setPage('List');
            setTable(workerSubsidiarySlugOrWorkerAction);
            setLevel('workers');

            setNavigationStubObj({
              level: 'workers',
              workerId: workerId
            });

            // diaryColumns: diaryColumns,
            // diaryData: diaryData,
            // diffWeeks: diffWeeks

            const {dateOrDateTimeColumns, stringColumns, columns, data, referenceData, diaryColumns, diaryData, diffWeeks, currWeekCounter} = result;
            setTableColumns(data);

            const labelColumns = columns.map((column) => column.label_name);
            const dashedLabelColumns = columns.map((column) => column.column_name);

            getSubsidiaryTableFromSubsidiarySlug(workerSubsidiarySlugOrWorkerAction).then(result => {
              setSubHeaderObj({
                workerId: workerId,
                table: tableSlug, 
                page: 'List',
                privileges: privilegesToUpdate,
                workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                workerSubsidiaryIdOrWorkerSubsidiaryAction: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction,
                problemSubsidiaryIdOrProblemSubsidiaryAction: problemSubsidiaryIdOrProblemSubsidiaryAction,
                subsidiary: result.data.table_name,
              });

              if (workerSubsidiarySlugOrWorkerAction === 'facepic-attachments' || workerSubsidiarySlugOrWorkerAction === 'pdpa-attachments' || workerSubsidiarySlugOrWorkerAction === 'ordinary-attachments' || workerSubsidiarySlugOrWorkerAction === 'sensitive-attachments') {
                setObjects(data.data);
                setObjectIdsSelected(new Array(data.data.length).fill(false));
                setObjectsSelected([]);

                const records = [...data.data];

                data.data = [];

                for (let i = 0; i < records.length; i += 10) {
                  data.data[i/10] = records.slice(i, i+10);
                }

                setRightStubObj({
                  page: 'List',
                  dateOrDateTimeColumns: dateOrDateTimeColumns,
                  stringColumns: stringColumns,
                  tableColumns: data.data,
                  workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                  labelColumns: labelColumns,
                  dashedLabelColumns: dashedLabelColumns,
                  workerId: workerId,
                  table: workerSubsidiarySlugOrWorkerAction.replace(/-/g, ' ').toUpperCase(),
                  handleChange: handleChange,
                  handleCalendarChange: handleCalendarChange,
                  handleDropdownChange: handleDropdownChange,
                  handleFileChange: handleFileChange,
                  handleOnChangeCheckbox: handleOnChangeCheckbox,
                  handleFormSubmit: handleFormSubmit,
                  subsidiary: result.data.table_name,
                  objects: data.data,
                  noOfRecords: records.length,
                  objectIdsSelected: new Array(data.data.length).fill(false),
                  objectsSelected: [],
                  referenceData: referenceData,
                  setRightStubObj: setRightStubObj,
                  toggleSort: toggleSort,
                  toggleSort2: toggleSort2,
                  toggleSort3: toggleSort3,
                  sort: sort
                });
                setLoading(false);
              } else {
                const records = [...data.data];

                data.data = [];

                for (let i = 0; i < records.length; i += 10) {
                  data.data[i/10] = records.slice(i, i+10);
                }

                setRightStubObj({
                  page: 'List',
                  dateOrDateTimeColumns: dateOrDateTimeColumns,
                  stringColumns: stringColumns,
                  tableColumns: data.data,
                  noOfRecords: records.length,
                  workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                  labelColumns: labelColumns,
                  dashedLabelColumns: dashedLabelColumns,
                  workerId: workerId,
                  table: workerSubsidiarySlugOrWorkerAction.replace(/-/g, ' ').toUpperCase(),
                  handleChange: handleChange,
                  handleCalendarChange: handleCalendarChange,
                  handleDropdownChange: handleDropdownChange,
                  handleFileChange: handleFileChange,
                  handleOnChangeCheckbox: handleOnChangeCheckbox,
                  handleFormSubmit: handleFormSubmit,
                  referenceData: referenceData,
                  setRightStubObj: setRightStubObj,
                  toggleSort: toggleSort,
                  toggleSort2: toggleSort2,
                  sort: sort,
                  diaryColumns: diaryColumns,
                  diaryData: diaryData,
                  diffWeeks: diffWeeks,
                  currWeekCounter: currWeekCounter
                });
                setLoading(false);
              }

              setTopAndMiddleNavigationObj({
                level: 'workers',
                workerId: workerId,
                page: 'List',
                subsidiary: result.data.table_name,
                workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction
              });
            });
          });
        });
      }
    } else if (
      workerSubsidiarySlugOrWorkerAction !== undefined && 
      workerSubsidiaryIdOrWorkerSubsidiaryAction !== undefined && 
      jobSubsidiarySlugOrWorkerSubsidiaryAction === undefined
    ) {
      // worker subsidiary view
      if (workerSubsidiaryIdOrWorkerSubsidiaryAction === 'add') {
        // add a subsidiary 
        const privilegeObject = {
          tableSlug: workerSubsidiarySlugOrWorkerAction, 
          token: cookies['token']
        };

        getPrivilegeForCurrentPage(privilegeObject).then(privilegesResult => {
          const privilegesToUpdate = transformPrivileges(privilegesResult, 'Add');
          setPrivileges(privilegesToUpdate);

          prepareTableColumnsWithPrivilegeAndInputType(workerSubsidiarySlugOrWorkerAction, privilegesToUpdate, 'Add', null, workerId, null, null, cookies['userId']).then(result => {

            setPage('Add');
            setTable(workerSubsidiarySlugOrWorkerAction);
            setLevel('workers');
            setTableColumns(result);

            getSubsidiaryTableFromSubsidiarySlug(workerSubsidiarySlugOrWorkerAction).then(result2 => {
              setSubHeaderObj({
                workerId: workerId,
                table: tableSlug, 
                page: 'Add',
                privileges: privilegesToUpdate,
                workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                workerSubsidiaryIdOrWorkerSubsidiaryAction: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction,
                problemSubsidiaryIdOrProblemSubsidiaryAction: problemSubsidiaryIdOrProblemSubsidiaryAction
              });

              if (workerSubsidiarySlugOrWorkerAction === 'ordinary-attachments' || workerSubsidiarySlugOrWorkerAction === 'sensitive-attachments') {
                getJobAndProblemByWorkerId(workerId).then(jobAndProblems => {
                  const jobIdsToProblems = {};

                  jobAndProblems.data.problems.forEach((problem) => {
                    if (jobIdsToProblems[problem.job_id] === undefined) {
                      jobIdsToProblems[problem.job_id] = [];
                    }

                    jobIdsToProblems[problem.job_id].push(problem);
                  });

                  for (let i = 0; i < result.length; i += 1) {
                    if (result[i]['column_name'] === 'employer_name') {
                      result[i]['dropdowns'] = [{dropdown_item: '-'}];
                      jobAndProblems.data.jobs.forEach(job => {
                        result[i]['dropdowns'].push({id: job.id, dropdown_item: job.employer_name});
                      });
                    } else if (result[i]['column_name'] === 'chief_problem') {
                      result[i]['dropdowns'] = [{dropdown_item: '-'}];
                    }
                  }
                  
                  setRightStubObj({
                    page: 'Add',
                    tableColumns: result,
                    table: result2.data.table_name,
                    handleChange: handleChange,
                    handleCalendarChange: handleCalendarChange,
                    handleDropdownChange: handleDropdownChange,
                    handleJobChange: handleJobChange,
                    handleFileChange: handleFileChange,
                    handleFormSubmit: handleFormSubmit,
                    workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                    workerSubsidiaryIdOrWorkerSubsidiaryAction: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                    jobIdsToProblems: jobIdsToProblems,
                    setRightStubObj: setRightStubObj,
                    handleComboBoxChange: handleComboBoxChange,
                    deleteButton: deleteButton,
                    toggleSort: toggleSort,
                    sort: sort
                  });
                  setLoading(false);
                });
              } else if (workerSubsidiarySlugOrWorkerAction === 'facepic-attachments' || workerSubsidiarySlugOrWorkerAction === 'pdpa-attachments') {
                setRightStubObj({
                  page: 'Add',
                  tableColumns: result,
                  table: result2.data.table_name,
                  handleChange: handleChange,
                  handleCalendarChange: handleCalendarChange,
                  handleDropdownChange: handleDropdownChange,
                  handleJobChange: handleJobChange,
                  handleFileChange: handleFileChange,
                  handleFormSubmit: handleFormSubmit,
                  workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                  workerSubsidiaryIdOrWorkerSubsidiaryAction: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                  setRightStubObj: setRightStubObj,
                  handleComboBoxChange: handleComboBoxChange,
                  deleteButton: deleteButton,
                  toggleSort: toggleSort,
                  sort: sort
                });
                setLoading(false);
              } else {
                if (workerSubsidiarySlugOrWorkerAction === 'jobs') {
                  getLargestSequence('jobs', workerId).then(result22 => {
                    let sequence;
                    if (result22.data.length === 0) {
                      sequence = 1;
                    } else {
                      sequence = result22.data[0]['job_sequence'] + 1;
                    }

                    for (let i = 0; i < result.length; i += 1) {
                      if (result[i].column_name === 'job_sequence') {
                        result[i].value = sequence;
                        break;
                      }
                    }

                    setRightStubObj({
                      page: 'Add',
                      tableColumns: result,
                      table: result2.data.table_name,
                      handleChange: handleChange,
                      handleCalendarChange: handleCalendarChange,
                      handleDropdownChange: handleDropdownChange,
                      handleFileChange: handleFileChange,
                      handleFormSubmit: handleFormSubmit,
                      setRightStubObj: setRightStubObj,
                      toggleSort: toggleSort,
                      sort: sort,
                      handleComboBoxChange: handleComboBoxChange,
                      deleteButton: deleteButton,
                      workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction
                    });
                    setLoading(false);
                  });
                } else {
                  setRightStubObj({
                    page: 'Add',
                    tableColumns: result,
                    table: result2.data.table_name,
                    handleChange: handleChange,
                    handleCalendarChange: handleCalendarChange,
                    handleDropdownChange: handleDropdownChange,
                    handleFileChange: handleFileChange,
                    handleFormSubmit: handleFormSubmit,
                    setRightStubObj: setRightStubObj,
                    toggleSort: toggleSort,
                    sort: sort,
                    handleComboBoxChange: handleComboBoxChange,
                    deleteButton: deleteButton,
                    workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction
                  });
                  setLoading(false);
                }
              }

              if (workerSubsidiarySlugOrWorkerAction === 'jobs' && workerSubsidiaryIdOrWorkerSubsidiaryAction !== 'add') {
                setNavigationStubObj({
                  level: 'jobs',
                  workerId: workerId,
                  jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                });

                setTopAndMiddleNavigationObj({
                  level: 'jobs',
                  workerId: workerId,
                  jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                  page: 'Add',
                  subsidiary: result2.data.table_name,
                  workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction
                });
              } else if (workerSubsidiarySlugOrWorkerAction === 'jobs' && workerSubsidiaryIdOrWorkerSubsidiaryAction === 'add') {
                setNavigationStubObj({
                  level: 'jobs',
                  workerId: workerId,
                  jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                });

                setTopAndMiddleNavigationObj({
                  level: 'jobs',
                  workerId: workerId,
                  page: 'Add',
                  subsidiary: result2.data.table_name,
                  workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction
                })
              } else {
                setNavigationStubObj({
                  level: 'workers',
                  workerId: workerId
                });

                setTopAndMiddleNavigationObj({
                  level: 'workers',
                  workerId: workerId,
                  page: 'Add',
                  subsidiary: result2.data.table_name,
                  workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction
                });
              }
            });
          });
        });
      } else {
        // view a subsidiary  record

        if (workerSubsidiarySlugOrWorkerAction === 'sensitive-attachments') {
          checkCurrentUserCanViewSensitiveAttachment(workerSubsidiaryIdOrWorkerSubsidiaryAction, cookies['token'])
          .then(result => {
            if (result.data === '' || result.data.length === 0) {
              navigate(`/workers/${workerId}/${workerSubsidiarySlugOrWorkerAction}`);
            } else {
              const privilegeObject = {
                tableSlug: workerSubsidiarySlugOrWorkerAction, 
                token: cookies['token']
              };

              getPrivilegeForCurrentPage(privilegeObject).then(privilegesResult => {
                const privilegesToUpdate = transformPrivileges(privilegesResult, 'View');
                setPrivileges(privilegesToUpdate);

                prepareTableColumnsWithPrivileges(workerSubsidiarySlugOrWorkerAction, privilegesToUpdate, 'View', workerSubsidiaryIdOrWorkerSubsidiaryAction).then(result => {
                  if (!matchWorkerJobProblem(result, workerId, workerSubsidiarySlugOrWorkerAction, workerSubsidiaryIdOrWorkerSubsidiaryAction, jobSubsidiarySlugOrWorkerSubsidiaryAction, jobSubsidiaryIdOrJobSubsidiaryAction)) navigate('/');
                  setPage('View');
                  setTable(workerSubsidiarySlugOrWorkerAction);
                  setLevel('workers');
                  setTableColumns(result);

                  getSubsidiaryTableFromSubsidiarySlug(workerSubsidiarySlugOrWorkerAction).then(result2 => {
                    setSubHeaderObj({
                      workerId: workerId,
                      table: tableSlug, 
                      page: 'View',
                      privileges: privilegesToUpdate,
                      workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                      workerSubsidiaryIdOrWorkerSubsidiaryAction: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                      jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                      jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                      problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction,
                      problemSubsidiaryIdOrProblemSubsidiaryAction: problemSubsidiaryIdOrProblemSubsidiaryAction,
                      subsidiary: result2.data.table_name
                    });

                    if (workerSubsidiarySlugOrWorkerAction === 'facepic-attachments' || workerSubsidiarySlugOrWorkerAction === 'pdpa-attachments' || workerSubsidiarySlugOrWorkerAction === 'ordinary-attachments' || workerSubsidiarySlugOrWorkerAction === 'sensitive-attachments') {
                      setRightStubObj({
                        page: 'View',
                        tableColumns: result,
                        table: result2.data.table_name,
                        handleChange: handleChange,
                        handleCalendarChange: handleCalendarChange,
                        handleDropdownChange: handleDropdownChange,
                        handleFileChange: handleFileChange,
                        handleFormSubmit: handleFormSubmit,
                        workerId: workerId,
                        workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                        workerSubsidiaryIdOrWorkerSubsidiaryAction: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                        setRightStubObj: setRightStubObj,
                        toggleSort: toggleSort,
                        sort: sort
                      });
                      setLoading(false);
                    } else {
                      setRightStubObj({
                        page: 'View',
                        tableColumns: result,
                        table: result2.data.table_name,
                        handleChange: handleChange,
                        handleCalendarChange: handleCalendarChange,
                        handleDropdownChange: handleDropdownChange,
                        handleFileChange: handleFileChange,
                        handleFormSubmit: handleFormSubmit,
                        setRightStubObj: setRightStubObj,
                        toggleSort: toggleSort,
                        workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                        sort: sort
                      });
                      setLoading(false);
                    }
                    
                    if (workerSubsidiarySlugOrWorkerAction === 'jobs') {
                      setTopAndMiddleNavigationObj({
                        level: 'jobs',
                        workerId: workerId,
                        jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                        page: 'View',
                        subsidiary: result2.data.table_name,
                        workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction
                      });

                      setNavigationStubObj({
                        level: 'jobs',
                        workerId: workerId,
                        jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                      });
                    } else {
                      setTopAndMiddleNavigationObj({
                        level: 'workers',
                        workerId: workerId,
                        page: 'View',
                        subsidiary: result2.data.table_name,
                        workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction
                      });

                      setNavigationStubObj({
                        level: 'workers',
                        workerId: workerId
                      });
                    }
                  });
                });
              });
            }
          })
          .catch(error => {
            console.log(error);
          })
        } else {
          const privilegeObject = {
            tableSlug: workerSubsidiarySlugOrWorkerAction, 
            token: cookies['token']
          };

          getPrivilegeForCurrentPage(privilegeObject).then(privilegesResult => {
            const privilegesToUpdate = transformPrivileges(privilegesResult, 'View');
            setPrivileges(privilegesToUpdate);

            prepareTableColumnsWithPrivileges(workerSubsidiarySlugOrWorkerAction, privilegesToUpdate, 'View', workerSubsidiaryIdOrWorkerSubsidiaryAction).then(result => {
              if (!matchWorkerJobProblem(result, workerId, workerSubsidiarySlugOrWorkerAction, workerSubsidiaryIdOrWorkerSubsidiaryAction, jobSubsidiarySlugOrWorkerSubsidiaryAction, jobSubsidiaryIdOrJobSubsidiaryAction)) navigate('/');
              setPage('View');
              setTable(workerSubsidiarySlugOrWorkerAction);
              setLevel('workers');
              setTableColumns(result);

              getSubsidiaryTableFromSubsidiarySlug(workerSubsidiarySlugOrWorkerAction).then(result2 => {
                setSubHeaderObj({
                  workerId: workerId,
                  table: tableSlug, 
                  page: 'View',
                  privileges: privilegesToUpdate,
                  workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                  workerSubsidiaryIdOrWorkerSubsidiaryAction: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                  jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                  jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                  problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction,
                  problemSubsidiaryIdOrProblemSubsidiaryAction: problemSubsidiaryIdOrProblemSubsidiaryAction,
                  subsidiary: result2.data.table_name
                });

                if (workerSubsidiarySlugOrWorkerAction === 'facepic-attachments' || workerSubsidiarySlugOrWorkerAction === 'pdpa-attachments' || workerSubsidiarySlugOrWorkerAction === 'ordinary-attachments' || workerSubsidiarySlugOrWorkerAction === 'sensitive-attachments') {
                  setRightStubObj({
                    page: 'View',
                    tableColumns: result,
                    table: result2.data.table_name,
                    handleChange: handleChange,
                    handleCalendarChange: handleCalendarChange,
                    handleDropdownChange: handleDropdownChange,
                    handleFileChange: handleFileChange,
                    handleFormSubmit: handleFormSubmit,
                    workerId: workerId,
                    workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                    workerSubsidiaryIdOrWorkerSubsidiaryAction: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                    setRightStubObj: setRightStubObj,
                    toggleSort: toggleSort,
                    sort: sort
                  });
                  setLoading(false);
                } else {
                  setRightStubObj({
                    page: 'View',
                    tableColumns: result,
                    table: result2.data.table_name,
                    handleChange: handleChange,
                    handleCalendarChange: handleCalendarChange,
                    handleDropdownChange: handleDropdownChange,
                    handleFileChange: handleFileChange,
                    handleFormSubmit: handleFormSubmit,
                    setRightStubObj: setRightStubObj,
                    toggleSort: toggleSort,
                    workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                    sort: sort
                  });
                  setLoading(false);
                }
                
                if (workerSubsidiarySlugOrWorkerAction === 'jobs') {
                  setTopAndMiddleNavigationObj({
                    level: 'jobs',
                    workerId: workerId,
                    jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                    page: 'View',
                    subsidiary: result2.data.table_name,
                    workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction
                  });

                  setNavigationStubObj({
                    level: 'jobs',
                    workerId: workerId,
                    jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                  });
                } else {
                  setTopAndMiddleNavigationObj({
                    level: 'workers',
                    workerId: workerId,
                    page: 'View',
                    subsidiary: result2.data.table_name,
                    workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction
                  });

                  setNavigationStubObj({
                    level: 'workers',
                    workerId: workerId
                  });
                }
              });
            });
          });
        }
      }
    } else if (
      workerSubsidiarySlugOrWorkerAction !== undefined && 
      workerSubsidiaryIdOrWorkerSubsidiaryAction !== undefined && 
      jobSubsidiarySlugOrWorkerSubsidiaryAction !== undefined && 
      jobSubsidiaryIdOrJobSubsidiaryAction === undefined
    ) {
      if (jobSubsidiarySlugOrWorkerSubsidiaryAction === 'edit') {
        if (workerSubsidiarySlugOrWorkerAction === 'sensitive-attachments') {
          checkCurrentUserCanViewSensitiveAttachment(workerSubsidiaryIdOrWorkerSubsidiaryAction, cookies['token'])
          .then(result => {
            if (result.data === '' || result.data.length === 0) {
              navigate(`/workers/${workerId}/${workerSubsidiarySlugOrWorkerAction}/${workerSubsidiaryIdOrWorkerSubsidiaryAction}`);
            } else {
              const privilegeObject = {
                tableSlug: workerSubsidiarySlugOrWorkerAction, 
                token: cookies['token']
              };
      
              getPrivilegeForCurrentPage(privilegeObject).then(privilegesResult => {
                const privilegesToUpdate = transformPrivileges(privilegesResult, 'Edit');
                setPrivileges(privilegesToUpdate);
                
                if (workerSubsidiarySlugOrWorkerAction === 'jobs') {
                  prepareTableColumnsWithPrivilegeAndInputType(workerSubsidiarySlugOrWorkerAction, privilegesToUpdate, 'Edit', workerSubsidiaryIdOrWorkerSubsidiaryAction, workerId, workerSubsidiaryIdOrWorkerSubsidiaryAction, null).then(result => {
                    setPage('Edit');
                    setTable(workerSubsidiarySlugOrWorkerAction);
                    setLevel('workers');
                    setTableColumns(result);
      
                    getSubsidiaryTableFromSubsidiarySlug(workerSubsidiarySlugOrWorkerAction).then(result2 => {
                      setSubHeaderObj({
                        workerId: workerId,
                        table: tableSlug, 
                        page: 'Edit',
                        privileges: privilegesToUpdate,
                        workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                        workerSubsidiaryIdOrWorkerSubsidiaryAction: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                        jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                        jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                        problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction,
                        problemSubsidiaryIdOrProblemSubsidiaryAction: problemSubsidiaryIdOrProblemSubsidiaryAction
                      });
                      
                      setRightStubObj({
                        page: 'Edit',
                        tableColumns: result,
                        table: result2.data.table_name,
                        handleChange: handleChange,
                        handleCalendarChange: handleCalendarChange,
                        handleDropdownChange: handleDropdownChange,
                        handleFileChange: handleFileChange,
                        setRightStubObj: setRightStubObj,
                        toggleSort: toggleSort,
                        sort: sort,
                        deleteButton: deleteButton,
                        handleComboBoxChange: handleComboBoxChange,
                        handleFormSubmit: handleFormSubmit,
                        workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                        jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction
                      });
                      setLoading(false);
      
                      if (workerSubsidiarySlugOrWorkerAction === 'jobs') {
                        setNavigationStubObj({
                          level: 'workers',
                          workerId: workerId,
                          jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                        });
                        
                        setTopAndMiddleNavigationObj({
                          level: 'jobs',
                          workerId: workerId,
                          jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                          page: 'Edit',
                          subsidiary: result2.data.table_name,
                          workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction
                        });
                      } else {
                        setNavigationStubObj({
                          level: 'workers',
                          workerId: workerId
                        });
                        
                        setTopAndMiddleNavigationObj({
                          level: 'workers',
                          workerId: workerId,
                          page: 'Edit',
                          subsidiary: result2.data.table_name,
                          workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction
                        });
                      }
                    });
                  });
                } else {
                  prepareTableColumnsWithPrivilegeAndInputType(workerSubsidiarySlugOrWorkerAction, privilegesToUpdate, 'Edit', workerSubsidiaryIdOrWorkerSubsidiaryAction, workerId, null, null).then(result => {
                    setPage('Edit');
                    setTable(workerSubsidiarySlugOrWorkerAction);
                    setLevel('workers');
                    setTableColumns(result);
      
                    getSubsidiaryTableFromSubsidiarySlug(workerSubsidiarySlugOrWorkerAction).then(result2 => {
                      setSubHeaderObj({
                        workerId: workerId,
                        table: tableSlug, 
                        page: 'Edit',
                        privileges: privilegesToUpdate,
                        workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                        workerSubsidiaryIdOrWorkerSubsidiaryAction: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                        jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                        jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                        problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction,
                        problemSubsidiaryIdOrProblemSubsidiaryAction: problemSubsidiaryIdOrProblemSubsidiaryAction
                      });
                      
                      if (workerSubsidiarySlugOrWorkerAction === 'ordinary-attachments' || workerSubsidiarySlugOrWorkerAction === 'sensitive-attachments') {
                        getJobAndProblemByWorkerId(workerId).then(jobAndProblems => {
                          const jobIdsToProblems = {};
                          
                          jobAndProblems.data.problems.forEach((problem) => {
                            if (jobIdsToProblems[problem.job_id] === undefined) {
                              jobIdsToProblems[problem.job_id] = [];
                            }
      
                            jobIdsToProblems[problem.job_id].push(problem);
                          });
      
                          let employerNameId = '';
                          let chiefProblemId = '';
      
                          for (let i = 0; i < result.length; i += 1) {
                            if (result[i]['column_name'] === 'employer_name') {
                              employerNameId = i;
                              result[i]['dropdowns'] = [{dropdown_item: '-'}];
                              jobAndProblems.data.jobs.forEach(job => {
                                result[i]['dropdowns'].push({id: job.id, dropdown_item: job.employer_name});
                              });
                            } else if (result[i]['column_name'] === 'chief_problem') {
                              chiefProblemId = i;
                              result[i]['dropdowns'] = [{dropdown_item: '-'}];
                            } else if (result[i]['column_name'] === 'job_id') {
                              if (result[i]['value'] !== '') {
                                result[employerNameId]['job_id'] = result[i].value;
      
                                result[employerNameId]['value'] = result[employerNameId]['dropdowns'].filter((dropdown) => dropdown.id === result[i].value)[0]['dropdown_item'];
                              }
                            } else if (result[i]['column_name'] === 'problem_id') {
                              if (result[i]['value'] !== '') {
                                result[chiefProblemId]['problem_id'] = result[i].value;
                                if (result[employerNameId]['job_id'] !== '') {
                                  jobAndProblems.data.problems.forEach((problem) => {
                                    if (problem.job_id === result[employerNameId]['job_id']) {
                                      result[chiefProblemId]['dropdowns'].push({id: problem.id, dropdown_item: problem.chief_problem});
                                    }
      
                                    if (problem.job_id === result[employerNameId]['job_id']) {
                                      result[chiefProblemId]['value'] = problem.chief_problem;
                                    }
                                  });
                                }
                              } else {
                                if (result[employerNameId]['job_id'] !== '') {
                                  jobAndProblems.data.problems.forEach((problem) => {
                                    if (problem.job_id === result[employerNameId]['job_id']) {
                                      result[chiefProblemId]['dropdowns'].push({id: problem.id, dropdown_item: problem.chief_problem});
                                    }
                                  });
                                }
                              }
                            }
                          }
      
                          setRightStubObj({
                            page: 'Edit',
                            tableColumns: result,
                            table: result2.data.table_name,
                            handleChange: handleChange,
                            handleCalendarChange: handleCalendarChange,
                            handleDropdownChange: handleDropdownChange,
                            handleFileChange: handleFileChange,
                            handleFormSubmit: handleFormSubmit,
                            handleJobChange: handleJobChange,
                            workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                            workerSubsidiaryIdOrWorkerSubsidiaryAction: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                            jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                            setRightStubObj: setRightStubObj,
                            toggleSort: toggleSort,
                            jobIdsToProblems: jobIdsToProblems,
                            sort: sort,
                            deleteButton: deleteButton,
                            handleComboBoxChange: handleComboBoxChange,
                            updatedFacepic: updatedFacepic,
                            setUpdatedFacepic: setUpdatedFacepic,
                            updatedFacepicDataFormat: updatedFacepicDataFormat,
                            setUpdatedFacepicDataFormat: setUpdatedFacepicDataFormat
                          });
                          setLoading(false);
      
                          // setRightStubObj({
                          //   page: 'Edit',
                          //   tableColumns: result,
                          //   table: result2.data.table_name,
                          //   handleChange: handleChange,
                          //   handleCalendarChange: handleCalendarChange,
                          //   handleDropdownChange: handleDropdownChange,
                          //   handleJobChange: handleJobChange,
                          //   handleFileChange: handleFileChange,
                          //   setRightStubObj: setRightStubObj,
                          //   toggleSort: toggleSort,
                          //   sort: sort,
                          //   deleteButton: deleteButton,
                          //   handleComboBoxChange: handleComboBoxChange,
                          //   handleFormSubmit: handleFormSubmit,
                          //   workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                          //   jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                          //   jobIdsToProblems: jobIdsToProblems
                          // });
                        });
                      } else if (workerSubsidiarySlugOrWorkerAction === 'facepic-attachments' || workerSubsidiarySlugOrWorkerAction === 'pdpa-attachments') {
                        setRightStubObj({
                          page: 'Edit',
                          tableColumns: result,
                          table: result2.data.table_name,
                          handleChange: handleChange,
                          handleCalendarChange: handleCalendarChange,
                          handleDropdownChange: handleDropdownChange,
                          handleFileChange: handleFileChange,
                          handleFormSubmit: handleFormSubmit,
                          workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                          workerSubsidiaryIdOrWorkerSubsidiaryAction: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                          jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                          setRightStubObj: setRightStubObj,
                          toggleSort: toggleSort,
                          sort: sort,
                          deleteButton: deleteButton,
                          handleComboBoxChange: handleComboBoxChange,
                          updatedFacepic: updatedFacepic,
                          setUpdatedFacepic: setUpdatedFacepic,
                          updatedFacepicDataFormat: updatedFacepicDataFormat,
                          setUpdatedFacepicDataFormat: setUpdatedFacepicDataFormat
                        });
                        setLoading(false);
                      } else {
                        setRightStubObj({
                          page: 'Edit',
                          tableColumns: result,
                          table: result2.data.table_name,
                          handleChange: handleChange,
                          handleCalendarChange: handleCalendarChange,
                          handleDropdownChange: handleDropdownChange,
                          handleFileChange: handleFileChange,
                          setRightStubObj: setRightStubObj,
                          toggleSort: toggleSort,
                          sort: sort,
                          deleteButton: deleteButton,
                          handleComboBoxChange: handleComboBoxChange,
                          handleFormSubmit: handleFormSubmit,
                          workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                          jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction
                        }); 
                        setLoading(false);
                      }
      
                      setNavigationStubObj({
                        level: 'workers',
                        workerId: workerId
                      });
                      
                      setTopAndMiddleNavigationObj({
                        level: 'workers',
                        workerId: workerId,
                        page: 'Edit',
                        subsidiary: result2.data.table_name,
                        workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction
                      });
      
                      // if (workerSubsidiarySlugOrWorkerAction === 'jobs') {
                      //   setNavigationStubObj({
                      //     level: 'jobs',
                      //     workerId: workerId,
                      //     jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                      //   });
                        
                      //   setTopAndMiddleNavigationObj({
                      //     level: 'jobs',
                      //     workerId: workerId,
                      //     jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                      //     page: 'Edit',
                      //     subsidiary: result2.data.table_name,
                      //     workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction
                      //   });
                      // } else {
                      //   setNavigationStubObj({
                      //     level: 'workers',
                      //     workerId: workerId
                      //   });
                        
                      //   setTopAndMiddleNavigationObj({
                      //     level: 'workers',
                      //     workerId: workerId,
                      //     page: 'Edit',
                      //     subsidiary: result2.data.table_name,
                      //     workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction
                      //   });
                      // }
                    });
                  });
                }
      
              })
            }
          })
          .catch(error => {
            console.log(error);
          });
        } else {
          const privilegeObject = {
            tableSlug: workerSubsidiarySlugOrWorkerAction, 
            token: cookies['token']
          };
  
          getPrivilegeForCurrentPage(privilegeObject).then(privilegesResult => {
            const privilegesToUpdate = transformPrivileges(privilegesResult, 'Edit');
            setPrivileges(privilegesToUpdate);
            
            if (workerSubsidiarySlugOrWorkerAction === 'jobs') {
              prepareTableColumnsWithPrivilegeAndInputType(workerSubsidiarySlugOrWorkerAction, privilegesToUpdate, 'Edit', workerSubsidiaryIdOrWorkerSubsidiaryAction, workerId, workerSubsidiaryIdOrWorkerSubsidiaryAction, null).then(result => {
                setPage('Edit');
                setTable(workerSubsidiarySlugOrWorkerAction);
                setLevel('workers');
                setTableColumns(result);
  
                getSubsidiaryTableFromSubsidiarySlug(workerSubsidiarySlugOrWorkerAction).then(result2 => {
                  setSubHeaderObj({
                    workerId: workerId,
                    table: tableSlug, 
                    page: 'Edit',
                    privileges: privilegesToUpdate,
                    workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                    workerSubsidiaryIdOrWorkerSubsidiaryAction: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                    jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                    jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                    problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction,
                    problemSubsidiaryIdOrProblemSubsidiaryAction: problemSubsidiaryIdOrProblemSubsidiaryAction
                  });
                  
                  setRightStubObj({
                    page: 'Edit',
                    tableColumns: result,
                    table: result2.data.table_name,
                    handleChange: handleChange,
                    handleCalendarChange: handleCalendarChange,
                    handleDropdownChange: handleDropdownChange,
                    handleFileChange: handleFileChange,
                    setRightStubObj: setRightStubObj,
                    toggleSort: toggleSort,
                    sort: sort,
                    deleteButton: deleteButton,
                    handleComboBoxChange: handleComboBoxChange,
                    handleFormSubmit: handleFormSubmit,
                    workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                    jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction
                  });
                  setLoading(false);
  
                  if (workerSubsidiarySlugOrWorkerAction === 'jobs') {
                    setNavigationStubObj({
                      level: 'workers',
                      workerId: workerId,
                      jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                    });
                    
                    setTopAndMiddleNavigationObj({
                      level: 'jobs',
                      workerId: workerId,
                      jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                      page: 'Edit',
                      subsidiary: result2.data.table_name,
                      workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction
                    });
                  } else {
                    setNavigationStubObj({
                      level: 'workers',
                      workerId: workerId
                    });
                    
                    setTopAndMiddleNavigationObj({
                      level: 'workers',
                      workerId: workerId,
                      page: 'Edit',
                      subsidiary: result2.data.table_name,
                      workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction
                    });
                  }
                });
              });
            } else {
              prepareTableColumnsWithPrivilegeAndInputType(workerSubsidiarySlugOrWorkerAction, privilegesToUpdate, 'Edit', workerSubsidiaryIdOrWorkerSubsidiaryAction, workerId, null, null).then(result => {
                setPage('Edit');
                setTable(workerSubsidiarySlugOrWorkerAction);
                setLevel('workers');
                setTableColumns(result);
  
                getSubsidiaryTableFromSubsidiarySlug(workerSubsidiarySlugOrWorkerAction).then(result2 => {
                  setSubHeaderObj({
                    workerId: workerId,
                    table: tableSlug, 
                    page: 'Edit',
                    privileges: privilegesToUpdate,
                    workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                    workerSubsidiaryIdOrWorkerSubsidiaryAction: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                    jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                    jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                    problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction,
                    problemSubsidiaryIdOrProblemSubsidiaryAction: problemSubsidiaryIdOrProblemSubsidiaryAction
                  });
                  
                  if (workerSubsidiarySlugOrWorkerAction === 'ordinary-attachments' || workerSubsidiarySlugOrWorkerAction === 'sensitive-attachments') {
                    getJobAndProblemByWorkerId(workerId).then(jobAndProblems => {
                      const jobIdsToProblems = {};
                      
                      jobAndProblems.data.problems.forEach((problem) => {
                        if (jobIdsToProblems[problem.job_id] === undefined) {
                          jobIdsToProblems[problem.job_id] = [];
                        }
  
                        jobIdsToProblems[problem.job_id].push(problem);
                      });
  
                      let employerNameId = '';
                      let chiefProblemId = '';
  
                      for (let i = 0; i < result.length; i += 1) {
                        if (result[i]['column_name'] === 'employer_name') {
                          employerNameId = i;
                          result[i]['dropdowns'] = [{dropdown_item: '-'}];
                          jobAndProblems.data.jobs.forEach(job => {
                            result[i]['dropdowns'].push({id: job.id, dropdown_item: job.employer_name});
                          });
                        } else if (result[i]['column_name'] === 'chief_problem') {
                          chiefProblemId = i;
                          result[i]['dropdowns'] = [{dropdown_item: '-'}];
                        } else if (result[i]['column_name'] === 'job_id') {
                          if (result[i]['value'] !== '') {
                            result[employerNameId]['job_id'] = result[i].value;
  
                            result[employerNameId]['value'] = result[employerNameId]['dropdowns'].filter((dropdown) => dropdown.id === result[i].value)[0]['dropdown_item'];
                          }
                        } else if (result[i]['column_name'] === 'problem_id') {
                          if (result[i]['value'] !== '') {
                            result[chiefProblemId]['problem_id'] = result[i].value;
                            if (result[employerNameId]['job_id'] !== '') {
                              jobAndProblems.data.problems.forEach((problem) => {
                                if (problem.job_id === result[employerNameId]['job_id']) {
                                  result[chiefProblemId]['dropdowns'].push({id: problem.id, dropdown_item: problem.chief_problem});
                                }
  
                                if (problem.job_id === result[employerNameId]['job_id']) {
                                  result[chiefProblemId]['value'] = problem.chief_problem;
                                }
                              });
                            }
                          } else {
                            if (result[employerNameId]['job_id'] !== '') {
                              jobAndProblems.data.problems.forEach((problem) => {
                                if (problem.job_id === result[employerNameId]['job_id']) {
                                  result[chiefProblemId]['dropdowns'].push({id: problem.id, dropdown_item: problem.chief_problem});
                                }
                              });
                            }
                          }
                        }
                      }
  
                      setRightStubObj({
                        page: 'Edit',
                        tableColumns: result,
                        table: result2.data.table_name,
                        handleChange: handleChange,
                        handleCalendarChange: handleCalendarChange,
                        handleDropdownChange: handleDropdownChange,
                        handleFileChange: handleFileChange,
                        handleFormSubmit: handleFormSubmit,
                        handleJobChange: handleJobChange,
                        workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                        workerSubsidiaryIdOrWorkerSubsidiaryAction: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                        jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                        setRightStubObj: setRightStubObj,
                        toggleSort: toggleSort,
                        jobIdsToProblems: jobIdsToProblems,
                        sort: sort,
                        deleteButton: deleteButton,
                        handleComboBoxChange: handleComboBoxChange,
                        updatedFacepic: updatedFacepic,
                        setUpdatedFacepic: setUpdatedFacepic,
                        updatedFacepicDataFormat: updatedFacepicDataFormat,
                        setUpdatedFacepicDataFormat: setUpdatedFacepicDataFormat
                      });
                      setLoading(false);
  
                      // setRightStubObj({
                      //   page: 'Edit',
                      //   tableColumns: result,
                      //   table: result2.data.table_name,
                      //   handleChange: handleChange,
                      //   handleCalendarChange: handleCalendarChange,
                      //   handleDropdownChange: handleDropdownChange,
                      //   handleJobChange: handleJobChange,
                      //   handleFileChange: handleFileChange,
                      //   setRightStubObj: setRightStubObj,
                      //   toggleSort: toggleSort,
                      //   sort: sort,
                      //   deleteButton: deleteButton,
                      //   handleComboBoxChange: handleComboBoxChange,
                      //   handleFormSubmit: handleFormSubmit,
                      //   workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                      //   jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                      //   jobIdsToProblems: jobIdsToProblems
                      // });
                    });
                  } else if (workerSubsidiarySlugOrWorkerAction === 'facepic-attachments' || workerSubsidiarySlugOrWorkerAction === 'pdpa-attachments') {
                    setRightStubObj({
                      page: 'Edit',
                      tableColumns: result,
                      table: result2.data.table_name,
                      handleChange: handleChange,
                      handleCalendarChange: handleCalendarChange,
                      handleDropdownChange: handleDropdownChange,
                      handleFileChange: handleFileChange,
                      handleFormSubmit: handleFormSubmit,
                      workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                      workerSubsidiaryIdOrWorkerSubsidiaryAction: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                      jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                      setRightStubObj: setRightStubObj,
                      toggleSort: toggleSort,
                      sort: sort,
                      deleteButton: deleteButton,
                      handleComboBoxChange: handleComboBoxChange,
                      updatedFacepic: updatedFacepic,
                      setUpdatedFacepic: setUpdatedFacepic,
                      updatedFacepicDataFormat: updatedFacepicDataFormat,
                      setUpdatedFacepicDataFormat: setUpdatedFacepicDataFormat
                    });
                    setLoading(false);
                  } else {
                    setRightStubObj({
                      page: 'Edit',
                      tableColumns: result,
                      table: result2.data.table_name,
                      handleChange: handleChange,
                      handleCalendarChange: handleCalendarChange,
                      handleDropdownChange: handleDropdownChange,
                      handleFileChange: handleFileChange,
                      setRightStubObj: setRightStubObj,
                      toggleSort: toggleSort,
                      sort: sort,
                      deleteButton: deleteButton,
                      handleComboBoxChange: handleComboBoxChange,
                      handleFormSubmit: handleFormSubmit,
                      workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                      jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction
                    }); 
                    setLoading(false);
                  }
  
                  setNavigationStubObj({
                    level: 'workers',
                    workerId: workerId
                  });
                  
                  setTopAndMiddleNavigationObj({
                    level: 'workers',
                    workerId: workerId,
                    page: 'Edit',
                    subsidiary: result2.data.table_name,
                    workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction
                  });
  
                  // if (workerSubsidiarySlugOrWorkerAction === 'jobs') {
                  //   setNavigationStubObj({
                  //     level: 'jobs',
                  //     workerId: workerId,
                  //     jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                  //   });
                    
                  //   setTopAndMiddleNavigationObj({
                  //     level: 'jobs',
                  //     workerId: workerId,
                  //     jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                  //     page: 'Edit',
                  //     subsidiary: result2.data.table_name,
                  //     workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction
                  //   });
                  // } else {
                  //   setNavigationStubObj({
                  //     level: 'workers',
                  //     workerId: workerId
                  //   });
                    
                  //   setTopAndMiddleNavigationObj({
                  //     level: 'workers',
                  //     workerId: workerId,
                  //     page: 'Edit',
                  //     subsidiary: result2.data.table_name,
                  //     workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction
                  //   });
                  // }
                });
              });
            }
  
          });
        }
      } else {
        // list job subsidiaries
        const privilegeObject = {
          tableSlug: jobSubsidiarySlugOrWorkerSubsidiaryAction, 
          token: cookies['token']
        };

        getPrivilegeForCurrentPage(privilegeObject).then(privilegesResult => {
          const privilegesToUpdate = transformPrivileges(privilegesResult, 'List');
          setPrivileges(privilegesToUpdate);

          prepareListWithPrivileges(workerId, workerSubsidiaryIdOrWorkerSubsidiaryAction, null, jobSubsidiarySlugOrWorkerSubsidiaryAction, null, cookies['token']).then(result => {
            setPage('List');
            setTable(jobSubsidiarySlugOrWorkerSubsidiaryAction);
            setLevel('jobs');

            setNavigationStubObj({
              level: 'jobs',
              workerId: workerId,
              jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
            });

            const {dateOrDateTimeColumns, stringColumns, columns, data, referenceData} = result;
            setTableColumns(data);

            const labelColumns = columns.map((column) => column.label_name);
            const dashedLabelColumns = columns.map((column) => column.column_name);

            getSubsidiaryTableFromSubsidiarySlug(jobSubsidiarySlugOrWorkerSubsidiaryAction).then(result => {
              setSubHeaderObj({
                workerId: workerId,
                table: tableSlug, 
                page: 'List',
                privileges: privilegesToUpdate,
                workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                workerSubsidiaryIdOrWorkerSubsidiaryAction: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction,
                problemSubsidiaryIdOrProblemSubsidiaryAction: problemSubsidiaryIdOrProblemSubsidiaryAction,
                subsidiary: result.data.table_name
              });

              const records = [...data.data];

              data.data = [];

              for (let i = 0; i < records.length; i += 10) {
                data.data[i/10] = records.slice(i, i+10);
              }
              
              setRightStubObj({
                page: 'List',
                dateOrDateTimeColumns: dateOrDateTimeColumns,
                stringColumns: stringColumns,
                tableColumns: data.data,
                noOfRecords: records.length,
                labelColumns: labelColumns,
                dashedLabelColumns: dashedLabelColumns,
                workerId: workerId,
                jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                table: jobSubsidiarySlugOrWorkerSubsidiaryAction.replace(/-/g, ' ').toUpperCase(),
                workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                handleChange: handleChange,
                handleCalendarChange: handleCalendarChange,
                handleDropdownChange: handleDropdownChange,
                handleFileChange: handleFileChange,
                referenceData: referenceData,
                handleOnChangeCheckbox: handleOnChangeCheckbox,
                setRightStubObj: setRightStubObj,
                toggleSort: toggleSort,
                toggleSort2: toggleSort2,
                toggleSort3: toggleSort3,
                sort: sort,
                handleFormSubmit: handleFormSubmit,
                subsidiary: result.data.table_name,
                objects: data.data,
                objectIdsSelected: new Array(data.data.length).fill(false),
                objectsSelected: [],
              });
              setLoading(false);

              setTopAndMiddleNavigationObj({
                level: 'jobs',
                workerId: workerId,
                jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                page: 'List',
                subsidiary: result.data.table_name,
                workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction
              });
            });
          });
        });
      }
    } else if (workerSubsidiarySlugOrWorkerAction !== undefined && 
      workerSubsidiaryIdOrWorkerSubsidiaryAction !== undefined && 
      jobSubsidiarySlugOrWorkerSubsidiaryAction !== undefined && 
      jobSubsidiaryIdOrJobSubsidiaryAction !== undefined &&
      problemSubsidiarySlugOrJobSubsidiaryAction === undefined
    ) {
      // job subsidiary action
      if (jobSubsidiaryIdOrJobSubsidiaryAction === 'add') {
        // add a subsidiary 
        const privilegeObject = {
          tableSlug: jobSubsidiarySlugOrWorkerSubsidiaryAction, 
          token: cookies['token']
        };

        getPrivilegeForCurrentPage(privilegeObject).then(privilegesResult => {
          const privilegesToUpdate = transformPrivileges(privilegesResult, 'Add');
          setPrivileges(privilegesToUpdate);

          prepareTableColumnsWithPrivilegeAndInputType(jobSubsidiarySlugOrWorkerSubsidiaryAction, privilegesToUpdate, 'Add', null, workerId, workerSubsidiaryIdOrWorkerSubsidiaryAction, null, cookies['userId']).then(result => {
            setPage('Add');
            setTable(jobSubsidiarySlugOrWorkerSubsidiaryAction);
            setLevel('jobs');
            setTableColumns(result);

            setNavigationStubObj({
              level: 'jobs',
              workerId: workerId,
              jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
            });

            getSubsidiaryTableFromSubsidiarySlug(jobSubsidiarySlugOrWorkerSubsidiaryAction).then(result2 => {
              setSubHeaderObj({
                workerId: workerId,
                table: tableSlug, 
                page: 'Add',
                privileges: privilegesToUpdate,
                workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                workerSubsidiaryIdOrWorkerSubsidiaryAction: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction,
                problemSubsidiaryIdOrProblemSubsidiaryAction: problemSubsidiaryIdOrProblemSubsidiaryAction
              });

              if (jobSubsidiarySlugOrWorkerSubsidiaryAction === 'ordinary-attachments' || jobSubsidiarySlugOrWorkerSubsidiaryAction === 'sensitive-attachments') {
                getJobAndProblemByWorkerId(workerId).then(jobAndProblems => {
                  const jobIdsToProblems = {};

                  jobAndProblems.data.problems.forEach((problem) => {
                    if (jobIdsToProblems[problem.job_id] === undefined) {
                      jobIdsToProblems[problem.job_id] = [];
                    }

                    jobIdsToProblems[problem.job_id].push(problem);
                  });

                  for (let i = 0; i < result.length; i += 1) {
                    if (result[i]['column_name'] === 'employer_name') {
                      result[i]['dropdowns'] = [{dropdown_item: '-'}];
                      jobAndProblems.data.jobs.forEach(job => {
                        if (job.id === parseInt(workerSubsidiaryIdOrWorkerSubsidiaryAction)) {
                          result[i]['value'] = job.employer_name;
                          result[i]['job_id'] = job.id;
                        }
                        result[i]['dropdowns'].push({id: job.id, dropdown_item: job.employer_name});
                      });
                    } else if (result[i]['column_name'] === 'chief_problem') {
                      result[i]['dropdowns'] = [{dropdown_item: '-'}];

                      jobAndProblems.data.problems.forEach((problem) => {
                        if (problem.job_id === parseInt(workerSubsidiaryIdOrWorkerSubsidiaryAction)) {
                          result[i]['dropdowns'].push({id: problem.id, dropdown_item: problem.chief_problem});
                        }
                      });
                    } 
                  }
                  
                  setRightStubObj({
                    page: 'Add',
                    tableColumns: result,
                    table: result2.data.table_name,
                    handleChange: handleChange,
                    handleCalendarChange: handleCalendarChange,
                    handleDropdownChange: handleDropdownChange,
                    handleJobChange: handleJobChange,
                    handleFileChange: handleFileChange,
                    handleFormSubmit: handleFormSubmit,
                    workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                    workerSubsidiaryIdOrWorkerSubsidiaryAction: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                    jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                    jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                    jobIdsToProblems: jobIdsToProblems,
                    setRightStubObj: setRightStubObj,
                    handleComboBoxChange: handleComboBoxChange,
                    deleteButton: deleteButton,
                    toggleSort: toggleSort,
                    sort: sort
                  });
                  setLoading(false);
                });
              } else if (jobSubsidiarySlugOrWorkerSubsidiaryAction === 'facepic-attachments' || jobSubsidiarySlugOrWorkerSubsidiaryAction === 'pdpa-attachments') {
                setRightStubObj({
                  page: 'Add',
                  tableColumns: result,
                  table: result2.data.table_name,
                  handleChange: handleChange,
                  handleCalendarChange: handleCalendarChange,
                  handleDropdownChange: handleDropdownChange,
                  handleFileChange: handleFileChange,
                  handleFormSubmit: handleFormSubmit,
                  workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                  workerSubsidiaryIdOrWorkerSubsidiaryAction: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                  jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                  jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                  setRightStubObj: setRightStubObj,
                  handleComboBoxChange: handleComboBoxChange,
                  deleteButton: deleteButton,
                  toggleSort: toggleSort,
                  sort: sort
                });
                setLoading(false);
              } else {
                if (jobSubsidiarySlugOrWorkerSubsidiaryAction === 'problems') {
                  getLargestSequence('problems', workerId).then(result22 => {
                    let sequence;
                    if (result22.data.length === 0) {
                      sequence = 1;
                    } else {
                      sequence = result22.data[0]['problem_sequence'] + 1;
                    }

                    for (let i = 0; i < result.length; i += 1) {
                      if (result[i].column_name === 'problem_sequence') {
                        result[i].value = sequence;
                        break;
                      }
                    }

                    setRightStubObj({
                      page: 'Add',
                      tableColumns: result,
                      table: result2.data.table_name,
                      handleChange: handleChange,
                      handleCalendarChange: handleCalendarChange,
                      handleDropdownChange: handleDropdownChange,
                      handleFileChange: handleFileChange,
                      changeBenefitClass: changeBenefitClass,
                      setRightStubObj: setRightStubObj,
                      toggleSort: toggleSort,
                      sort: sort,
                      handleComboBoxChange: handleComboBoxChange,
                      deleteButton: deleteButton,
                      handleFormSubmit: handleFormSubmit,
                      workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                      jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction
                    });
                    setLoading(false);
                  });
                } else {
                  let beneItemReferences;
  
                  if (jobSubsidiarySlugOrWorkerSubsidiaryAction === 'benefits') {
                    for (let i = 0; i < result.length; i += 1) {
                      if (result[i]['column_name'] === 'bene_item') {
                        beneItemReferences = [...result[i]['dropdowns']];
                        result[i]['dropdowns'].splice(1);
                        break;
                      }
                    }
                  }
  
                  setRightStubObj({
                    page: 'Add',
                    tableColumns: result,
                    beneItemReferences: beneItemReferences,
                    table: result2.data.table_name,
                    handleChange: handleChange,
                    handleCalendarChange: handleCalendarChange,
                    handleDropdownChange: handleDropdownChange,
                    handleFileChange: handleFileChange,
                    changeBenefitClass: changeBenefitClass,
                    setRightStubObj: setRightStubObj,
                    toggleSort: toggleSort,
                    sort: sort,
                    handleComboBoxChange: handleComboBoxChange,
                    deleteButton: deleteButton,
                    handleFormSubmit: handleFormSubmit,
                    workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                    jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction
                  });
                  setLoading(false);
                }

              }

              if (jobSubsidiarySlugOrWorkerSubsidiaryAction === 'problems') {
                setTopAndMiddleNavigationObj({
                  level: 'problems',
                  workerId: workerId,
                  jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                  page: 'Add',
                  subsidiary: result2.data.table_name,
                  workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                  jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                  problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction
                });
              } else {
                setTopAndMiddleNavigationObj({
                  level: 'jobs',
                  workerId: workerId,
                  jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                  page: 'Add',
                  subsidiary: result2.data.table_name,
                  workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                  jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction
                });
              }
            });
          });
        });
      } else {
        // view job subsidiaryId
        if (jobSubsidiarySlugOrWorkerSubsidiaryAction === 'sensitive-attachments') {
          checkCurrentUserCanViewSensitiveAttachment(jobSubsidiaryIdOrJobSubsidiaryAction, cookies['token'])
          .then(result => {
            if (result.data === '' || result.data.length === 0) {
              navigate(`/workers/${workerId}/${workerSubsidiarySlugOrWorkerAction}/${workerSubsidiaryIdOrWorkerSubsidiaryAction}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}`);
            } else {
              const privilegeObject = {
                tableSlug: jobSubsidiarySlugOrWorkerSubsidiaryAction, 
                token: cookies['token']
              };

              getPrivilegeForCurrentPage(privilegeObject).then(privilegesResult => {
                const privilegesToUpdate = transformPrivileges(privilegesResult, 'View');
                setPrivileges(privilegesToUpdate);

                prepareTableColumnsWithPrivileges(jobSubsidiarySlugOrWorkerSubsidiaryAction, privilegesToUpdate, 'View', jobSubsidiaryIdOrJobSubsidiaryAction).then(result => {
                  if (!matchWorkerJobProblem(result, workerId, workerSubsidiarySlugOrWorkerAction, workerSubsidiaryIdOrWorkerSubsidiaryAction, jobSubsidiarySlugOrWorkerSubsidiaryAction, jobSubsidiaryIdOrJobSubsidiaryAction)) navigate('/');
                  setPage('View');
                  setTable(jobSubsidiarySlugOrWorkerSubsidiaryAction);
                  setLevel('workers');
                  setTableColumns(result);

                  getSubsidiaryTableFromSubsidiarySlug(jobSubsidiarySlugOrWorkerSubsidiaryAction).then(result2 => {
                    setSubHeaderObj({
                      workerId: workerId,
                      table: tableSlug, 
                      page: 'View',
                      privileges: privilegesToUpdate,
                      workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                      workerSubsidiaryIdOrWorkerSubsidiaryAction: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                      jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                      jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                      problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction,
                      problemSubsidiaryIdOrProblemSubsidiaryAction: problemSubsidiaryIdOrProblemSubsidiaryAction,
                      subsidiary: result2.data.table_name
                    });

                    setRightStubObj({
                      page: 'View',
                      tableColumns: result,
                      table: result2.data.table_name,
                      handleChange: handleChange,
                      handleCalendarChange: handleCalendarChange,
                      handleDropdownChange: handleDropdownChange,
                      handleFileChange: handleFileChange,
                      setRightStubObj: setRightStubObj,
                      toggleSort: toggleSort,
                      sort: sort,
                      handleFormSubmit: handleFormSubmit,
                      workerId: workerId,
                      workerSubsidiaryIdOrWorkerSubsidiaryAction: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                      workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                      jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                      jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction
                    });
                    setLoading(false);
                    
                    if (jobSubsidiarySlugOrWorkerSubsidiaryAction === 'problems') {
                      setTopAndMiddleNavigationObj({
                        level: 'problems',
                        workerId: workerId,
                        jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                        problemId: jobSubsidiaryIdOrJobSubsidiaryAction,
                        page: 'View',
                        subsidiary: result2.data.table_name,
                        workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                        jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction
                      });

                      setNavigationStubObj({
                        level: 'problems',
                        workerId: workerId,
                        jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                        problemId: jobSubsidiaryIdOrJobSubsidiaryAction
                      });
                    } else {
                      setTopAndMiddleNavigationObj({
                        level: 'jobs',
                        workerId: workerId,
                        jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                        page: 'View',
                        subsidiary: result2.data.table_name,
                        workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                        jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction
                      });

                      setNavigationStubObj({
                        level: 'jobs',
                        workerId: workerId,
                        jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                      });
                    }
                  });
                });
              });
            }
          })
          .catch(error => {
            console.log(error);
          })
        } else {
          const privilegeObject = {
            tableSlug: jobSubsidiarySlugOrWorkerSubsidiaryAction, 
            token: cookies['token']
          };

          getPrivilegeForCurrentPage(privilegeObject).then(privilegesResult => {
            const privilegesToUpdate = transformPrivileges(privilegesResult, 'View');
            setPrivileges(privilegesToUpdate);

            prepareTableColumnsWithPrivileges(jobSubsidiarySlugOrWorkerSubsidiaryAction, privilegesToUpdate, 'View', jobSubsidiaryIdOrJobSubsidiaryAction).then(result => {
              if (!matchWorkerJobProblem(result, workerId, workerSubsidiarySlugOrWorkerAction, workerSubsidiaryIdOrWorkerSubsidiaryAction, jobSubsidiarySlugOrWorkerSubsidiaryAction, jobSubsidiaryIdOrJobSubsidiaryAction)) navigate('/');
              setPage('View');
              setTable(jobSubsidiarySlugOrWorkerSubsidiaryAction);
              setLevel('workers');
              setTableColumns(result);

              getSubsidiaryTableFromSubsidiarySlug(jobSubsidiarySlugOrWorkerSubsidiaryAction).then(result2 => {
                setSubHeaderObj({
                  workerId: workerId,
                  table: tableSlug, 
                  page: 'View',
                  privileges: privilegesToUpdate,
                  workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                  workerSubsidiaryIdOrWorkerSubsidiaryAction: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                  jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                  jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                  problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction,
                  problemSubsidiaryIdOrProblemSubsidiaryAction: problemSubsidiaryIdOrProblemSubsidiaryAction,
                  subsidiary: result2.data.table_name
                });

                setRightStubObj({
                  page: 'View',
                  tableColumns: result,
                  table: result2.data.table_name,
                  handleChange: handleChange,
                  handleCalendarChange: handleCalendarChange,
                  handleDropdownChange: handleDropdownChange,
                  handleFileChange: handleFileChange,
                  setRightStubObj: setRightStubObj,
                  toggleSort: toggleSort,
                  sort: sort,
                  handleFormSubmit: handleFormSubmit,
                  workerId: workerId,
                  workerSubsidiaryIdOrWorkerSubsidiaryAction: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                  workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                  jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                  jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction
                });
                setLoading(false);

                if (jobSubsidiarySlugOrWorkerSubsidiaryAction === 'problems') {
                  setTopAndMiddleNavigationObj({
                    level: 'problems',
                    workerId: workerId,
                    jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                    problemId: jobSubsidiaryIdOrJobSubsidiaryAction,
                    page: 'View',
                    subsidiary: result2.data.table_name,
                    workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                    jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction
                  });

                  setNavigationStubObj({
                    level: 'problems',
                    workerId: workerId,
                    jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                    problemId: jobSubsidiaryIdOrJobSubsidiaryAction
                  });
                } else {
                  setTopAndMiddleNavigationObj({
                    level: 'jobs',
                    workerId: workerId,
                    jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                    page: 'View',
                    subsidiary: result2.data.table_name,
                    workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                    jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction
                  });

                  setNavigationStubObj({
                    level: 'jobs',
                    workerId: workerId,
                    jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                  });
                }
              });
            });
          });
        }
      }
    } else if (workerSubsidiarySlugOrWorkerAction !== undefined && 
      workerSubsidiaryIdOrWorkerSubsidiaryAction !== undefined && 
      jobSubsidiarySlugOrWorkerSubsidiaryAction !== undefined && 
      jobSubsidiaryIdOrJobSubsidiaryAction !== undefined &&
      problemSubsidiarySlugOrJobSubsidiaryAction !== undefined &&
      problemSubsidiaryIdOrProblemSubsidiaryAction === undefined
    ) {
      if (problemSubsidiarySlugOrJobSubsidiaryAction === 'edit') {
        if (jobSubsidiarySlugOrWorkerSubsidiaryAction === 'sensitive-attachments') {
          checkCurrentUserCanViewSensitiveAttachment(jobSubsidiaryIdOrJobSubsidiaryAction, cookies['token'])
          .then(result => {
            if (result.data === '' || result.data.length === 0) {
              navigate(`/workers/${workerId}/${workerSubsidiarySlugOrWorkerAction}/${workerSubsidiaryIdOrWorkerSubsidiaryAction}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}/${jobSubsidiaryIdOrJobSubsidiaryAction}`);
            } else {
              const privilegeObject = {
                tableSlug: jobSubsidiarySlugOrWorkerSubsidiaryAction, 
                token: cookies['token']
              };

              getPrivilegeForCurrentPage(privilegeObject).then(privilegesResult => {
                const privilegesToUpdate = transformPrivileges(privilegesResult, 'Edit');
                setPrivileges(privilegesToUpdate);

                if (jobSubsidiarySlugOrWorkerSubsidiaryAction === 'problems') {
                  prepareTableColumnsWithPrivilegeAndInputType(jobSubsidiarySlugOrWorkerSubsidiaryAction, privilegesToUpdate, 'Edit', jobSubsidiaryIdOrJobSubsidiaryAction, workerId, workerSubsidiaryIdOrWorkerSubsidiaryAction, jobSubsidiaryIdOrJobSubsidiaryAction).then(result => {
                    setPage('Edit');
                    setTable(jobSubsidiarySlugOrWorkerSubsidiaryAction);
                    setLevel('jobs');
                    setTableColumns(result);
        
                    setNavigationStubObj({
                      level: 'jobs',
                      workerId: workerId,
                      jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                      problemId: jobSubsidiaryIdOrJobSubsidiaryAction
                    });
        
                    getSubsidiaryTableFromSubsidiarySlug(jobSubsidiarySlugOrWorkerSubsidiaryAction).then(result2 => {
                      setSubHeaderObj({
                        workerId: workerId,
                        table: tableSlug, 
                        page: 'Edit',
                        privileges: privilegesToUpdate,
                        workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                        workerSubsidiaryIdOrWorkerSubsidiaryAction: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                        jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                        jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                        problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction,
                        problemSubsidiaryIdOrProblemSubsidiaryAction: problemSubsidiaryIdOrProblemSubsidiaryAction
                      });
                      
                      setRightStubObj({
                        page: 'Edit',
                        tableColumns: result,
                        table: result2.data.table_name,
                        handleChange: handleChange,
                        handleCalendarChange: handleCalendarChange,
                        handleDropdownChange: handleDropdownChange,
                        handleFileChange: handleFileChange,
                        handleComboBoxChange: handleComboBoxChange,
                        deleteButton: deleteButton,
                        setRightStubObj: setRightStubObj,
                        toggleSort: toggleSort,
                        sort: sort,
                        handleFormSubmit: handleFormSubmit,
                        workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                        jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                        problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction
                      });
                      setLoading(false);

                      if (jobSubsidiarySlugOrWorkerSubsidiaryAction === 'problems') {
                        setTopAndMiddleNavigationObj({
                          level: 'problems',
                          workerId: workerId,
                          jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                          page: 'Add',
                          subsidiary: result2.data.table_name,
                          workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                          jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                          problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction
                        });
                      } else {
                        setTopAndMiddleNavigationObj({
                          level: 'jobs',
                          workerId: workerId,
                          jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                          problemId: jobSubsidiaryIdOrJobSubsidiaryAction,
                          page: 'Edit',
                          subsidiary: result2.data.table_name,
                          workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                          jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                          problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction
                        });
                      }
                    });
                  });
                } else {
                  prepareTableColumnsWithPrivilegeAndInputType(jobSubsidiarySlugOrWorkerSubsidiaryAction, privilegesToUpdate, 'Edit', jobSubsidiaryIdOrJobSubsidiaryAction, workerId, workerSubsidiaryIdOrWorkerSubsidiaryAction, null).then(result => {
                    setPage('Edit');
                    setTable(jobSubsidiarySlugOrWorkerSubsidiaryAction);
                    setLevel('jobs');
                    setTableColumns(result);
        
                    setNavigationStubObj({
                      level: 'jobs',
                      workerId: workerId,
                      jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                      problemId: jobSubsidiaryIdOrJobSubsidiaryAction
                    });
        
                    getSubsidiaryTableFromSubsidiarySlug(jobSubsidiarySlugOrWorkerSubsidiaryAction).then(result2 => {
                      setSubHeaderObj({
                        workerId: workerId,
                        table: tableSlug, 
                        page: 'Edit',
                        privileges: privilegesToUpdate,
                        workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                        workerSubsidiaryIdOrWorkerSubsidiaryAction: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                        jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                        jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                        problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction,
                        problemSubsidiaryIdOrProblemSubsidiaryAction: problemSubsidiaryIdOrProblemSubsidiaryAction
                      });
                      
                      if (jobSubsidiarySlugOrWorkerSubsidiaryAction === 'ordinary-attachments' || jobSubsidiarySlugOrWorkerSubsidiaryAction === 'sensitive-attachments') {
                        getJobAndProblemByWorkerId(workerId).then(jobAndProblems => {
                          const jobIdsToProblems = {};
                          
                          jobAndProblems.data.problems.forEach((problem) => {
                            if (jobIdsToProblems[problem.job_id] === undefined) {
                              jobIdsToProblems[problem.job_id] = [];
                            }

                            jobIdsToProblems[problem.job_id].push(problem);
                          });

                          let employerNameId = '';
                          let chiefProblemId = '';

                          for (let i = 0; i < result.length; i += 1) {
                            if (result[i]['column_name'] === 'employer_name') {
                              employerNameId = i;
                              result[i]['dropdowns'] = [{dropdown_item: '-'}];
                              jobAndProblems.data.jobs.forEach(job => {
                                result[i]['dropdowns'].push({id: job.id, dropdown_item: job.employer_name});
                              });
                            } else if (result[i]['column_name'] === 'chief_problem') {
                              chiefProblemId = i;
                              result[i]['dropdowns'] = [{dropdown_item: '-'}];
                            } else if (result[i]['column_name'] === 'job_id') {
                              if (result[i]['value'] !== '') {
                                result[employerNameId]['job_id'] = result[i].value;

                                result[employerNameId]['value'] = result[employerNameId]['dropdowns'].filter((dropdown) => dropdown.id === result[i].value)[0]['dropdown_item'];
                              }
                            } else if (result[i]['column_name'] === 'problem_id') {
                              if (result[i]['value'] !== '') {
                                result[chiefProblemId]['problem_id'] = result[i].value;
                                // result[chiefProblemId]['value'] = result[i]['dropdowns'].filter((dropdown) => dropdown.id === result[i].value)[0]['dropdown_item'];
                                if (result[employerNameId]['job_id'] !== '') {
                                  jobAndProblems.data.problems.forEach((problem) => {
                                    if (problem.job_id === result[employerNameId]['job_id']) {
                                      result[chiefProblemId]['dropdowns'].push({id: problem.id, dropdown_item: problem.chief_problem});
                                    }

                                    if (problem.job_id === result[employerNameId]['job_id']) {
                                      result[chiefProblemId]['value'] = problem.chief_problem;
                                    }
                                  });
                                }
                              } else {
                                if (result[employerNameId]['job_id'] !== '') {
                                  jobAndProblems.data.problems.forEach((problem) => {
                                    if (problem.job_id === result[employerNameId]['job_id']) {
                                      result[chiefProblemId]['dropdowns'].push({id: problem.id, dropdown_item: problem.chief_problem});
                                    }
                                  });
                                }
                              }
                            }
                          }

                          setRightStubObj({
                            page: 'Edit',
                            tableColumns: result,
                            table: result2.data.table_name,
                            handleChange: handleChange,
                            handleCalendarChange: handleCalendarChange,
                            handleDropdownChange: handleDropdownChange,
                            handleFileChange: handleFileChange,
                            handleFormSubmit: handleFormSubmit,
                            handleJobChange: handleJobChange,
                            changeBenefitClass: changeBenefitClass,
                            workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                            workerSubsidiaryIdOrWorkerSubsidiaryAction: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                            jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                            jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                            problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction,
                            setRightStubObj: setRightStubObj,
                            toggleSort: toggleSort,
                            jobIdsToProblems: jobIdsToProblems,
                            sort: sort,
                            deleteButton: deleteButton,
                            handleComboBoxChange: handleComboBoxChange,
                            updatedFacepic: updatedFacepic,
                            setUpdatedFacepic: setUpdatedFacepic,
                            updatedFacepicDataFormat: updatedFacepicDataFormat,
                            setUpdatedFacepicDataFormat: setUpdatedFacepicDataFormat
                          });
                          setLoading(false);

                          // setRightStubObj({
                          //   page: 'Edit',
                          //   tableColumns: result,
                          //   table: result2.data.table_name,
                          //   handleChange: handleChange,
                          //   handleCalendarChange: handleCalendarChange,
                          //   handleDropdownChange: handleDropdownChange,
                          //   handleJobChange: handleJobChange,
                          //   handleFileChange: handleFileChange,
                          //   setRightStubObj: setRightStubObj,
                          //   toggleSort: toggleSort,
                          //   sort: sort,
                          //   deleteButton: deleteButton,
                          //   handleComboBoxChange: handleComboBoxChange,
                          //   handleFormSubmit: handleFormSubmit,
                          //   workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                          //   jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                          //   jobIdsToProblems: jobIdsToProblems
                          // });
                        });
                      } else {
                        let beneItemReferences;

                        let beneClass = '';

                        if (jobSubsidiarySlugOrWorkerSubsidiaryAction === 'benefits') {
                          for (let i = 0; i < result.length; i += 1) {
                            if (result[i]['column_name'] === 'bene_class') {
                              beneClass = result[i]['value'];
                            } else if (result[i]['column_name'] === 'bene_item') {
                              beneItemReferences = [...result[i]['dropdowns']];
                              result[i]['dropdowns'] = [];
                              result[i]['dropdowns'].push({dropdown_item: '-'});
                              result[i]['dropdowns'] = result[i]['dropdowns'].concat(beneItemReferences.filter(row => row.dropdown_class === beneClass));
                              break;
                            }
                          }
                        }

                        setRightStubObj({
                          page: 'Edit',
                          tableColumns: result,
                          table: result2.data.table_name,
                          handleChange: handleChange,
                          handleCalendarChange: handleCalendarChange,
                          handleDropdownChange: handleDropdownChange,
                          handleFileChange: handleFileChange,
                          setRightStubObj: setRightStubObj,
                          toggleSort: toggleSort,
                          beneItemReferences: beneItemReferences,
                          changeBenefitClass: changeBenefitClass,
                          sort: sort,
                          deleteButton: deleteButton,
                          handleComboBoxChange: handleComboBoxChange,
                          handleFormSubmit: handleFormSubmit,
                          workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                          jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                          problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction
                        });
                        setLoading(false);
                      }
        
                      setTopAndMiddleNavigationObj({
                        level: 'jobs',
                        workerId: workerId,
                        jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                        problemId: jobSubsidiaryIdOrJobSubsidiaryAction,
                        page: 'Edit',
                        subsidiary: result2.data.table_name,
                        workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                        jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                        problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction
                      });
                    });
                  });
                }

              });
            }
          })
          .catch(error => {
            console.log(error);
          })
        } else {
          const privilegeObject = {
            tableSlug: jobSubsidiarySlugOrWorkerSubsidiaryAction, 
            token: cookies['token']
          };

          getPrivilegeForCurrentPage(privilegeObject).then(privilegesResult => {
            const privilegesToUpdate = transformPrivileges(privilegesResult, 'Edit');
            setPrivileges(privilegesToUpdate);

            if (jobSubsidiarySlugOrWorkerSubsidiaryAction === 'problems') {
              prepareTableColumnsWithPrivilegeAndInputType(jobSubsidiarySlugOrWorkerSubsidiaryAction, privilegesToUpdate, 'Edit', jobSubsidiaryIdOrJobSubsidiaryAction, workerId, workerSubsidiaryIdOrWorkerSubsidiaryAction, jobSubsidiaryIdOrJobSubsidiaryAction).then(result => {
                setPage('Edit');
                setTable(jobSubsidiarySlugOrWorkerSubsidiaryAction);
                setLevel('jobs');
                setTableColumns(result);
    
                setNavigationStubObj({
                  level: 'jobs',
                  workerId: workerId,
                  jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                  problemId: jobSubsidiaryIdOrJobSubsidiaryAction
                });
    
                getSubsidiaryTableFromSubsidiarySlug(jobSubsidiarySlugOrWorkerSubsidiaryAction).then(result2 => {
                  setSubHeaderObj({
                    workerId: workerId,
                    table: tableSlug, 
                    page: 'Edit',
                    privileges: privilegesToUpdate,
                    workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                    workerSubsidiaryIdOrWorkerSubsidiaryAction: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                    jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                    jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                    problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction,
                    problemSubsidiaryIdOrProblemSubsidiaryAction: problemSubsidiaryIdOrProblemSubsidiaryAction
                  });
                  
                  setRightStubObj({
                    page: 'Edit',
                    tableColumns: result,
                    table: result2.data.table_name,
                    handleChange: handleChange,
                    handleCalendarChange: handleCalendarChange,
                    handleDropdownChange: handleDropdownChange,
                    handleFileChange: handleFileChange,
                    handleComboBoxChange: handleComboBoxChange,
                    deleteButton: deleteButton,
                    setRightStubObj: setRightStubObj,
                    toggleSort: toggleSort,
                    sort: sort,
                    handleFormSubmit: handleFormSubmit,
                    workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                    jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                    problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction
                  });
                  setLoading(false);

                  if (jobSubsidiarySlugOrWorkerSubsidiaryAction === 'problems') {
                    setTopAndMiddleNavigationObj({
                      level: 'problems',
                      workerId: workerId,
                      jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                      page: 'Add',
                      subsidiary: result2.data.table_name,
                      workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                      jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                      problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction
                    });
                  } else {
                    setTopAndMiddleNavigationObj({
                      level: 'jobs',
                      workerId: workerId,
                      jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                      problemId: jobSubsidiaryIdOrJobSubsidiaryAction,
                      page: 'Edit',
                      subsidiary: result2.data.table_name,
                      workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                      jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                      problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction
                    });
                  }
                });
              });
            } else {
              prepareTableColumnsWithPrivilegeAndInputType(jobSubsidiarySlugOrWorkerSubsidiaryAction, privilegesToUpdate, 'Edit', jobSubsidiaryIdOrJobSubsidiaryAction, workerId, workerSubsidiaryIdOrWorkerSubsidiaryAction, null).then(result => {
                setPage('Edit');
                setTable(jobSubsidiarySlugOrWorkerSubsidiaryAction);
                setLevel('jobs');
                setTableColumns(result);
    
                setNavigationStubObj({
                  level: 'jobs',
                  workerId: workerId,
                  jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                  problemId: jobSubsidiaryIdOrJobSubsidiaryAction
                });
    
                getSubsidiaryTableFromSubsidiarySlug(jobSubsidiarySlugOrWorkerSubsidiaryAction).then(result2 => {
                  setSubHeaderObj({
                    workerId: workerId,
                    table: tableSlug, 
                    page: 'Edit',
                    privileges: privilegesToUpdate,
                    workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                    workerSubsidiaryIdOrWorkerSubsidiaryAction: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                    jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                    jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                    problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction,
                    problemSubsidiaryIdOrProblemSubsidiaryAction: problemSubsidiaryIdOrProblemSubsidiaryAction
                  });
                  
                  if (jobSubsidiarySlugOrWorkerSubsidiaryAction === 'ordinary-attachments' || jobSubsidiarySlugOrWorkerSubsidiaryAction === 'sensitive-attachments') {
                    getJobAndProblemByWorkerId(workerId).then(jobAndProblems => {
                      const jobIdsToProblems = {};
                      
                      jobAndProblems.data.problems.forEach((problem) => {
                        if (jobIdsToProblems[problem.job_id] === undefined) {
                          jobIdsToProblems[problem.job_id] = [];
                        }

                        jobIdsToProblems[problem.job_id].push(problem);
                      });

                      let employerNameId = '';
                      let chiefProblemId = '';

                      for (let i = 0; i < result.length; i += 1) {
                        if (result[i]['column_name'] === 'employer_name') {
                          employerNameId = i;
                          result[i]['dropdowns'] = [{dropdown_item: '-'}];
                          jobAndProblems.data.jobs.forEach(job => {
                            result[i]['dropdowns'].push({id: job.id, dropdown_item: job.employer_name});
                          });
                        } else if (result[i]['column_name'] === 'chief_problem') {
                          chiefProblemId = i;
                          result[i]['dropdowns'] = [{dropdown_item: '-'}];
                        } else if (result[i]['column_name'] === 'job_id') {
                          if (result[i]['value'] !== '') {
                            result[employerNameId]['job_id'] = result[i].value;

                            result[employerNameId]['value'] = result[employerNameId]['dropdowns'].filter((dropdown) => dropdown.id === result[i].value)[0]['dropdown_item'];
                          }
                        } else if (result[i]['column_name'] === 'problem_id') {
                          if (result[i]['value'] !== '') {
                            result[chiefProblemId]['problem_id'] = result[i].value;
                            // result[chiefProblemId]['value'] = result[i]['dropdowns'].filter((dropdown) => dropdown.id === result[i].value)[0]['dropdown_item'];
                            if (result[employerNameId]['job_id'] !== '') {
                              jobAndProblems.data.problems.forEach((problem) => {
                                if (problem.job_id === result[employerNameId]['job_id']) {
                                  result[chiefProblemId]['dropdowns'].push({id: problem.id, dropdown_item: problem.chief_problem});
                                }

                                if (problem.job_id === result[employerNameId]['job_id']) {
                                  result[chiefProblemId]['value'] = problem.chief_problem;
                                }
                              });
                            }
                          } else {
                            if (result[employerNameId]['job_id'] !== '') {
                              jobAndProblems.data.problems.forEach((problem) => {
                                if (problem.job_id === result[employerNameId]['job_id']) {
                                  result[chiefProblemId]['dropdowns'].push({id: problem.id, dropdown_item: problem.chief_problem});
                                }
                              });
                            }
                          }
                        }
                      }

                      setRightStubObj({
                        page: 'Edit',
                        tableColumns: result,
                        table: result2.data.table_name,
                        handleChange: handleChange,
                        handleCalendarChange: handleCalendarChange,
                        handleDropdownChange: handleDropdownChange,
                        handleFileChange: handleFileChange,
                        handleFormSubmit: handleFormSubmit,
                        handleJobChange: handleJobChange,
                        changeBenefitClass: changeBenefitClass,
                        workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                        workerSubsidiaryIdOrWorkerSubsidiaryAction: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                        jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                        jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                        problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction,
                        setRightStubObj: setRightStubObj,
                        toggleSort: toggleSort,
                        jobIdsToProblems: jobIdsToProblems,
                        sort: sort,
                        deleteButton: deleteButton,
                        handleComboBoxChange: handleComboBoxChange,
                        updatedFacepic: updatedFacepic,
                        setUpdatedFacepic: setUpdatedFacepic,
                        updatedFacepicDataFormat: updatedFacepicDataFormat,
                        setUpdatedFacepicDataFormat: setUpdatedFacepicDataFormat
                      });
                      setLoading(false);

                      // setRightStubObj({
                      //   page: 'Edit',
                      //   tableColumns: result,
                      //   table: result2.data.table_name,
                      //   handleChange: handleChange,
                      //   handleCalendarChange: handleCalendarChange,
                      //   handleDropdownChange: handleDropdownChange,
                      //   handleJobChange: handleJobChange,
                      //   handleFileChange: handleFileChange,
                      //   setRightStubObj: setRightStubObj,
                      //   toggleSort: toggleSort,
                      //   sort: sort,
                      //   deleteButton: deleteButton,
                      //   handleComboBoxChange: handleComboBoxChange,
                      //   handleFormSubmit: handleFormSubmit,
                      //   workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                      //   jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                      //   jobIdsToProblems: jobIdsToProblems
                      // });
                    });
                  } else {
                    let beneItemReferences;

                    let beneClass = '';

                    if (jobSubsidiarySlugOrWorkerSubsidiaryAction === 'benefits') {
                      for (let i = 0; i < result.length; i += 1) {
                        if (result[i]['column_name'] === 'bene_class') {
                          beneClass = result[i]['value'];
                        } else if (result[i]['column_name'] === 'bene_item') {
                          beneItemReferences = [...result[i]['dropdowns']];
                          result[i]['dropdowns'] = [];
                          result[i]['dropdowns'].push({dropdown_item: '-'});
                          result[i]['dropdowns'] = result[i]['dropdowns'].concat(beneItemReferences.filter(row => row.dropdown_class === beneClass));
                          break;
                        }
                      }
                    }

                    setRightStubObj({
                      page: 'Edit',
                      tableColumns: result,
                      table: result2.data.table_name,
                      handleChange: handleChange,
                      handleCalendarChange: handleCalendarChange,
                      handleDropdownChange: handleDropdownChange,
                      handleFileChange: handleFileChange,
                      setRightStubObj: setRightStubObj,
                      toggleSort: toggleSort,
                      beneItemReferences: beneItemReferences,
                      changeBenefitClass: changeBenefitClass,
                      sort: sort,
                      deleteButton: deleteButton,
                      handleComboBoxChange: handleComboBoxChange,
                      handleFormSubmit: handleFormSubmit,
                      workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                      jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                      problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction
                    });
                    setLoading(false);
                  }
    
                  setTopAndMiddleNavigationObj({
                    level: 'jobs',
                    workerId: workerId,
                    jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                    problemId: jobSubsidiaryIdOrJobSubsidiaryAction,
                    page: 'Edit',
                    subsidiary: result2.data.table_name,
                    workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                    jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                    problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction
                  });
                });
              });
            }

          });
        }
      } else {
        // list problem subsidiaries 
        const privilegeObject = {
          tableSlug: problemSubsidiarySlugOrJobSubsidiaryAction, 
          token: cookies['token']
        };

        getPrivilegeForCurrentPage(privilegeObject).then(privilegesResult => {
          const privilegesToUpdate = transformPrivileges(privilegesResult, 'List');
          setPrivileges(privilegesToUpdate);

          prepareListWithPrivileges(workerId, workerSubsidiaryIdOrWorkerSubsidiaryAction, jobSubsidiaryIdOrJobSubsidiaryAction, problemSubsidiarySlugOrJobSubsidiaryAction, null, cookies['token']).then(result => {
            setPage('List');
            setTable(problemSubsidiarySlugOrJobSubsidiaryAction);
            setLevel('problems');

            setNavigationStubObj({
              level: 'problems',
              workerId: workerId,
              jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
              problemId: jobSubsidiaryIdOrJobSubsidiaryAction,
            });

            const {dateOrDateTimeColumns, stringColumns, columns, data, referenceData} = result;
            setTableColumns(data);

            const labelColumns = columns.map((column) => column.label_name);
            const dashedLabelColumns = columns.map((column) => column.column_name);

            getSubsidiaryTableFromSubsidiarySlug(problemSubsidiarySlugOrJobSubsidiaryAction).then(result => {
              setSubHeaderObj({
                workerId: workerId,
                table: tableSlug, 
                page: 'List',
                privileges: privilegesToUpdate,
                workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                workerSubsidiaryIdOrWorkerSubsidiaryAction: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction,
                problemSubsidiaryIdOrProblemSubsidiaryAction: problemSubsidiaryIdOrProblemSubsidiaryAction,
                subsidiary: result.data.table_name
              });

              const records = [...data.data];

              data.data = [];

              for (let i = 0; i < records.length; i += 10) {
                data.data[i/10] = records.slice(i, i+10);
              }
              
              setRightStubObj({
                page: 'List',
                dateOrDateTimeColumns: dateOrDateTimeColumns,
                stringColumns: stringColumns,
                tableColumns: data.data,
                noOfRecords: records.length,
                labelColumns: labelColumns,
                dashedLabelColumns: dashedLabelColumns,
                problemId: jobSubsidiaryIdOrJobSubsidiaryAction,
                workerId: workerId,
                jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                table: problemSubsidiarySlugOrJobSubsidiaryAction.replace(/-/g, ' ').toUpperCase(),
                workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction,
                handleChange: handleChange,
                handleCalendarChange: handleCalendarChange,
                handleDropdownChange: handleDropdownChange,
                handleOnChangeCheckbox: handleOnChangeCheckbox,
                handleFileChange: handleFileChange,
                referenceData: referenceData,
                setRightStubObj: setRightStubObj,
                toggleSort: toggleSort,
                toggleSort3: toggleSort3,
                sort: sort,
                handleFormSubmit: handleFormSubmit,
                subsidiary: result.data.table_name,
                objects: data.data,
                objectIdsSelected: new Array(data.data.length).fill(false),
                objectsSelected: []
              });
              setLoading(false);

              setTopAndMiddleNavigationObj({
                level: 'problems',
                workerId: workerId,
                jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                problemId: jobSubsidiaryIdOrJobSubsidiaryAction,
                page: 'List',
                subsidiary: result.data.table_name,
                workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction
              });
            });
          });
        });
      }
    } else if (workerSubsidiarySlugOrWorkerAction !== undefined && 
      workerSubsidiaryIdOrWorkerSubsidiaryAction !== undefined && 
      jobSubsidiarySlugOrWorkerSubsidiaryAction !== undefined && 
      jobSubsidiaryIdOrJobSubsidiaryAction !== undefined &&
      problemSubsidiarySlugOrJobSubsidiaryAction !== undefined &&
      problemSubsidiaryIdOrProblemSubsidiaryAction !== undefined &&
      problemSubsidiaryAction === undefined
    ) {
      // problem subsidiary action
      if (problemSubsidiaryIdOrProblemSubsidiaryAction === 'add') {
        // add a subsidiary 
        const privilegeObject = {
          tableSlug: problemSubsidiarySlugOrJobSubsidiaryAction, 
          token: cookies['token']
        };

        getPrivilegeForCurrentPage(privilegeObject).then(privilegesResult => {
          const privilegesToUpdate = transformPrivileges(privilegesResult, 'Add');
          setPrivileges(privilegesToUpdate);

          prepareTableColumnsWithPrivilegeAndInputType(problemSubsidiarySlugOrJobSubsidiaryAction, privilegesToUpdate, 'Add', null, workerId, workerSubsidiaryIdOrWorkerSubsidiaryAction, jobSubsidiaryIdOrJobSubsidiaryAction, cookies['userId']).then(result => {
            setPage('Add');
            setTable(problemSubsidiarySlugOrJobSubsidiaryAction);
            setLevel('problems');
            setTableColumns(result);

            setNavigationStubObj({
              level: 'problems',
              workerId: workerId,
              jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
              problemId: jobSubsidiaryIdOrJobSubsidiaryAction,
            });

            getSubsidiaryTableFromSubsidiarySlug(problemSubsidiarySlugOrJobSubsidiaryAction).then(result2 => {
              setSubHeaderObj({
                workerId: workerId,
                table: tableSlug, 
                page: 'Add',
                privileges: privilegesToUpdate,
                workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                workerSubsidiaryIdOrWorkerSubsidiaryAction: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction,
                problemSubsidiaryIdOrProblemSubsidiaryAction: problemSubsidiaryIdOrProblemSubsidiaryAction
              });

              if (problemSubsidiarySlugOrJobSubsidiaryAction === 'ordinary-attachments' || problemSubsidiarySlugOrJobSubsidiaryAction === 'sensitive-attachments') {
                getJobAndProblemByWorkerId(workerId).then(jobAndProblems => {
                  const jobIdsToProblems = {};

                  jobAndProblems.data.problems.forEach((problem) => {
                    if (jobIdsToProblems[problem.job_id] === undefined) {
                      jobIdsToProblems[problem.job_id] = [];
                    }

                    jobIdsToProblems[problem.job_id].push(problem);
                  });

                  for (let i = 0; i < result.length; i += 1) {
                    if (result[i]['column_name'] === 'employer_name') {
                      result[i]['dropdowns'] = [{dropdown_item: '-'}];
                      jobAndProblems.data.jobs.forEach(job => {
                        if (job.id === parseInt(workerSubsidiaryIdOrWorkerSubsidiaryAction)) {
                          result[i]['value'] = job.employer_name;
                          result[i]['job_id'] = job.id;
                        }
                        result[i]['dropdowns'].push({id: job.id, dropdown_item: job.employer_name});
                      });
                    } else if (result[i]['column_name'] === 'chief_problem') {
                      result[i]['dropdowns'] = [{dropdown_item: '-'}];

                      jobAndProblems.data.problems.forEach((problem) => {
                        if (problem.job_id === parseInt(workerSubsidiaryIdOrWorkerSubsidiaryAction)) {
                          result[i]['dropdowns'].push({id: problem.id, dropdown_item: problem.chief_problem});
                        }

                        if (problem.id === parseInt(jobSubsidiaryIdOrJobSubsidiaryAction)) {
                          result[i]['value'] = problem.chief_problem;
                          result[i]['problem_id'] = problem.id;
                        }
                      });
                    } 
                  }

                  setRightStubObj({
                    page: 'Add',
                    tableColumns: result,
                    table: result2.data.table_name,
                    handleChange: handleChange,
                    handleCalendarChange: handleCalendarChange,
                    handleDropdownChange: handleDropdownChange,
                    handleJobChange: handleJobChange,
                    handleFileChange: handleFileChange,
                    handleFormSubmit: handleFormSubmit,
                    workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                    workerSubsidiaryIdOrWorkerSubsidiaryAction: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                    jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                    jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                    problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction,
                    problemSubsidiaryIdOrProblemSubsidiaryAction: problemSubsidiaryIdOrProblemSubsidiaryAction,
                    jobIdsToProblems: jobIdsToProblems,
                    setRightStubObj: setRightStubObj,
                    handleComboBoxChange: handleComboBoxChange,
                    deleteButton: deleteButton,
                    toggleSort: toggleSort,
                    sort: sort
                  });
                  setLoading(false);
                });
              } else if (problemSubsidiarySlugOrJobSubsidiaryAction === 'facepic-attachments' || problemSubsidiarySlugOrJobSubsidiaryAction === 'pdpa-attachments') {
                setRightStubObj({
                  page: 'Add',
                  tableColumns: result,
                  table: result2.data.table_name,
                  handleChange: handleChange,
                  handleCalendarChange: handleCalendarChange,
                  handleDropdownChange: handleDropdownChange,
                  handleFileChange: handleFileChange,
                  handleFormSubmit: handleFormSubmit,
                  workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                  workerSubsidiaryIdOrWorkerSubsidiaryAction: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                  jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                  jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                  problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction,
                  problemSubsidiaryIdOrProblemSubsidiaryAction: problemSubsidiaryIdOrProblemSubsidiaryAction,
                  setRightStubObj: setRightStubObj,
                  handleComboBoxChange: handleComboBoxChange,
                  deleteButton: deleteButton,
                  toggleSort: toggleSort,
                  sort: sort
                });
                setLoading(false);
              } else {
                if (problemSubsidiarySlugOrJobSubsidiaryAction === 'ordinary-case-discussions' || problemSubsidiarySlugOrJobSubsidiaryAction === 'hearings-progress' || problemSubsidiarySlugOrJobSubsidiaryAction === 'sensitive-discussions') {
                  // check whether problem clan is set at a problem level
                  getObject('problems', jobSubsidiaryIdOrJobSubsidiaryAction).then(problem => {
                    problem = problem.data;

                  if (problem.problemClan_id !== null) {
                      getObject('problem-clans', problem.problemClan_id).then(problemClan => {
                        for (let i = result.length - 1; i >= 0; i -= 1) {
                          if (result[i]['column_name'] === 'problem_clan_checkbox') {
                            result[i]['detail'] = problemClan.data.clan_name;
                            break;
                          }
                        }

                        const clanWorkers = {};

                        problemClan.data.problems.forEach((problem) => {
                          if (clanWorkers[problem.id] === undefined && problem.id !== parseInt(jobSubsidiaryIdOrJobSubsidiaryAction)) {
                            clanWorkers[problem.id] = {
                              worker: `${problem.job.worker.twid} ${problem.job.worker.name_of_worker}`,
                              job: `${problem.job.employer_name}`,
                              problem: `${problem.chief_problem}`,
                              clanId: problemClan.data.id
                            };
                          }
                        });
                        
                        setRightStubObj({
                          page: 'Add',
                          tableColumns: result,
                          clanWorkers: clanWorkers,
                          table: result2.data.table_name,
                          handleChange: handleChange,
                          handleCalendarChange: handleCalendarChange,
                          handleDropdownChange: handleDropdownChange,
                          handleFileChange: handleFileChange,
                          setRightStubObj: setRightStubObj,
                          toggleSort: toggleSort,
                          taggedWorker: taggedWorker,
                          sort: sort,
                          handleComboBoxChange: handleComboBoxChange,
                          deleteButton: deleteButton,
                          handleFormSubmit: handleFormSubmit,
                          workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                          jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                          problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction,
                          problemSubsidiaryIdOrProblemSubsidiaryAction: problemSubsidiaryIdOrProblemSubsidiaryAction
                        });
                        setLoading(false);
                      });
                    } else {
                      setRightStubObj({
                        page: 'Add',
                        tableColumns: result,
                        table: result2.data.table_name,
                        handleChange: handleChange,
                        handleCalendarChange: handleCalendarChange,
                        handleDropdownChange: handleDropdownChange,
                        handleFileChange: handleFileChange,
                        setRightStubObj: setRightStubObj,
                        toggleSort: toggleSort,
                        sort: sort,
                        handleComboBoxChange: handleComboBoxChange,
                        deleteButton: deleteButton,
                        handleFormSubmit: handleFormSubmit,
                        workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                        jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                        problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction,
                        problemSubsidiaryIdOrProblemSubsidiaryAction: problemSubsidiaryIdOrProblemSubsidiaryAction
                      });
                      setLoading(false);
                    }
                  });
                } else {
                  setRightStubObj({
                    page: 'Add',
                    tableColumns: result,
                    table: result2.data.table_name,
                    handleChange: handleChange,
                    handleCalendarChange: handleCalendarChange,
                    handleDropdownChange: handleDropdownChange,
                    handleFileChange: handleFileChange,
                    setRightStubObj: setRightStubObj,
                    toggleSort: toggleSort,
                    sort: sort,
                    handleComboBoxChange: handleComboBoxChange,
                    deleteButton: deleteButton,
                    handleFormSubmit: handleFormSubmit,
                    workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                    jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                    problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction,
                    problemSubsidiaryIdOrProblemSubsidiaryAction: problemSubsidiaryIdOrProblemSubsidiaryAction
                  });
                  setLoading(false);
                }
              }
              
              setTopAndMiddleNavigationObj({
                level: 'problems',
                workerId: workerId,
                jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                problemId: jobSubsidiaryIdOrJobSubsidiaryAction,
                page: 'Add',
                subsidiary: result2.data.table_name,
                workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction
              });
            });
          });
        });
      } else {
        // view problem subsidiaryId
        if (problemSubsidiarySlugOrJobSubsidiaryAction === 'sensitive-discussions') {
          checkCurrentUserCanViewSensitiveDiscussion(problemSubsidiaryIdOrProblemSubsidiaryAction, jobSubsidiaryIdOrJobSubsidiaryAction, cookies['token'])
          .then(result => {
            if (result.data === '' || result.data.length === 0) {
              navigate(`/workers/${workerId}/jobs/${workerSubsidiaryIdOrWorkerSubsidiaryAction}/problems/${jobSubsidiaryIdOrJobSubsidiaryAction}/sensitive-discussions`);
            } else {
              const privilegeObject = {
                tableSlug: problemSubsidiarySlugOrJobSubsidiaryAction, 
                token: cookies['token']
              };

              getPrivilegeForCurrentPage(privilegeObject).then(privilegesResult => {
                const privilegesToUpdate = transformPrivileges(privilegesResult, 'View');
                setPrivileges(privilegesToUpdate);

                prepareTableColumnsWithPrivileges(problemSubsidiarySlugOrJobSubsidiaryAction, privilegesToUpdate, 'View', problemSubsidiaryIdOrProblemSubsidiaryAction).then(result => {
                  if (!matchWorkerJobProblem(result, workerId, workerSubsidiarySlugOrWorkerAction, workerSubsidiaryIdOrWorkerSubsidiaryAction, jobSubsidiarySlugOrWorkerSubsidiaryAction, jobSubsidiaryIdOrJobSubsidiaryAction)) navigate('/');
                  setPage('View');
                  setTable(problemSubsidiarySlugOrJobSubsidiaryAction);
                  setLevel('problems');
                  setTableColumns(result);

                  getSubsidiaryTableFromSubsidiarySlug(problemSubsidiarySlugOrJobSubsidiaryAction).then(result2 => {
                    setSubHeaderObj({
                      workerId: workerId,
                      table: tableSlug, 
                      page: 'View',
                      privileges: privilegesToUpdate,
                      workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                      workerSubsidiaryIdOrWorkerSubsidiaryAction: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                      jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                      jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                      problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction,
                      problemSubsidiaryIdOrProblemSubsidiaryAction: problemSubsidiaryIdOrProblemSubsidiaryAction,
                      subsidiary: result2.data.table_name
                    });

                    setRightStubObj({
                      page: 'View',
                      tableColumns: result,
                      table: result2.data.table_name,
                      handleChange: handleChange,
                      handleCalendarChange: handleCalendarChange,
                      handleDropdownChange: handleDropdownChange,
                      handleFileChange: handleFileChange,
                      setRightStubObj: setRightStubObj,
                      toggleSort: toggleSort,
                      sort: sort,
                      handleFormSubmit: handleFormSubmit,
                      workerSubsidiaryIdOrWorkerSubsidiaryAction: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                      workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                      jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                      jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                      problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction,
                      problemSubsidiaryIdOrProblemSubsidiaryAction: problemSubsidiaryIdOrProblemSubsidiaryAction,
                    });
                    setLoading(false);
                    
                    setTopAndMiddleNavigationObj({
                      level: 'problems',
                      workerId: workerId,
                      jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                      problemId: jobSubsidiaryIdOrJobSubsidiaryAction,
                      page: 'View',
                      subsidiary: result2.data.table_name,
                      workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                      jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                      problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction
                    });

                    setNavigationStubObj({
                      level: 'problems',
                      workerId: workerId,
                      jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                      problemId: jobSubsidiaryIdOrJobSubsidiaryAction
                    });
                  });
                });
              });
            }
          })
          .catch(error => {
            console.log(error);
          })
        } else if (problemSubsidiarySlugOrJobSubsidiaryAction === 'sensitive-attachments') {
          checkCurrentUserCanViewSensitiveAttachment(problemSubsidiaryIdOrProblemSubsidiaryAction, cookies['token'])
          .then(result => {
            if (result.data === '' || result.data.length === 0) {
              navigate(`/workers/${workerId}/${workerSubsidiarySlugOrWorkerAction}/${workerSubsidiaryIdOrWorkerSubsidiaryAction}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}/${jobSubsidiaryIdOrJobSubsidiaryAction}/${problemSubsidiarySlugOrJobSubsidiaryAction}`);
            } else {
              const privilegeObject = {
                tableSlug: problemSubsidiarySlugOrJobSubsidiaryAction, 
                token: cookies['token']
              };

              getPrivilegeForCurrentPage(privilegeObject).then(privilegesResult => {
                const privilegesToUpdate = transformPrivileges(privilegesResult, 'View');
                setPrivileges(privilegesToUpdate);

                prepareTableColumnsWithPrivileges(problemSubsidiarySlugOrJobSubsidiaryAction, privilegesToUpdate, 'View', problemSubsidiaryIdOrProblemSubsidiaryAction).then(result => {
                  if (!matchWorkerJobProblem(result, workerId, workerSubsidiarySlugOrWorkerAction, workerSubsidiaryIdOrWorkerSubsidiaryAction, jobSubsidiarySlugOrWorkerSubsidiaryAction, jobSubsidiaryIdOrJobSubsidiaryAction)) navigate('/');
                  setPage('View');
                  setTable(problemSubsidiarySlugOrJobSubsidiaryAction);
                  setLevel('problems');
                  setTableColumns(result);

                  getSubsidiaryTableFromSubsidiarySlug(problemSubsidiarySlugOrJobSubsidiaryAction).then(result2 => {
                    setSubHeaderObj({
                      workerId: workerId,
                      table: tableSlug, 
                      page: 'View',
                      privileges: privilegesToUpdate,
                      workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                      workerSubsidiaryIdOrWorkerSubsidiaryAction: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                      jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                      jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                      problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction,
                      problemSubsidiaryIdOrProblemSubsidiaryAction: problemSubsidiaryIdOrProblemSubsidiaryAction,
                      subsidiary: result2.data.table_name
                    });

                    setRightStubObj({
                      page: 'View',
                      tableColumns: result,
                      table: result2.data.table_name,
                      handleChange: handleChange,
                      handleCalendarChange: handleCalendarChange,
                      handleDropdownChange: handleDropdownChange,
                      handleFileChange: handleFileChange,
                      setRightStubObj: setRightStubObj,
                      toggleSort: toggleSort,
                      sort: sort,
                      handleFormSubmit: handleFormSubmit,
                      workerId: workerId,
                      workerSubsidiaryIdOrWorkerSubsidiaryAction: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                      workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                      jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                      jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                      problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction,
                      problemSubsidiaryIdOrProblemSubsidiaryAction: problemSubsidiaryIdOrProblemSubsidiaryAction,
                    });
                    setLoading(false);
                    
                    setTopAndMiddleNavigationObj({
                      level: 'problems',
                      workerId: workerId,
                      jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                      problemId: jobSubsidiaryIdOrJobSubsidiaryAction,
                      page: 'View',
                      subsidiary: result2.data.table_name,
                      workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                      jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                      problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction
                    });

                    setNavigationStubObj({
                      level: 'problems',
                      workerId: workerId,
                      jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                      problemId: jobSubsidiaryIdOrJobSubsidiaryAction
                    });
                  });
                });
              }); 
            }
          })
          .catch(error => {
            console.log(error);
          })
        } else {
          const privilegeObject = {
            tableSlug: problemSubsidiarySlugOrJobSubsidiaryAction, 
            token: cookies['token']
          };

          getPrivilegeForCurrentPage(privilegeObject).then(privilegesResult => {
            const privilegesToUpdate = transformPrivileges(privilegesResult, 'View');
            setPrivileges(privilegesToUpdate);

            prepareTableColumnsWithPrivileges(problemSubsidiarySlugOrJobSubsidiaryAction, privilegesToUpdate, 'View', problemSubsidiaryIdOrProblemSubsidiaryAction).then(result => {
              if (!matchWorkerJobProblem(result, workerId, workerSubsidiarySlugOrWorkerAction, workerSubsidiaryIdOrWorkerSubsidiaryAction, jobSubsidiarySlugOrWorkerSubsidiaryAction, jobSubsidiaryIdOrJobSubsidiaryAction)) navigate('/');
              setPage('View');
              setTable(problemSubsidiarySlugOrJobSubsidiaryAction);
              setLevel('problems');
              setTableColumns(result);

              getSubsidiaryTableFromSubsidiarySlug(problemSubsidiarySlugOrJobSubsidiaryAction).then(result2 => {
                setSubHeaderObj({
                  workerId: workerId,
                  table: tableSlug, 
                  page: 'View',
                  privileges: privilegesToUpdate,
                  workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                  workerSubsidiaryIdOrWorkerSubsidiaryAction: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                  jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                  jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                  problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction,
                  problemSubsidiaryIdOrProblemSubsidiaryAction: problemSubsidiaryIdOrProblemSubsidiaryAction,
                  subsidiary: result2.data.table_name
                });

                setRightStubObj({
                  page: 'View',
                  tableColumns: result,
                  table: result2.data.table_name,
                  handleChange: handleChange,
                  handleCalendarChange: handleCalendarChange,
                  handleDropdownChange: handleDropdownChange,
                  handleFileChange: handleFileChange,
                  setRightStubObj: setRightStubObj,
                  toggleSort: toggleSort,
                  sort: sort,
                  handleFormSubmit: handleFormSubmit,
                  workerId: workerId,
                  workerSubsidiaryIdOrWorkerSubsidiaryAction: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                  workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                  jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                  jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                  problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction,
                  problemSubsidiaryIdOrProblemSubsidiaryAction: problemSubsidiaryIdOrProblemSubsidiaryAction,
                });
                setLoading(false);
                
                setTopAndMiddleNavigationObj({
                  level: 'problems',
                  workerId: workerId,
                  jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                  problemId: jobSubsidiaryIdOrJobSubsidiaryAction,
                  page: 'View',
                  subsidiary: result2.data.table_name,
                  workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                  jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                  problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction
                });

                setNavigationStubObj({
                  level: 'problems',
                  workerId: workerId,
                  jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                  problemId: jobSubsidiaryIdOrJobSubsidiaryAction
                });
              });
            });
          }); 
        }
      }
    } else if (workerSubsidiarySlugOrWorkerAction !== undefined && 
      workerSubsidiaryIdOrWorkerSubsidiaryAction !== undefined && 
      jobSubsidiarySlugOrWorkerSubsidiaryAction !== undefined && 
      jobSubsidiaryIdOrJobSubsidiaryAction !== undefined &&
      problemSubsidiarySlugOrJobSubsidiaryAction !== undefined &&
      problemSubsidiaryIdOrProblemSubsidiaryAction !== undefined &&
      problemSubsidiaryAction !== undefined
    ) {
      if (problemSubsidiaryAction === 'edit') {
        if (problemSubsidiarySlugOrJobSubsidiaryAction === 'sensitive-discussions') {
          checkCurrentUserCanViewSensitiveDiscussion(problemSubsidiaryIdOrProblemSubsidiaryAction, jobSubsidiaryIdOrJobSubsidiaryAction, cookies['token'])
          .then(result => {
            if (result.data === '' || result.data.length === 0) {
              navigate(`/workers/${workerId}/jobs/${workerSubsidiaryIdOrWorkerSubsidiaryAction}/problems/${jobSubsidiaryIdOrJobSubsidiaryAction}/sensitive-discussions`);
            } else {
              const privilegeObject = {
                tableSlug: problemSubsidiarySlugOrJobSubsidiaryAction, 
                token: cookies['token']
              };

              getPrivilegeForCurrentPage(privilegeObject).then(privilegesResult => {
                const privilegesToUpdate = transformPrivileges(privilegesResult, 'Edit');
                setPrivileges(privilegesToUpdate);

                prepareTableColumnsWithPrivilegeAndInputType(problemSubsidiarySlugOrJobSubsidiaryAction, privilegesToUpdate, 'Edit', problemSubsidiaryIdOrProblemSubsidiaryAction, workerId, workerSubsidiaryIdOrWorkerSubsidiaryAction, jobSubsidiaryIdOrJobSubsidiaryAction).then(result => {
                  setPage('Edit');
                  setTable(problemSubsidiarySlugOrJobSubsidiaryAction);
                  setLevel('problems');
                  setTableColumns(result);

                  setNavigationStubObj({
                    level: 'problems',
                    workerId: workerId,
                    jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                    problemId: jobSubsidiaryIdOrJobSubsidiaryAction
                  });

                  getSubsidiaryTableFromSubsidiarySlug(problemSubsidiarySlugOrJobSubsidiaryAction).then(result2 => {
                    setSubHeaderObj({
                      workerId: workerId,
                      table: tableSlug, 
                      page: 'Edit',
                      privileges: privilegesToUpdate,
                      workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                      workerSubsidiaryIdOrWorkerSubsidiaryAction: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                      jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                      jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                      problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction,
                      problemSubsidiaryIdOrProblemSubsidiaryAction: problemSubsidiaryIdOrProblemSubsidiaryAction,
                      problemSubsidiaryAction: problemSubsidiaryAction
                    });
                    
                    setRightStubObj({
                      page: 'Edit',
                      tableColumns: result,
                      table: result2.data.table_name,
                      handleChange: handleChange,
                      handleCalendarChange: handleCalendarChange,
                      handleDropdownChange: handleDropdownChange,
                      handleFileChange: handleFileChange,
                      setRightStubObj: setRightStubObj,
                      toggleSort: toggleSort,
                      sort: sort,
                      deleteButton: deleteButton,
                      handleComboBoxChange: handleComboBoxChange,
                      handleFormSubmit: handleFormSubmit,
                      workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                      jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                      problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction,
                      problemSubsidiaryAction: problemSubsidiaryAction
                    });
                    setLoading(false);

                    setTopAndMiddleNavigationObj({
                      level: 'problems',
                      workerId: workerId,
                      jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                      problemId: jobSubsidiaryIdOrJobSubsidiaryAction,
                      page: 'Edit',
                      subsidiary: result2.data.table_name,
                      workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                      jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                      problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction
                    });
                  });
                });

              });
            }
          })
          .catch(error => {
            console.log(error);
          })
        } else if (problemSubsidiarySlugOrJobSubsidiaryAction === 'sensitive-attachments') {
          checkCurrentUserCanViewSensitiveAttachment(problemSubsidiaryIdOrProblemSubsidiaryAction, cookies['token'])
          .then(result => {
            if (result.data === '' || result.data.length === 0) {
              navigate(`/workers/${workerId}/${workerSubsidiarySlugOrWorkerAction}/${workerSubsidiaryIdOrWorkerSubsidiaryAction}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}/${jobSubsidiaryIdOrJobSubsidiaryAction}/${problemSubsidiarySlugOrJobSubsidiaryAction}/${problemSubsidiaryIdOrProblemSubsidiaryAction}`);
            } else {
              const privilegeObject = {
                tableSlug: problemSubsidiarySlugOrJobSubsidiaryAction, 
                token: cookies['token']
              };

              getPrivilegeForCurrentPage(privilegeObject).then(privilegesResult => {
                const privilegesToUpdate = transformPrivileges(privilegesResult, 'Edit');
                setPrivileges(privilegesToUpdate);

                prepareTableColumnsWithPrivilegeAndInputType(problemSubsidiarySlugOrJobSubsidiaryAction, privilegesToUpdate, 'Edit', problemSubsidiaryIdOrProblemSubsidiaryAction, workerId, workerSubsidiaryIdOrWorkerSubsidiaryAction, jobSubsidiaryIdOrJobSubsidiaryAction).then(result => {
                  setPage('Edit');
                  setTable(problemSubsidiarySlugOrJobSubsidiaryAction);
                  setLevel('problems');
                  setTableColumns(result);

                  setNavigationStubObj({
                    level: 'problems',
                    workerId: workerId,
                    jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                    problemId: jobSubsidiaryIdOrJobSubsidiaryAction
                  });

                  getSubsidiaryTableFromSubsidiarySlug(problemSubsidiarySlugOrJobSubsidiaryAction).then(result2 => {
                    setSubHeaderObj({
                      workerId: workerId,
                      table: tableSlug, 
                      page: 'Edit',
                      privileges: privilegesToUpdate,
                      workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                      workerSubsidiaryIdOrWorkerSubsidiaryAction: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                      jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                      jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                      problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction,
                      problemSubsidiaryIdOrProblemSubsidiaryAction: problemSubsidiaryIdOrProblemSubsidiaryAction,
                      problemSubsidiaryAction: problemSubsidiaryAction
                    });
                    
                    if (problemSubsidiarySlugOrJobSubsidiaryAction === 'ordinary-attachments' || problemSubsidiarySlugOrJobSubsidiaryAction === 'sensitive-attachments') {
                      getJobAndProblemByWorkerId(workerId).then(jobAndProblems => {
                        const jobIdsToProblems = {};
                        
                        jobAndProblems.data.problems.forEach((problem) => {
                          if (jobIdsToProblems[problem.job_id] === undefined) {
                            jobIdsToProblems[problem.job_id] = [];
                          }

                          jobIdsToProblems[problem.job_id].push(problem);
                        });

                        let employerNameId = '';
                        let chiefProblemId = '';

                        for (let i = 0; i < result.length; i += 1) {
                          if (result[i]['column_name'] === 'employer_name') {
                            employerNameId = i;
                            result[i]['dropdowns'] = [{dropdown_item: '-'}];
                            jobAndProblems.data.jobs.forEach(job => {
                              result[i]['dropdowns'].push({id: job.id, dropdown_item: job.employer_name});
                            });
                          } else if (result[i]['column_name'] === 'chief_problem') {
                            chiefProblemId = i;
                            result[i]['dropdowns'] = [{dropdown_item: '-'}];
                          } else if (result[i]['column_name'] === 'job_id') {
                            if (result[i]['value'] !== '') {
                              result[employerNameId]['job_id'] = result[i].value;

                              result[employerNameId]['value'] = result[employerNameId]['dropdowns'].filter((dropdown) => dropdown.id === result[i].value)[0]['dropdown_item'];
                            }
                          } else if (result[i]['column_name'] === 'problem_id') {
                            if (result[i]['value'] !== '') {
                              result[chiefProblemId]['problem_id'] = result[i].value;
                              // result[chiefProblemId]['value'] = result[i]['dropdowns'].filter((dropdown) => dropdown.id === result[i].value)[0]['dropdown_item'];
                              if (result[employerNameId]['job_id'] !== '') {
                                jobAndProblems.data.problems.forEach((problem) => {
                                  if (problem.job_id === result[employerNameId]['job_id']) {
                                    result[chiefProblemId]['dropdowns'].push({id: problem.id, dropdown_item: problem.chief_problem});
                                  }

                                  if (problem.job_id === result[employerNameId]['job_id']) {
                                    result[chiefProblemId]['value'] = problem.chief_problem;
                                  }
                                });
                              }
                            } else {
                              if (result[employerNameId]['job_id'] !== '') {
                                jobAndProblems.data.problems.forEach((problem) => {
                                  if (problem.job_id === result[employerNameId]['job_id']) {
                                    result[chiefProblemId]['dropdowns'].push({id: problem.id, dropdown_item: problem.chief_problem});
                                  }
                                });
                              }
                            }
                          }
                        }

                        setRightStubObj({
                          page: 'Edit',
                          tableColumns: result,
                          table: result2.data.table_name,
                          handleChange: handleChange,
                          handleCalendarChange: handleCalendarChange,
                          handleDropdownChange: handleDropdownChange,
                          handleFileChange: handleFileChange,
                          handleFormSubmit: handleFormSubmit,
                          handleJobChange: handleJobChange,
                          workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                          workerSubsidiaryIdOrWorkerSubsidiaryAction: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                          jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                          jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                          problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction,
                          problemSubsidiaryIdOrProblemSubsidiaryAction: problemSubsidiaryIdOrProblemSubsidiaryAction,
                          problemSubsidiaryAction: problemSubsidiaryAction,
                          setRightStubObj: setRightStubObj,
                          toggleSort: toggleSort,
                          jobIdsToProblems: jobIdsToProblems,
                          sort: sort,
                          deleteButton: deleteButton,
                          handleComboBoxChange: handleComboBoxChange,
                          updatedFacepic: updatedFacepic,
                          setUpdatedFacepic: setUpdatedFacepic,
                          updatedFacepicDataFormat: updatedFacepicDataFormat,
                          setUpdatedFacepicDataFormat: setUpdatedFacepicDataFormat
                        });
                        setLoading(false);
                      });
                    } else {
                      setRightStubObj({
                        page: 'Edit',
                        tableColumns: result,
                        table: result2.data.table_name,
                        handleChange: handleChange,
                        handleCalendarChange: handleCalendarChange,
                        handleDropdownChange: handleDropdownChange,
                        handleFileChange: handleFileChange,
                        setRightStubObj: setRightStubObj,
                        toggleSort: toggleSort,
                        sort: sort,
                        deleteButton: deleteButton,
                        handleComboBoxChange: handleComboBoxChange,
                        handleFormSubmit: handleFormSubmit,
                        workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                        jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                        problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction,
                        problemSubsidiaryAction: problemSubsidiaryAction
                      });
                      setLoading(false);
                    }

                    setTopAndMiddleNavigationObj({
                      level: 'problems',
                      workerId: workerId,
                      jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                      problemId: jobSubsidiaryIdOrJobSubsidiaryAction,
                      page: 'Edit',
                      subsidiary: result2.data.table_name,
                      workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                      jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                      problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction
                    });
                  });
                });

              });
            }
          })
          .catch(error => {
            console.log(error);
          })
        } else {
          const privilegeObject = {
            tableSlug: problemSubsidiarySlugOrJobSubsidiaryAction, 
            token: cookies['token']
          };

          getPrivilegeForCurrentPage(privilegeObject).then(privilegesResult => {
            const privilegesToUpdate = transformPrivileges(privilegesResult, 'Edit');
            setPrivileges(privilegesToUpdate);

            prepareTableColumnsWithPrivilegeAndInputType(problemSubsidiarySlugOrJobSubsidiaryAction, privilegesToUpdate, 'Edit', problemSubsidiaryIdOrProblemSubsidiaryAction, workerId, workerSubsidiaryIdOrWorkerSubsidiaryAction, jobSubsidiaryIdOrJobSubsidiaryAction).then(result => {
              setPage('Edit');
              setTable(problemSubsidiarySlugOrJobSubsidiaryAction);
              setLevel('problems');
              setTableColumns(result);

              setNavigationStubObj({
                level: 'problems',
                workerId: workerId,
                jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                problemId: jobSubsidiaryIdOrJobSubsidiaryAction
              });

              getSubsidiaryTableFromSubsidiarySlug(problemSubsidiarySlugOrJobSubsidiaryAction).then(result2 => {
                setSubHeaderObj({
                  workerId: workerId,
                  table: tableSlug, 
                  page: 'Edit',
                  privileges: privilegesToUpdate,
                  workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                  workerSubsidiaryIdOrWorkerSubsidiaryAction: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                  jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                  jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                  problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction,
                  problemSubsidiaryIdOrProblemSubsidiaryAction: problemSubsidiaryIdOrProblemSubsidiaryAction,
                  problemSubsidiaryAction: problemSubsidiaryAction
                });
                
                if (problemSubsidiarySlugOrJobSubsidiaryAction === 'ordinary-attachments' || problemSubsidiarySlugOrJobSubsidiaryAction === 'sensitive-attachments') {
                  getJobAndProblemByWorkerId(workerId).then(jobAndProblems => {
                    const jobIdsToProblems = {};
                    
                    jobAndProblems.data.problems.forEach((problem) => {
                      if (jobIdsToProblems[problem.job_id] === undefined) {
                        jobIdsToProblems[problem.job_id] = [];
                      }

                      jobIdsToProblems[problem.job_id].push(problem);
                    });

                    let employerNameId = '';
                    let chiefProblemId = '';

                    for (let i = 0; i < result.length; i += 1) {
                      if (result[i]['column_name'] === 'employer_name') {
                        employerNameId = i;
                        result[i]['dropdowns'] = [{dropdown_item: '-'}];
                        jobAndProblems.data.jobs.forEach(job => {
                          result[i]['dropdowns'].push({id: job.id, dropdown_item: job.employer_name});
                        });
                      } else if (result[i]['column_name'] === 'chief_problem') {
                        chiefProblemId = i;
                        result[i]['dropdowns'] = [{dropdown_item: '-'}];
                      } else if (result[i]['column_name'] === 'job_id') {
                        if (result[i]['value'] !== '') {
                          result[employerNameId]['job_id'] = result[i].value;

                          result[employerNameId]['value'] = result[employerNameId]['dropdowns'].filter((dropdown) => dropdown.id === result[i].value)[0]['dropdown_item'];
                        }
                      } else if (result[i]['column_name'] === 'problem_id') {
                        if (result[i]['value'] !== '') {
                          result[chiefProblemId]['problem_id'] = result[i].value;
                          // result[chiefProblemId]['value'] = result[i]['dropdowns'].filter((dropdown) => dropdown.id === result[i].value)[0]['dropdown_item'];
                          if (result[employerNameId]['job_id'] !== '') {
                            jobAndProblems.data.problems.forEach((problem) => {
                              if (problem.job_id === result[employerNameId]['job_id']) {
                                result[chiefProblemId]['dropdowns'].push({id: problem.id, dropdown_item: problem.chief_problem});
                              }

                              if (problem.job_id === result[employerNameId]['job_id']) {
                                result[chiefProblemId]['value'] = problem.chief_problem;
                              }
                            });
                          }
                        } else {
                          if (result[employerNameId]['job_id'] !== '') {
                            jobAndProblems.data.problems.forEach((problem) => {
                              if (problem.job_id === result[employerNameId]['job_id']) {
                                result[chiefProblemId]['dropdowns'].push({id: problem.id, dropdown_item: problem.chief_problem});
                              }
                            });
                          }
                        }
                      }
                    }

                    setRightStubObj({
                      page: 'Edit',
                      tableColumns: result,
                      table: result2.data.table_name,
                      handleChange: handleChange,
                      handleCalendarChange: handleCalendarChange,
                      handleDropdownChange: handleDropdownChange,
                      handleFileChange: handleFileChange,
                      handleFormSubmit: handleFormSubmit,
                      handleJobChange: handleJobChange,
                      workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                      workerSubsidiaryIdOrWorkerSubsidiaryAction: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                      jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                      jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                      problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction,
                      problemSubsidiaryIdOrProblemSubsidiaryAction: problemSubsidiaryIdOrProblemSubsidiaryAction,
                      problemSubsidiaryAction: problemSubsidiaryAction,
                      setRightStubObj: setRightStubObj,
                      toggleSort: toggleSort,
                      jobIdsToProblems: jobIdsToProblems,
                      sort: sort,
                      deleteButton: deleteButton,
                      handleComboBoxChange: handleComboBoxChange,
                      updatedFacepic: updatedFacepic,
                      setUpdatedFacepic: setUpdatedFacepic,
                      updatedFacepicDataFormat: updatedFacepicDataFormat,
                      setUpdatedFacepicDataFormat: setUpdatedFacepicDataFormat
                    });
                    setLoading(false);
                  });
                } else {
                  setRightStubObj({
                    page: 'Edit',
                    tableColumns: result,
                    table: result2.data.table_name,
                    handleChange: handleChange,
                    handleCalendarChange: handleCalendarChange,
                    handleDropdownChange: handleDropdownChange,
                    handleFileChange: handleFileChange,
                    setRightStubObj: setRightStubObj,
                    toggleSort: toggleSort,
                    sort: sort,
                    deleteButton: deleteButton,
                    handleComboBoxChange: handleComboBoxChange,
                    handleFormSubmit: handleFormSubmit,
                    workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                    jobSubsidiaryIdOrJobSubsidiaryAction: jobSubsidiaryIdOrJobSubsidiaryAction,
                    problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction,
                    problemSubsidiaryAction: problemSubsidiaryAction
                  });
                  setLoading(false);
                }

                setTopAndMiddleNavigationObj({
                  level: 'problems',
                  workerId: workerId,
                  jobId: workerSubsidiaryIdOrWorkerSubsidiaryAction,
                  problemId: jobSubsidiaryIdOrJobSubsidiaryAction,
                  page: 'Edit',
                  subsidiary: result2.data.table_name,
                  workerSubsidiarySlugOrWorkerAction: workerSubsidiarySlugOrWorkerAction,
                  jobSubsidiarySlugOrWorkerSubsidiaryAction: jobSubsidiarySlugOrWorkerSubsidiaryAction,
                  problemSubsidiarySlugOrJobSubsidiaryAction: problemSubsidiarySlugOrJobSubsidiaryAction
                });
              });
            });

          });
        }
      }
    }

    return () => {
      setOutcome({});
    };
  }, [workerSubsidiarySlugOrWorkerAction, workerSubsidiaryIdOrWorkerSubsidiaryAction, jobSubsidiarySlugOrWorkerSubsidiaryAction, jobSubsidiaryIdOrJobSubsidiaryAction, problemSubsidiarySlugOrJobSubsidiaryAction, problemSubsidiaryIdOrProblemSubsidiaryAction, problemSubsidiaryAction]);

  return ( 
    <>     
          {
            (() => {
                if (workerSubsidiarySlugOrWorkerAction === 'jobs' && workerSubsidiaryIdOrWorkerSubsidiaryAction === undefined) {
                    return (
                      <Abridged rightStubObj={rightStubObj} subHeaderObj={subHeaderObj} outcome={outcome} setOutcome={setOutcome} />
                    )
                } else if (
                    (
                      workerSubsidiarySlugOrWorkerAction === 'facepic-attachments' || 
                      workerSubsidiarySlugOrWorkerAction === 'pdpa-attachments' || 
                      workerSubsidiarySlugOrWorkerAction === 'ordinary-attachments' || 
                      workerSubsidiarySlugOrWorkerAction === 'sensitive-attachments'
                    ) || 
                    (
                      workerSubsidiarySlugOrWorkerAction === 'jobs' && 
                      (
                        jobSubsidiarySlugOrWorkerSubsidiaryAction === 'facepic-attachments' || 
                        jobSubsidiarySlugOrWorkerSubsidiaryAction === 'pdpa-attachments' || 
                        jobSubsidiarySlugOrWorkerSubsidiaryAction === 'ordinary-attachments' || 
                        jobSubsidiarySlugOrWorkerSubsidiaryAction === 'sensitive-attachments'
                      )
                    ) || 
                    (
                      (
                        jobSubsidiarySlugOrWorkerSubsidiaryAction === 'problems' &&
                        workerSubsidiarySlugOrWorkerAction === 'jobs'
                      ) && 
                      (
                        problemSubsidiarySlugOrJobSubsidiaryAction === 'facepic-attachments' || 
                        problemSubsidiarySlugOrJobSubsidiaryAction === 'pdpa-attachments' || 
                        problemSubsidiarySlugOrJobSubsidiaryAction === 'ordinary-attachments' || 
                        problemSubsidiarySlugOrJobSubsidiaryAction === 'sensitive-attachments'
                      )
                    )
                  ) {
                    return (
                      <>
                        <Modal showDeleteConfirmation={showDeleteConfirmation} disableShowDeleteConfirmation={disableShowDeleteConfirmation} confirmDelete={deleteSelected} objectsSelected={objectsSelected.map((object) => object['attachment_name'])} single={deleteSingle} plural={deletePlural} />
                        <Abridged rightStubObj={rightStubObj} subHeaderObj={subHeaderObj} outcome={outcome} setOutcome={setOutcome} />
                      </>
                    )
                } else if (workerSubsidiarySlugOrWorkerAction === 'jobs' && jobSubsidiarySlugOrWorkerSubsidiaryAction === 'problems' && jobSubsidiaryIdOrJobSubsidiaryAction === undefined) {
                    return (
                      <ProblemAbridged rightStubObj={rightStubObj} subHeaderObj={subHeaderObj} outcome={outcome} setOutcome={setOutcome} />
                    )
                } else {
                    return (
                      <>
                        <Header />
                        <form onSubmit={(event) => handleFormSubmit(event, rightStubObj)}>
                          <SubHeader2 subHeaderObj={subHeaderObj} />
                          <Modal showDeleteConfirmation={showDeleteConfirmation} disableShowDeleteConfirmation={disableShowDeleteConfirmation} confirmDelete={deleteSelected} objectsSelected={[]} rightStubObj={rightStubObj} single={deleteSingle} plural={deletePlural} />
                          <AlertNotification outcome={outcome} setOutcome={setOutcome} />
                          <WarningNotification outcome={outcome} setOutcome={setOutcome} />
                          <TagWorkerModal problemId={jobSubsidiaryIdOrJobSubsidiaryAction} setRightStubObj={setRightStubObj} rightStubObj={rightStubObj} taggedWorker={taggedWorker} setTaggedWorker={setTaggedWorker} />
                          {/* <TopAndMiddleNavigation2 topAndMiddleNavigationObj={topAndMiddleNavigationObj} /> */}

                          <TopAndMiddleNavigation3 
                            userId={cookies['userId']} 
                            topAndMiddleNavigationObj={topAndMiddleNavigationObj}
                          />
                          <div className="mt-5 mb-10 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 grid grid-cols-1 sm:grid-cols-2 gap-6 pb-20">
                            <NavigationStub2 navigationStubObj={navigationStubObj} />
                            {!loading && <RightStub rightStubObj={rightStubObj} />}
                          </div>
                        </form>
                      </>
                    )
                }
            })()  
          }  
    </>  
  )
}
